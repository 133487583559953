import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import CoreLibraryForm from './CoreLibraryForm';
import CoreLibraryTable from './CoreLibraryTable';
import LessonForm from '../../../components/LessonForm';

export default class Courses extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route
                        exact
                        path='/admin/core-libraries'
                        component={CoreLibraryTable}
                    />
                    <Route
                        exact
                        path='/admin/core-libraries/create'
                        key='admin-coreLibrary-create'
                        component={CoreLibraryForm}
                    />
                    <Route
                        exact
                        path='/admin/core-libraries/edit/:id'
                        key='admin-coreLibrary-edit'
                        component={CoreLibraryForm}
                    />
                    <Route
                        exact
                        path='/admin/core-libraries/lessons/edit/:id'
                        component={LessonForm}
                    />
                </Switch>
            </div>
        );
    }
}
