import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ApiTable } from '../../../components/ApiTable';
import apiCall from '../../../helpers/apiCall';
import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faClone as farClone } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import {
    Col,
    Row,
    Form,
    FormGroup,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

class ExamTable extends Component {
    constructor(props) {
        super(props);

        this.docId = this.props.match.params.id;

        this.state = {
            course: {},
            modalShow: false,
            modalAction: null,
            modalType: '',
        };
    }

    componentDidMount = async () => {
        const { success, response } = await apiCall(
            'GET',
            `/courses/${this.props.match.params.id}`
        );

        this.setState({
            course: response,
        });

        this.props.pushBreadcrumbLink({
            text: 'Courses',
            path: '/admin/courses',
        });
        this.props.pushBreadcrumbLink({
            text: `Course: ${this.state.course.title}`,
            path: `/admin/courses/${this.props.match.params.id}`,
        });
    };

    componentWillUnmount = () => {
        this.props.removeBreadcrumbLink({
            text: 'Courses',
            path: '/admin/courses',
        });
        this.props.removeBreadcrumbLink({
            text: `Course: ${this.state.course.title}`,
            path: `/admin/courses/${this.props.match.params.id}`,
        });
    };

    duplicateExam = async (docId, reloadTable) => {
        const { success } = await apiCall(
            'POST',
            `/courses/exams/${docId}/clone`
        );

        if (success) {
            this.setState({
                modalShow: false,
            });
            await reloadTable();
            this.props.setGlobalAlert({
                type: 'success',
                message: 'Exam has been duplicated',
            });
        } else {
            this.props.setGlobalAlert({
                type: 'error',
                message: 'Exam has not been duplicated. Please try again.',
            });
        }
    };

    deleteExam = async (docId, reloadTable) => {
        const { success } = await apiCall('DELETE', `/courses/exams/${docId}`);

        if (success) {
            this.setState({
                modalShow: false,
            });
            await reloadTable();
            this.props.setGlobalAlert({
                type: 'success',
                message: 'Exam has been deleted',
            });
        } else {
            this.props.setGlobalAlert({
                type: 'error',
                message: 'Exam has not been deleted. Please try again.',
            });
        }
    };

    render() {
        return (
            <div>
                <ConfirmationModal
                    show={this.state.modalShow}
                    hideModal={() => {
                        this.setState({
                            modalShow: false,
                        });
                    }}
                    confirmAction={this.state.modalAction}
                    titleText={'Are you sure?'}
                    bodyText={[
                        'You are about to ',
                        <strong key='modal-type'>
                            {this.state.modalType}
                        </strong>,
                        ' this Exam.',
                    ]}
                />
                <Form onSubmit={this.handleSubmit} id='examTableForm'>
                    <Row className='pt-5'>
                        <Col md={3}>
                            <FormGroup>
                                <Form.Label>Randomize Exams?</Form.Label>
                                <Form.Control
                                    as='select'
                                    id='randomizeExam'
                                    name='randomizeExam'
                                    required
                                    // TODO: property not yet in the BE
                                    // value={this.props.item.required}
                                    // onChange={(event) => {
                                    //     this.props.handleChange(
                                    //         this.props.idx,
                                    //         'required',
                                    //         event.target.value
                                    //     );
                                    // }}
                                >
                                    <option disabled value=''></option>
                                    <option value='true'>Yes</option>
                                    <option value='false'>No</option>
                                </Form.Control>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                <a
                    className='btn btn--primary btn--small'
                    href={`/admin/courses/ext/${this.docId}/exams/create/`}>
                    New Exam
                </a>
                <ApiTable
                    apiCall={{
                        method: 'GET',
                        path: `/courses/${this.docId}/exams/`,
                    }}
                    columns={[
                        {
                            text: '',
                            field: (row) => (
                                <OverlayTrigger
                                    placement='top'
                                    overlay={
                                        <Tooltip
                                            id={`tooltip-${row._id}-edit-exam)}`}>
                                            Edit exam
                                        </Tooltip>
                                    }>
                                    <Link
                                        className='btn btn--small'
                                        to={
                                            '/admin/courses/ext/exams/edit/' +
                                            row._id
                                        }>
                                        <Fa icon={faEdit} />
                                    </Link>
                                </OverlayTrigger>
                            ),
                            maxWidth: '3.11rem',
                        },
                        {
                            text: 'Title',
                            field: 'title',
                            minWidth: '45%',
                        },
                        {
                            text: 'Status',
                            field: (row) => {
                                return (
                                    row.status[0].toUpperCase() +
                                    row.status.substr(1).toLowerCase()
                                );
                            },
                        },
                        {
                            text: 'Created at',
                            field: 'createdAt',
                        },
                    ]}
                    rowButtons={[
                        {
                            type: 'button',
                            text: 'Duplicate exam',
                            icon: farClone,
                            clickCallback: (e, doc, reload) => {
                                this.setState({
                                    modalShow: true,
                                    modalAction: () => {
                                        this.duplicateExam(doc._id, reload);
                                    },
                                    modalType: 'duplicate',
                                });
                            },
                        },
                        {
                            type: 'button',
                            text: 'Delete exam',
                            icon: faTrash,
                            clickCallback: (e, doc, reload) => {
                                this.setState({
                                    modalShow: true,
                                    modalAction: () => {
                                        this.deleteExam(doc._id, reload);
                                    },
                                    modalType: 'delete',
                                });
                            },
                        },
                    ]}
                />
            </div>
        );
    }
}

export default connect(null, {
    pushBreadcrumbLink: (payload) => ({
        type: 'PUSH_BREADCRUMB_LINK',
        payload,
    }),
    removeBreadcrumbLink: (payload) => ({
        type: 'REMOVE_BREADCRUMB_LINK',
        payload,
    }),
    setGlobalAlert: (payload) => ({
        type: 'SET_GLOBAL_ALERT',
        payload,
    }),
})(withRouter(ExamTable));
