import React, { Component } from 'react';
import './CourseMenu.scss';
import { connect } from 'react-redux';
import ChaptersAccordion from '../ChaptersAccordion';
import { withRouter } from 'react-router-dom';

class MenuItems extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: '',
            sidebar: false,
            lessons: [],
            sidebarEventKey: 0,
            lessonChapterPairs: [],
            open: false,
            currentActive: null,
            courseId: '',
            lessonId: '',
            chapterId: '',
            activeKey: '',
        };
    }

    async componentDidMount() {
        if (Object.keys(this.props.currentLesson).length > 0) {
            this.setState({ lessonId: this.props.currentLesson.state._id });
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.currentLesson != this.props.currentLesson) {
            this.setState({ lessonId: this.props.currentLesson.state._id });
        }
    }

    goToExam = () => {
        const courseId = this.props.currentCourse.state._id;
        this.props.history.push(`/user/preexam/${courseId}`);
    };
    render() {
        if (this.props.allChapters.state && this.props.allChapters.state[0]) {
            return (
                <div>
                    {this.props.allChapters.state.map((chapter, index) => (
                        <div key={index}>
                            <ChaptersAccordion
                                withBorder={this.props.withBorder}
                                key={chapter._id}
                                title={chapter.title}
                                description={chapter.description}
                                defaultActiveKey='0'
                                chapter={chapter}
                                courseId={this.props.currentCourse.state._id}
                            />
                        </div>
                    ))}
                    {this.props.currentCourse.state.percentageProgress >=
                    100 ? (
                        <button
                            className='bp'
                            style={{ margin: '20px auto', width: '100%' }}
                            onClick={() => this.goToExam()}>
                            Final Exam
                        </button>
                    ) : (
                        <button
                            style={{ margin: '20px auto', width: '100%' }}
                            className='bd'
                            disabled>
                            Final Exam
                        </button>
                    )}
                </div>
            );
        } else {
            return (
                <div style={{ textAlign: 'center' }}>
                    <h2>{this.state.errors}</h2>;
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    // state argument is the entire redux store
    const { currentLesson, currentChapter, currentCourse, allChapters } = state; // inside curly braces we have the name of the state we want
    return {
        currentLesson,
        currentChapter,
        currentCourse,
        allChapters,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentLesson: (payload) => {
            dispatch({
                type: 'SET_CURRENT_LESSON',
                payload,
            });
        },
        setCurrentChapter: (payload) => {
            dispatch({
                type: 'SET_CURRENT_CHAPTER',
                payload,
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(MenuItems));
