import { faWindowMinimize } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { useHistory } from 'react-router-dom';
import apiCall from '../../../helpers/apiCall';
import './CourseCard.scss';

export const CourseCard = ({ image, title, description, course }) => {
    const history = useHistory(),
        getLesson = async () => {
            if (course.latestChapter != null && course.latestLesson != null) {
                history.push(
                    `/user/course/${course._id}/chapters/${course.latestChapter}/lesson/${course.latestLesson}`
                );
            } else {
                const { success: foundChapters, response: chapters } =
                    await apiCall(
                        'GET',
                        `/users/courses/${course._id}/chapters`
                    );
                if (foundChapters) {
                    const url = `/users/chapters/${chapters.chapters[0]._id}/lessons`;
                    const { success: foundLessons, response: lessons } =
                        await apiCall('GET', url);
                    if (foundLessons) {
                        history.push(
                            `/user/course/${course._id}/chapters/${chapters.chapters[0]._id}/lesson/${lessons.docs[0]._id}`
                        );
                    }
                }
            }
        },
        enrollmentDate =
            'enrollmentUpdatedAt' in course
                ? new Date(course.enrollmentUpdatedAt).toLocaleDateString(
                      'en-US'
                  )
                : '',
        handleOnClick = async (e) => {
            if (
                course.status == 'FINISHED' ||
                course.percentageProgress >= 100
            ) {
                history.push(`/user/preexam/${course._id}`);
                return;
            }

            if ('enrollmentUpdatedAt' in course) {
                await getLesson();
                return;
            }

            history.push(`/user/enroll/${course._id}`);
        };

    return (
        <div
            className='xcc'
            onClick={course.suspendedAt ? () => {} : handleOnClick}
            style={course.suspendedAt ? {} : { cursor: 'pointer' }}>
            <div
                style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: `cover`,
                    backgroundRepeat: `no-repeat`,
                }}>
                <div className='xccp'>
                    {!course.suspendedAt ? (
                        <span>{course.percentageProgress}%</span>
                    ) : (
                        <span>Suspended</span>
                    )}
                </div>
            </div>
            <div>
                <h2>{title}</h2>
                <div className='xccd'>
                    <span>
                        {enrollmentDate.length > 0
                            ? 'Enrolled: ' + enrollmentDate
                            : ''}
                    </span>
                </div>
                <p>{description}</p>
            </div>
            <div>
                {course.suspendedAt ? (
                    <a
                        href='mailto:support@realestateu.com'
                        onClick={(e) => e.stopPropagation()}>
                        <button className='bp'>Contact support</button>
                    </a>
                ) : (
                    <button className='bp' onClick={handleOnClick}>
                        {course.status == 'FINISHED' ||
                        course.percentageProgress >= 100
                            ? 'Take Exam'
                            : 'enrollmentUpdatedAt' in course &&
                              course.status == 'NEW'
                            ? 'Resume'
                            : 'enrollmentUpdatedAt' in course &&
                              course.status == 'INCOMPLETE'
                            ? 'Resume'
                            : course.status == 'EXAM_PASSED' ||
                              course.status == 'FAILED'
                            ? 'Results'
                            : 'Enroll'}
                    </button>
                )}
            </div>
        </div>
    );
};
