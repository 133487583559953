import { connect } from 'react-redux';

import StripeCheckout from 'react-stripe-checkout';
import apiCall from '../../../helpers/apiCall';
import React, { Component } from 'react';

class Card extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // name: props.name,
            // price: props.price,
            // productBy: 'Real Estate U',
            product: {
                name: 'itemname',
                price: 123,
                productBy: 'Real Estate U',
            },
        };
    }

    // we are storing user data in redux store

    render() {
        console.log(this.props.loggedIn);
        const product = this.state.product;

        const price = product.price;
        const name = product.name;
        const productBy = product.productBy;

        const authToken = localStorage.getItem('authToken');
        const makePayment = async (token) => {
            const body = {
                token,
                product,
                packageIds: ['606193575ceac9002e6a9c82'],
                userId: this.props.loggedIn.user._id,
            };

            console.log(body);

            const { status, message, success } = await apiCall(
                'POST',
                '/payment',
                body,
                authToken
            );
            if (success) {
                console.log({ show: status });
            } else {
                console.log(message);
            }
        };

        return (
            <div
                style={{
                    width: '126px',
                    marginTop: '30px',
                    marginBottom: '10px',
                }}>
                <StripeCheckout
                    stripeKey='pk_test_51HxA8EFJRmS5CqMIfGYScTqgaojZtmIPdFQQa8oFgkp3T9C4GjTzKoEz7z8zQ15YSQCMtqf4K2xE9mlIpPOiEKCC0003nmhowy'
                    token={makePayment}
                    name={name}
                    amount={price * 100}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    // state argument is the entire redux store
    const { loggedIn } = state; // inside curly braces we have the name of the state we want
    console.log(state);
    return { loggedIn };
};

export default connect(mapStateToProps)(Card);
