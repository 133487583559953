import React, { Component } from 'react';
import {
    Card,
    Button,
    Row,
    Col,
    Image,
    Container,
    ResponsiveEmbed,
    Input,
} from 'react-bootstrap';
import './index.scss';

export class FillTheMissingWord extends Component {
    constructor(props) {
        super(props);

        this.state = {
            correctAnswer: 'and',
            answer: '',
            options: ['because', 'but', 'and', 'as'],
            sentence: '', // don't know how this quiz will work yet so should leave blank for now.
            correctlyAnswered: null,
        };
    }

    checkAnswer() {
        const answer = this.state.answer;
        if (answer == this.state.correctAnswer) {
            this.setState({ correctlyAnswered: true });
        } else {
            this.setState({ correctlyAnswered: false });
        }
    }
    render() {
        return (
            <div className='fill-missing-word-quiz'>
                <div className='fill-missing-word'>
                    <span className='quiz-statement'>
                        Fred fed Ted bread,{' '}
                        <div className='answer-blank-space'>
                            {this.state.answer}
                        </div>
                        Ted fed Fred bread
                    </span>
                    <Row className='options'>
                        {this.state.options.map((option) => (
                            <Col xs='6' sm='6' lg='3' key={option}>
                                <Button
                                    onClick={() => {
                                        this.setState({ answer: option });
                                    }}>
                                    {option}
                                </Button>
                            </Col>
                        ))}
                    </Row>
                    <h1>
                        {this.state.correctlyAnswered ? (
                            <div className='correct-answer-alert'>
                                <h1>Correct!!!</h1>
                            </div>
                        ) : this.state.correctlyAnswered == false ? (
                            <div className='wrong-answer-alert'>
                                <h1>Try again!</h1>
                            </div>
                        ) : null}
                    </h1>
                    <Button
                        style={{ width: '50%', marginTop: '30px' }}
                        onClick={() => this.checkAnswer()}>
                        Check Answer
                    </Button>
                </div>
            </div>
        );
    }
}
