import React, { Component } from 'react';
import {
    Card,
    Button,
    Row,
    Col,
    Image,
    Container,
    ResponsiveEmbed,
    Input,
} from 'react-bootstrap';
import './index.scss';

export class MultiChoiceMicroQuiz extends Component {
    //

    constructor(props) {
        super(props);

        this.state = {
            availableOptions: ['Paris', 'London', 'New York', 'Rome'],
            correctAnswer: 'London',
            answer: '',
            currentQuestion: 'what is the capital city of England?',
        };
    }

    checkAnswer() {
        const answer = this.state.answer;
        if (answer == this.state.correctAnswer) {
            this.setState({ correctlyAnswered: true, answer: answer });
        } else {
            this.setState({ correctlyAnswered: false, answer: answer });
        }
    }
    render() {
        return (
            <div>
                <div className='multiple-choice'>
                    <div className='multiple-choice-statement'>
                        <h4>{this.state.currentQuestion}</h4>
                    </div>

                    {this.state.availableOptions.map((option) => (
                        <Button
                            className='asbn'
                            type='radio'
                            key={option}
                            onClick={() => {
                                this.setState({ answer: option });
                            }}>
                            {option}
                        </Button>
                    ))}
                    <h1>
                        {this.state.correctlyAnswered ? (
                            <div className='correct-answer-alert'>
                                <h1>Correct!!!</h1>
                            </div>
                        ) : this.state.correctlyAnswered == false ? (
                            <div className='wrong-answer-alert'>
                                <h1>Try again!</h1>
                            </div>
                        ) : null}
                    </h1>
                    <Button
                        style={{ width: '50%', marginTop: '30px' }}
                        onClick={() => this.checkAnswer()}>
                        Check Answer
                    </Button>
                </div>
            </div>
        );
    }
}
