import React, { Component } from 'react';
import { useHistory } from 'react-router';

import './CourseInformation.scss';
import { Col, Row, Button } from 'react-bootstrap';
const CourseInformation = () => {
    const history = useHistory();

    return (
        <div className='course-info'>
            <Row className='desk-info-row'>
                <Col className='desk-info-col'>
                    <Button
                        onClick={() =>
                            history.push(
                                '/user/course/6040bbab31ada76c24da7743/chapters/6040bbab31ada76c24da7743/lesson/6040bbab31ada76c24da7743'
                            )
                        }
                        className='button'>
                        <div>
                            <span className='button--header'>
                                Resume Course
                            </span>
                            <span className='button--description'>
                                Pick up from your last position
                            </span>
                        </div>
                    </Button>
                </Col>
                <Col md={7} lg={8} className='desk-course-info'>
                    <Row className='desk-course-information'>
                        <Col className='expiration'>
                            <div className='expiration-info'>
                                <span>Course expires in</span>
                                <span className='desk-course-information--time'>
                                    360 Days
                                </span>
                            </div>
                        </Col>
                        <Col className='time-left'>
                            <div className='time-left-info'>
                                <span>Time Left</span>
                                <span className='time-left--time'>
                                    42.2 of 77 hours
                                </span>
                            </div>
                        </Col>
                        <Col className='time-completed'>
                            <div className='time-completed-info'>
                                <span>You have completed</span>
                                <span className='time-completed--time'>
                                    60% | 42.3 of 73 hours
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className='mobile-info-row'>
                <Col className='mobile-info-col'>
                    <div className='course-progress'>
                        You have completed 60% | 45.2 of 75 hours
                    </div>
                    <div className='mobile-remaining-and-expiration-info'>
                        <div>Course expires in 360 Days</div>
                        <div>Time Left 45.2 of 75 hours</div>
                    </div>
                    <div>
                        <Button
                            onClick={() =>
                                history.push(
                                    '/user/course/6040bbab31ada76c24da7743/chapters/6040bbab31ada76c24da7743/lesson/6040bbab31ada76c24da7743'
                                )
                            }
                            className='button'>
                            <h5>Resume Course</h5>
                            <span>Pick up from your last position</span>
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default CourseInformation;
