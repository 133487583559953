import React, { Component, Children } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

export default class ComponentTabs extends Component {
    render() {
        const { children, tabs } = this.props;

        return (
            <Tabs>
                {children && children.length !== 0
                    ? Children.map(children, (child) => {
                          const { tabTitle } = child.props,
                              tabKey = tabTitle.toLowerCase().replace(' ', '-');
                          return (
                              <Tab
                                  eventKey={tabKey}
                                  title={tabTitle}
                                  key={tabKey}>
                                  {child}
                              </Tab>
                          );
                      })
                    : tabs.map((tab) => {
                          const { tabTitle, TabComponent } = tab,
                              tabKey = tabTitle.toLowerCase().replace(' ', '-');

                          return (
                              <Tab
                                  eventKey={tabKey}
                                  title={tabTitle}
                                  key={tabKey}>
                                  <TabComponent
                                      docId={this.props.docId}
                                      user={this.props.user}
                                  />
                              </Tab>
                          );
                      })}
            </Tabs>
        );
    }
}
