import './ApiTable.scss';
import React, { Component } from 'react';
// import this, it's a helper function to make api call to our backend
import apiCall from '../../helpers/apiCall';
// some external components we need to render this
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../Pagination';
import { Table } from '../Table';

class ApiTable extends Component {
    constructor(props) {
        super(props); // whenever you use the constructor, have this line here

        // we create this to later reuse it throughout other methods within the class
        // this makes use of the URLSearchParams class available javascript
        this.searchParams = props.location.search
            ? new URLSearchParams(props.location.search)
            : new URLSearchParams();

        let sort = this.searchParams.get('sort');

        if (sort) {
            sort = Object.fromEntries(
                sort.split(',').reduce((array, item) => {
                    let [key, value] = item.split('=');

                    value = parseInt(value);

                    if (key.indexOf('-')) {
                        for (const k of key.split('-')) {
                            if (!Number.isNaN(value)) {
                                array.push([k, value]);
                            }
                        }
                    } else {
                        if (!Number.isNaN(value)) {
                            array.push([key, value]);
                        }
                    }

                    return array;
                }, [])
            );
        }

        this.timeOutFunction = null;
        // state needs to be initialized in the constructor, each state variable goes into this.state
        this.state = {
            tableData: null, // here we will save the api response everytime a call is made
            search: this.searchParams.get('search')
                ? decodeURI(this.searchParams.get('search'))
                : '',
            loading: true,
            sort: sort ?? {},
        };
    }

    // life-cycle method to run some code each time the component is created( or re-created, eg. state change )
    // !!! this doesn't need to be an async function always, the async keyword is used just because
    // we need to use await inside !!!
    async componentDidMount() {
        await this.loadTableData();
    }

    // load the data again
    async componentDidUpdate(prevProps, prevState, snapshot) {
        // but load the data again just when the page changes
        if (prevProps.location.search !== this.props.location.search) {
            await this.loadTableData();
        }
    }

    // this is the code we previously had in our life-cycle method componentDidMount,
    // but we are going to reuse this code so we moved it into a separate function
    loadTableData = async () => {
        // we need to update this each time we load the data with props passed down by react-router
        // make the call to the backend to fetch the data for the table
        if (!this.state.loading) {
            this.setState({
                loading: true,
            });
        }

        let query = new URLSearchParams(this.props.location.search);
        const { response, message, success } = await apiCall(
            this.props.apiCall.method,
            // we concatenate the API path with the required query parameters for pagination, search and sorting
            this.props.apiCall.path +
                (query.toString() ? `?${query.toString()}` : ''),
            this.props.apiCall.payload !== undefined
                ? this.props.apiCall.payload
                : null,
            this.props.apiCall.auth !== undefined
                ? this.props.apiCall.auth
                : true
        );

        // if the call was successful save the response in the state
        if (success) {
            // TODO: remove this in production.
            if (typeof response.docs === 'undefined') {
                alert(
                    'The backend needs a fix, take your sweet 2 minutes to curse Adrian then tell him to fix his shitty backend!'
                );
            }
            // this is a function available in any react component to update the state
            // you can pass one or many parameters of the state at a time, you don't need to pass them all
            // every time you need to update the state, react will check that.
            this.setState({
                tableData: response,
                loading: false,
            });
        } else {
            this.props.setGlobalAlert({ type: 'error', message });
        }
    };

    updateURLParams = () => {
        let query = new URLSearchParams(this.props.location.search);
        // handle sort query param
        if (Object.keys(this.state.sort).length) {
            let sort = '';

            for (const key of Object.keys(this.state.sort)) {
                sort += `${key}=${this.state.sort[key]},`;
            }

            sort = sort.substring(0, sort.length - 1);

            query.set('sort', sort);
        } else {
            let sort = query.get('sort');

            if (sort) {
                query.delete('sort');
            }
        }

        this.props.history.replace(
            `${this.props.match.url}?${query.toString()}`
        );
    };

    handleSort = async (e, columnKey) => {
        e.preventDefault();

        if (!this.state.sort[columnKey]) {
            // sort asc on first click
            this.setState(
                {
                    sort: {
                        ...this.state.sort,
                        [columnKey]: 1,
                    },
                },
                this.updateURLParams
            );
        } else {
            // or just toggle between asc / desc on click
            this.setState(
                {
                    sort: {
                        ...this.state.sort,
                        [columnKey]: this.state.sort[columnKey] === 1 ? -1 : 1,
                    },
                },
                this.updateURLParams
            );
        }
    };

    handleSortReset = async (e, columnKey) => {
        e.preventDefault();
        let { sort } = this.state;

        delete sort[columnKey];

        this.setState(
            {
                sort,
            },
            this.loadTableData
        );

        return false;
    };

    handleSearchSubmit = (e) => {
        e.preventDefault();
    };

    handleSearchChange = async (e) => {
        const input = e.target;

        this.setState(
            {
                search: input.value,
            },
            () => {
                if (this.timeOutFunction) {
                    clearTimeout(this.timeOutFunction);
                }

                this.timeOutFunction = setTimeout(() => {
                    if (input.value) {
                        this.searchParams.set(
                            'search',
                            encodeURI(this.state.search)
                        );
                    } else {
                        this.searchParams.delete('search');
                    }

                    this.searchParams.delete('page');

                    this.props.history.push(
                        this.props.location.pathname +
                            '?' +
                            this.searchParams.toString()
                    );
                }, 1000);
            }
        );
    };

    render() {
        if (this.state.tableData) {
            const {
                state: { tableData, sort, loading },
                props: { hideControls, columns, rowButtons },
                handleSort,
                handleSortReset,
                loadTableData,
            } = this;

            return (
                <div className='table-wrapper'>
                    {!hideControls && (
                        <div className='table-controls'>
                            <Pagination
                                className='table-pagination'
                                activePage={tableData.page}
                                totalPages={tableData.totalPages}
                            />
                            <div className='table-search'>
                                <form
                                    action='/'
                                    className='form'
                                    onSubmit={this.handleSearchSubmit}>
                                    <div className='form__field form__field--inside-icon'>
                                        <input
                                            type='text'
                                            name='search'
                                            defaultValue={this.state.search}
                                            placeholder='Search...'
                                            onChange={this.handleSearchChange}
                                        />
                                        <button
                                            className='btn btn--primary btn--small'
                                            type='submit'>
                                            <Fa icon={faSearch} />
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    )}
                    <Table
                        rows={tableData.docs}
                        rowButtons={rowButtons}
                        columns={columns}
                        onSort={handleSort}
                        onSortReset={handleSortReset}
                        sortData={sort}
                        loading={loading}
                        reloadRows={loadTableData}
                    />
                    {!hideControls && (
                        <div className='table-controls table-controls--bottom'>
                            <Pagination
                                className='table-pagination'
                                activePage={tableData.page}
                                totalPages={tableData.totalPages}
                            />
                        </div>
                    )}
                </div>
            );
        }
        return <div />;
    }
}

// we export de class component passed through withRouter, which adds data needed to the our ApiTable component
export default connect(null, {
    setGlobalAlert: (payload) => ({
        type: 'SET_GLOBAL_ALERT',
        payload,
    }),
})(withRouter(ApiTable));
