import './LessonForm.scoped.scss';
import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import apiCall from '../../helpers/apiCall';
import uuid from 'react-uuid';
import { Tab, Tabs, Button, Modal } from 'react-bootstrap';
import { RouteLeavingGuard } from '../../components/RouteLeavingGuard';
import { Spinner } from '../../components/Spinner';
import LessonFormElement from './LessonFormElement';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import {
    faChevronLeft,
    faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { PageLayout } from '../User/LessonLayouts';

class LessonForm extends Component {
    _isMounted = false;
    eventSource = null;

    state = {
        source:
            this.props.match.path.indexOf('courses') >= 0 ? 'courses' : 'core',
        type:
            this.props.match.path.indexOf('courses') >= 0
                ? 'Course'
                : 'Core Library',
        lessonLoading: false,
        lessonCardsLoading: false,

        lastSavedTs: null,
        minsAgo: null,

        lesson: {},
        cardsPublished: [],
        cardsDraft: [],
        course: {},
        showPreview: false,
    };

    createBreadcrumbs = (props, state) => {
        this.props.pushBreadcrumbLink({
            text: state.source === 'core' ? 'Core Libraries' : 'Courses',
            path: `/admin/${
                state.source === 'core' ? 'core-libraries' : 'courses'
            }`,
        });

        this.props.pushBreadcrumbLink({
            text: `${state.type}: ${state.course.title}`,
            path: `/admin/${
                state.source === 'core' ? 'core-libraries/edit' : 'courses'
            }/${state.course._id}?focus=${state.lesson.chapterId}&highlight=${
                state.lesson._id
            }`,
        });

        this.props.pushBreadcrumbLink({
            text: `Lesson: ${
                state.lesson.title
                    ? state.lesson.title
                    : state.lesson.draft.title
            }`,
            path: `/admin/${
                state.source === 'core' ? 'core-libraries' : 'courses'
            }/ext/lessons/edit/${props.match.params.id}`,
        });
    };

    togglePreview = () => {
        this.setState({
            showPreview: !this.state.showPreview,
        });
    };

    removeBreadcrumbs = (props, state) => {
        this.props.removeBreadcrumbLink({
            text: state.source === 'core' ? 'Core Libraries' : 'Courses',
            path: `/admin/${
                state.source === 'core' ? 'core-libraries' : 'courses'
            }`,
        });

        this.props.removeBreadcrumbLink({
            text: `${state.type}: ${state.course.title}`,
            path: `/admin/${
                state.source === 'core' ? 'core-libraries/edit' : 'courses'
            }/${state.course._id}?focus=${state.lesson.chapterId}&highlight=${
                state.lesson._id
            }`,
        });

        this.props.removeBreadcrumbLink({
            text: `Lesson: ${
                state.lesson.title
                    ? state.lesson.title
                    : state.lesson.draft.title
            }`,
            path: `/admin/${
                state.source === 'core' ? 'core-libraries' : 'courses'
            }/ext/lessons/edit/${props.match.params.id}`,
        });
    };

    componentDidMount = () => {
        this.loadThings();
    };

    componentWillUnmount = async () => {
        this._isMounted = false;
        clearInterval(this.updateTimeInterval);
        clearInterval(this.saveInterval);
        this.removeBreadcrumbs(this.props, this.state);
    };

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        const anyDirtyBefore =
                prevState.lesson.isDirty ||
                prevState.cardsDraft.some((card) => card.isDirty),
            allCleanNow =
                !this.state.lesson.isDirty &&
                this.state.cardsDraft.every((card) => !card.isDirty);

        if (anyDirtyBefore && allCleanNow) {
            this.setState({
                lastSavedTs: Date.now(),
                minsAgo: 0,
            });
            this.updateTimeInterval = setInterval(() => {
                this.setState({
                    minsAgo: Math.floor(
                        (Date.now() - this.state.lastSavedTs) / 1000 / 60
                    ),
                });
            }, 1000 * 60);
        }

        if (prevProps.match.params.id !== this.props.match.params.id) {
            this.removeBreadcrumbs(prevProps, prevState);
            this.loadThings();
        }
    };

    loadThings = async () => {
        this._isMounted = true;
        clearInterval(this.saveInterval);

        this.setState({
            lessonLoading: true,
            lessonCardsLoading: true,
        });

        apiCall(
            'GET',
            `/${this.state.source}/lessons/${this.props.match.params.id}`
        )
            .then(async ({ success, response }) => {
                if (this._isMounted && success && response) {
                    this.setState({
                        lesson: {
                            ...response,
                        },
                    });

                    const courseUrl = `/${this.state.source}/${
                        response.chapter[
                            this.state.source == 'courses'
                                ? 'courseId'
                                : 'coreLibraryId'
                        ]
                    }`;
                    const { response: course } = await apiCall(
                        'GET',
                        courseUrl
                    );

                    if (this._isMounted) {
                        if (course) {
                            this.setState({
                                course,
                            });
                        }
                        this.createBreadcrumbs(this.props, this.state);
                        this.setState({
                            lessonLoading: false,
                        });
                    }
                }
            })
            .catch(() => {
                this.setState({
                    lessonLoading: false,
                });
            });

        apiCall(
            'GET',
            `/${this.state.source}/lessons/${this.props.match.params.id}/cards`
        )
            .then(({ success, response }) => {
                if (this._isMounted && success && response) {
                    this.setState({
                        cardsDraft: response
                            .filter((card) => card.draft)
                            .map((card, i) => {
                                return {
                                    ...card,
                                    uuid: card._id,
                                };
                            })
                            .sort((card1, card2) => {
                                return (
                                    card1.draft.orderIndex -
                                    card2.draft.orderIndex
                                );
                            }),
                        cardsPublished: response
                            .filter((card) => card.cardType)
                            .map((card, i) => {
                                return {
                                    ...card,
                                    uuid: card._id,
                                };
                            })
                            .sort((card1, card2) => {
                                return card1.orderIndex - card2.orderIndex;
                            }),
                    });
                }
                this.setState({
                    lessonCardsLoading: false,
                });
            })
            .catch(() => {
                this.setState({
                    lessonCardsLoading: false,
                });
            });

        // Temporarily disabled autosave
        // this.saveInterval = setInterval(() => {
        //     if (this.isAnythingDirty()) {
        //         this.handleSubmit();
        //     }
        // }, 1000 * 30);
    };

    handleFileChange = (card, type, url, file) => {
        this.handleCardChange(card, `${type}File`, file);
        this.handleCardChange(
            card,
            `source${type[0].toUpperCase() + type.substr(1).toLowerCase()}`,
            url
        );
    };

    handleChange = (event) => {
        if (this.state.lesson.draft[event.target.name] !== event.target.value) {
            this.setState({
                lesson: {
                    ...this.state.lesson,
                    draft: {
                        ...this.state.lesson.draft,
                        [event.target.name]: event.target.value,
                    },
                    isDirty: true,
                },
            });
        }
    };

    handleCardChange = (card, key, value) => {
        this.setState({
            cards: this.state.cardsDraft.map((thisCard) => {
                if (
                    thisCard.uuid === card.uuid &&
                    thisCard.draft[key] !== value
                ) {
                    thisCard.draft[key] = value;
                    thisCard.isDirty = true;
                }
                console.log(value);
                return thisCard;
            }),
        });
    };

    handleSubmit = async (event) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (
            this.state.lesson.isDirty &&
            !this.state.lesson.submissionInProgress
        ) {
            this.setState({
                lesson: {
                    ...this.state.lesson,
                    submissionInProgress: true,
                },
            });

            let lesson = {
                ...this.state.lesson.draft,
                chapterId: this.state.lesson.chapterId,
                menuIndex: this.state.lesson.menuIndex,
                conditionStatement: this.state.lesson.conditionStatement,
            };

            delete lesson._id;
            delete lesson.isDirty;
            delete lesson.submissionInProgress;
            delete lesson.updatedAt;

            apiCall(
                'PUT',
                `/${this.state.source}/lessons/${this.props.match.params.id}`,
                lesson
            ).then(({ success, response }) => {
                if (this._isMounted && success && response) {
                    this.setState({
                        lesson: {
                            ...this.state.lesson,
                            isDirty: false,
                            submissionInProgress: false,
                        },
                    });
                    this.removeBreadcrumbs(this.props, this.state);
                    this.createBreadcrumbs(this.props, this.state);
                } else {
                    this.props.setGlobalAlert({
                        type: 'error',
                        message:
                            'There was a problem with saving this Lesson. Please try again',
                    });
                    this.setState({
                        lesson: {
                            ...this.state.lesson,
                            submissionInProgress: false,
                        },
                    });
                }
            });
        }

        this.state.cardsDraft.map(async (card) => {
            if ((!card.isDirty && !card.isNew) || card.submissionInProgress) {
                return;
            }

            this.setState({
                cardsDraft: this.state.cardsDraft.map((thisCard) => {
                    if (card.uuid === thisCard.uuid) {
                        thisCard = {
                            ...thisCard,
                            submissionInProgress: true,
                        };
                    }
                    return thisCard;
                }),
            });

            const uploadPromises = ['image', 'audio', 'video', 'document'].map(
                async (type) => {
                    if (card.draft && card.draft[`${type}File`]) {
                        let filePostData = new FormData();
                        filePostData.append('file', card.draft[`${type}File`]);
                        const { success, response } = await apiCall(
                            'POST',
                            '/file',
                            filePostData
                        );

                        if (success && this._isMounted) {
                            card.draft[
                                `source${
                                    type[0].toUpperCase() +
                                    type.substr(1).toLowerCase()
                                }`
                            ] = response.url;

                            delete card.draft[`${type}File`];
                        }
                    }
                }
            );

            await Promise.all(uploadPromises);

            const method = card.isNew ? 'POST' : 'PUT';
            const urlEnding = card.isNew
                ? `${this.state.lesson._id}/cards`
                : `cards/${card._id}`;
            const url = `/${this.state.source}/lessons/${urlEnding}`;

            const thisCardUuid = card.uuid;

            card = { ...card.draft };

            apiCall(method, url, card).then(({ success, response }) => {
                if (this._isMounted && success && response && response._id) {
                    this.setState({
                        cardsDraft: this.state.cardsDraft.map((thisCard) => {
                            if (thisCardUuid === thisCard.uuid) {
                                thisCard = {
                                    ...response,
                                    uuid: response._id,
                                };
                            }
                            return thisCard;
                        }),
                    });
                } else {
                    this.props.setGlobalAlert({
                        type: 'error',
                        message:
                            'There was a problem with saving this Lesson. Please try again',
                    });
                    this.setState({
                        cardsDraft: this.state.cardsDraft.map((thisCard) => {
                            if (thisCardUuid === thisCard.uuid) {
                                thisCard = {
                                    ...thisCard,
                                    submissionInProgress: false,
                                };
                            }
                            return thisCard;
                        }),
                    });
                }
            });
        });
    };

    swapCards = (oldIdx, newIdx) => {
        let cards = [...this.state.cardsDraft];

        const [reorderedItem] = cards.splice(oldIdx, 1);
        cards.splice(newIdx, 0, reorderedItem);

        this.setState({
            cardsDraft: cards.map((card, i) => {
                if (card.draft.orderIndex !== i) {
                    card = {
                        ...{
                            ...card,
                            draft: {
                                ...card.draft,
                                orderIndex: i,
                            },
                        },
                        isDirty: true,
                    };
                }
                return card;
            }),
        });
    };

    handleDragAndDrop = (result) => {
        if (
            !result.destination ||
            result.source.index === result.destination.index
        ) {
            return;
        }
        this.swapCards(result.source.index, result.destination.index);
    };

    manualIdxChange = (newIdx, oldIdx) => {
        if (oldIdx === newIdx) {
            return;
        }
        this.swapCards(oldIdx, newIdx);
    };

    addNewCard = async (idx) => {
        idx = parseInt(idx) || 0;
        let cards = [...this.state.cardsDraft];
        cards.splice(idx, 0, await this.getEmptyCard());
        this.setState({
            cardsDraft: cards.map((card, i) => {
                if (card.draft && card.draft.orderIndex !== i) {
                    card.draft.orderIndex = i;
                    card.isDirty = true;
                }
                return card;
            }),
        });
    };

    getEmptyCard = async () => {
        const { success, response } = await apiCall(
            'GET',
            '/settings/values/default_heading_bgcolor,default_heading_textcolor'
        );

        return {
            uuid: uuid(),
            draft: {
                cardTitle: 'Card',
                cardType: 'TEXT',
                imageImportance: false,
                heading: '',
                subHeading: '',
                sourceImage: '',
                sourceVideo: '',
                sourceAudio: '',
                sourceDocument: '',
                bgColor: success ? response.default_heading_bgcolor : '#000000',
                fgColor: success
                    ? response.default_heading_textcolor
                    : '#FFFFFF',
                transcript: '',
                content: '',
                info: '',
                orderIndex: 0,
                question: '',
                quiz: {},
                answers: [
                    {
                        value: '',
                        correct: false,
                    },
                    {
                        value: '',
                        correct: false,
                    },
                    {
                        value: '',
                        correct: false,
                    },
                    {
                        value: '',
                        correct: false,
                    },
                ],
                requiresCorrectAnswer: false,
            },
            isNew: true,
            isDirty: true,
        };
    };

    deleteItem = async (idx) => {
        let cards = [...this.state.cardsDraft];
        const [deletedItem] = cards.splice(idx, 1);

        if (!deletedItem.isNew) {
            const { success, response } = await apiCall(
                'DELETE',
                `/${this.state.source}/lessons/cards/${deletedItem._id}`
            );

            if (this._isMounted && success && response) {
                this.setState({
                    cardsDraft: cards.map((card, i) => {
                        if (card.draft.orderIndex !== i) {
                            card = {
                                ...card,
                                draft: {
                                    ...card.draft,
                                    orderIndex: i,
                                },
                                isDirty: true,
                            };
                        }
                        return card;
                    }),
                });
                if (this.isAnythingDirty()) {
                    this.handleSubmit();
                }
                this.props.setGlobalAlert({
                    type: 'success',
                    message: 'Lesson Card was deleted',
                });
            } else {
                this.props.setGlobalAlert({
                    type: 'error',
                    message:
                        'There was a problem with deleting the Card. Please try again',
                });
            }
        } else {
            this.setState({
                cardsDraft: cards.map((card, i) => {
                    return {
                        ...card,
                        draft: {
                            ...card.draft,
                            orderIndex: i,
                        },
                    };
                }),
            });
        }
    };

    isAnythingDirty = () => {
        return (
            this.state.lesson.isDirty ||
            this.state.cardsDraft.some((card) => card.isDirty)
        );
    };

    publish = async () => {
        if (this.eventSource === null) {
            this.setState({
                lessonLoading: true,
                lessonCardsLoading: true,
            });

            this.eventSource = new EventSource(
                `${process.env.REACT_APP_API_URL}/${
                    this.state.source
                }/lessons/${
                    this.props.match.params.id
                }/publish?token=${encodeURI(this.props.loggedIn.token)}`
            );

            this.eventSource.onmessage = (event) => {
                const eventData = JSON.parse(event.data);
                if (eventData.success) {
                    const { currentlyPublished, toPublish } = eventData.data;

                    if (toPublish === currentlyPublished) {
                        this.eventSource.close();
                        this.eventSource = null;
                        this.loadThings();
                        this.props.setGlobalAlert({
                            type: 'success',
                            message: 'Lesson has been published',
                        });
                    }
                } else {
                    this.props.setGlobalAlert({
                        type: 'error',
                        message:
                            'There was a problem with publishing this Lesson. Please try again.',
                    });
                }
            };

            this.eventSource.onerror = () => {
                this.eventSource.close();
                this.eventSource = null;

                this.setState({
                    lessonLoading: false,
                    lessonCardsLoading: false,
                });
                this.props.setGlobalAlert({
                    type: 'error',
                    message:
                        'There was a problem with publishing this Lesson. Please try again.',
                });
            };
        }
    };

    render() {
        if (this.state.lessonLoading) {
            return <Spinner />;
        }

        return (
            <>
                {this.state.source === 'courses' && (
                    <div className='lesson-navigation'>
                        {this.state?.lesson?.previousLesson && (
                            <Button
                                as={Link}
                                className='lesson-navigation__previous bd'
                                to={`/admin/courses/chapters/${this.state.lesson.previousLesson.chapterId}/ext/lessons/edit/${this.state.lesson.previousLesson._id}`}>
                                <Fa icon={faChevronLeft} className='mr-2' />
                                Previous lesson
                            </Button>
                        )}
                        {this.state?.lesson?.nextLesson && (
                            <Button
                                as={Link}
                                className='lesson-navigation__next bd'
                                to={`/admin/courses/chapters/${this.state.lesson.nextLesson.chapterId}/ext/lessons/edit/${this.state.lesson.nextLesson._id}`}>
                                Next lesson
                                <Fa icon={faChevronRight} className='ml-2' />
                            </Button>
                        )}
                    </div>
                )}
                <RouteLeavingGuard
                    when={this.isAnythingDirty()}
                    navigate={(path) => this.props.history.push(path)}
                    shouldBlockNavigation={() => {
                        return this.isAnythingDirty();
                    }}
                />
                <Tabs>
                    <Tab eventKey='1' title='Draft' key='1'>
                        <LessonFormElement
                            editable={true}
                            togglePreview={this.togglePreview}
                            parent={this}
                            lesson={this.state.lesson.draft || {}}
                            cards={this.state.cardsDraft.map((card) => {
                                return {
                                    ...card.draft,
                                    uuid: card.uuid || card._id,
                                };
                            })}
                            handleSubmit={this.handleSubmit}
                        />
                    </Tab>
                    <Tab eventKey='2' title='Published' key='2'>
                        <LessonFormElement
                            editable={false}
                            parent={this}
                            published={this.state.lesson.title !== ''}
                            lesson={this.state.lesson}
                            cards={this.state.cardsPublished}
                        />
                    </Tab>
                </Tabs>
                <Modal
                    className='lesson-preview'
                    show={this.state.showPreview}
                    size='xl'
                    onHide={this.togglePreview}>
                    <Modal.Header closeButton>
                        {this.state?.lesson?.draft?.title}
                    </Modal.Header>
                    <Modal.Body
                        onClick={(e) => {
                            e.stopPropagation();
                        }}>
                        <PageLayout cards={this.state.cardsDraft} />
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default connect(
    (state) => {
        return { loggedIn: state.loggedIn };
    },
    {
        pushBreadcrumbLink: (payload) => ({
            type: 'PUSH_BREADCRUMB_LINK',
            payload,
        }),
        removeBreadcrumbLink: (payload) => ({
            type: 'REMOVE_BREADCRUMB_LINK',
            payload,
        }),
        setGlobalAlert: (payload) => ({
            type: 'SET_GLOBAL_ALERT',
            payload,
        }),
    }
)(withRouter(LessonForm));
