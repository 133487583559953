import React, { Component } from 'react';
import DocumentViewer from '../../../components/User/DocumentViewer';

class UserDocumentViewer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const documentId = this.props.match.params.documentId;
        const url = `https://localhost:5000/api/files/${documentId}`;
        return (
            <div
                className='user-document-viewer'
                style={{ marginTop: '-54px' }}>
                <DocumentViewer document={url} />
            </div>
        );
    }
}

export default UserDocumentViewer;
