import React, { Component } from 'react';
import TypingDNA from './typingRecorder';
import apiCall from '../../../helpers/apiCall';
import axios from 'axios';

import {
    Button,
    Modal,
    FormControl,
    Row,
    Col,
    Container,
} from 'react-bootstrap';

class VerifyTypingPattern extends Component {
    constructor(props) {
        super(props);

        this.state = {
            typingPattern: '',
            result: '',
        };
    }

    render() {
        const tdna = new TypingDNA();

        const currentQuote = 'hello my name is haroon';

        TypingDNA.addTarget('typing-dna-verify');

        const compareTexts = (t1, t2) => {
            var dt1 = t1.split(' ');
            var dt2 = t2.split(' ');
            var total2 = 0;
            var total1 = 0;
            for (var i in dt2) {
                total2 += dt1.indexOf(dt2[i]) > -1 ? 1 : 0;
            }
            for (var i in dt1) {
                total1 += dt2.indexOf(dt1[i]) > -1 ? 1 : 0;
            }
            var total = total1 < total2 ? total1 : total2;
            var length = dt1.length > dt2.length ? dt1.length : dt2.length;
            /** returns a number between 0 (completely different texts) and 1 (identical texts) */
            return total / length;
        };

        // function swapContent(oldElement, newElement, callback) {

        const CheckUser = async () => {
            const user = {
                userId: '5f656a212e8d42d506cbba69',
            };
            const token = localStorage.getItem('authToken');
            const response = await axios('http://localhost:5000/api/index', {
                data: {
                    userId: '5f656a212e8d42d506cbba69',
                },

                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                withCredentials: true,

                method: 'POST',
                url: 'http://localhost:5000/api/index',
            });

            console.log(response);
        };

        const onEnterPress = (e) => {
            // when they press enter, start verification
            if (e.charCode === 13 && e.shiftKey == false) {
                e.preventDefault();
                verifyText();
            }
        };

        const verifyTP = async (typingPattern) => {
            const token = localStorage.getItem('authToken');
            if (typingPattern != (null || undefined)) {
                console.log('hello');

                const response = await axios({
                    method: 'POST',
                    url: 'http://localhost:5000/api/verify',
                    data: {
                        tp: typingPattern,
                    },
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                });

                console.log(response);
                // alert(response.data.data.page);
                // this.setState({ result: 'response.data.data.page' });
            } else {
                console.log('no typing pattern');
            }
        };

        const verifyText = () => {
            if (
                compareTexts(
                    document.getElementById('typing-dna-verify').value,
                    currentQuote
                ) > 0.7
            ) {
                /** at least 70% of the words should be typed correctly */
                let tp = tdna.getTypingPattern({ type: 1 });
                console.log(tp);
                this.setState({ typingPattern: tp });
                if (
                    tp != (null || undefined) &&
                    tdna.isMobile() &&
                    TypingDNA.checkMobileValidity(tp) < 0.7
                ) {
                    alert(
                        "Looks like you're on mobile. Try authenticating on another device"
                    );
                    return false;
                }
                TypingDNA.reset();

                verifyTP(tp);

                return true;
            } else {
                alert('Too many typos, please re-type');
                return false;
            }
        };

        return (
            <Container>
                <Row>
                    <Button variant='primary' onClick={() => CheckUser()}>
                        Check User
                    </Button>

                    <div>Type the text below</div>
                    <h1> {this.state.result}</h1>
                    <h4>{currentQuote}</h4>
                    <FormControl
                        id='typing-dna-verify'
                        onKeyPress={onEnterPress}
                    />

                    <Button onClick={verifyText}>Authenticate Pattern</Button>
                </Row>
            </Container>
        );
    }
}

export default VerifyTypingPattern;
