import React, { Component } from 'react';
import StudentForm from '../../../components/User/StudentForm';

class PostExam extends Component {
    render() {
        return (
            <StudentForm
                typeSlug='post-exam'
                typeTitle='Post Exam'
                courseId={this.props.match.params.courseId}
            />
        );
    }
}

export default PostExam;
