import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Course from './Course';
import CourseTable from './CourseTable';
import EnrolledStudentsList from './EnrolledStudentsList';
import LessonForm from '../../../components/LessonForm';

export default class Courses extends Component {
    render() {
        return (
            <div>
                <Switch>
                    <Route
                        exact
                        path='/admin/courses'
                        component={CourseTable}
                    />
                    <Route
                        exact
                        path='/admin/courses/:courseId/students'
                        component={EnrolledStudentsList}
                    />
                    <Route
                        exact
                        path='/admin/courses/chapters/:chapterId/ext/lessons/edit/:id'>
                        <LessonForm />
                    </Route>
                    <Route
                        path='/admin/courses/:id'
                        key='admin-courses-edit'
                        component={Course}
                    />
                </Switch>
            </div>
        );
    }
}
