import React, { Component } from 'react';
import Select from 'react-select';
import {
    Row,
    Col,
    Accordion,
    Card,
    Button,
    FormGroup,
    Form,
    OverlayTrigger,
    Tooltip,
    Modal,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPencilAlt,
    faTrash,
    faEllipsisV,
} from '@fortawesome/free-solid-svg-icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import './LessonCard.scss';
import { ConfirmationModal } from '../../components/ConfirmationModal';
import { FileUpload } from '../../components/FileUpload/';
import ExamForm from '../../pages/Admin/Courses/ExamForm';

export default class LessonCard extends Component {
    _isMounted = false;

    state = {
        modalShow: false,
        showQuizModal: false,
    };

    cardTypes = [
        'TEXT',
        'HEADING',
        'AUDIO',
        'AUDIO_BIG',
        'VIDEO',
        'IMAGE_TOP',
        'IMAGE_RIGHT',
        'IMAGE_LEFT',
        'IMAGE_BG',
        'SINGLE_QUESTION',
        'DOCUMENT',
        'QUIZ',
    ];

    constructor(props) {
        super(props);

        this.cardTypeOpts = this.cardTypes.map((type) => {
            const label = (
                type.charAt(0).toUpperCase() + type.substr(1).toLowerCase()
            ).replaceAll('_', ' ');
            return { label, value: type };
        });
    }

    handleVideoIdPaste = async (event) => {
        const id =
            /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/gi.exec(
                (event.clipboardData || window.clipboardData).getData('text')
            );

        if (id && id[1]) {
            event.preventDefault();
            event.stopPropagation();

            this.props.handleCardChange(
                this.props.card,
                event.target.name,
                id[1]
            );
        }
    };

    componentWillUnmount = async () => {
        this._isMounted = false;
    };

    componentDidMount = async () => {
        this._isMounted = true;
    };

    handleFileChange = (type, url, file) => {
        this.props.handleFileChange(this.props.card, type, url, file);
    };

    quizModalHandleClose = () => this.setState({ showQuizModal: false });
    quizModalHandleShow = () => this.setState({ showQuizModal: true });

    handleQuizChange = (quiz) => {
        this.props.handleCardChange(this.props.card, 'quiz', quiz);
    };

    render() {
        return (
            <div>
                <ConfirmationModal
                    show={this.state.modalShow}
                    hideModal={() => {
                        this.setState({ modalShow: false });
                    }}
                    confirmAction={() => {
                        this.props.deleteItem(this.props.idx);
                        this.setState({
                            modalShow: false,
                        });
                    }}
                    titleText={'Are you sure?'}
                    bodyText={[
                        'You are about to ',
                        <strong key='modal-type'>delete</strong>,
                        ' this Card.',
                    ]}
                />

                <Modal
                    style={{ maxHeight: '90%', marginTop: '60px' }}
                    show={this.state.showQuizModal}
                    onHide={this.quizModalHandleClose}
                    backdrop='static'
                    keyboard={false}
                    dialogClassName='modal-90w'>
                    <Modal.Header closeButton>
                        <Modal.Title>Quiz</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ExamForm
                            type='Quiz'
                            quiz={this.props.card.quiz}
                            editable={this.props.editable}
                            handleQuizChange={this.handleQuizChange}
                            hideQuizModal={this.quizModalHandleClose}
                        />
                    </Modal.Body>
                </Modal>

                {this.props.editable && this.props.card.orderIndex === 0 ? (
                    <Button
                        className='btn-plus my-1'
                        size='sm'
                        block
                        onClick={(e) => {
                            this.props.addNewCard(0);
                        }}>
                        +
                    </Button>
                ) : (
                    <></>
                )}
                <Accordion defaultActiveKey='1'>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey='0'>
                            <Row>
                                <Col xs={12} md={4} className='my-2 my-lg-0'>
                                    {this.props.editable ? (
                                        <span
                                            {...this.props.dragHandleProps}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}>
                                            <FontAwesomeIcon
                                                icon={faEllipsisV}
                                                style={{ marginRight: '20px' }}
                                            />
                                        </span>
                                    ) : (
                                        <></>
                                    )}
                                    <Form.Control
                                        type='number'
                                        placeholder='Index'
                                        min='1'
                                        max={this.props.numOfCards}
                                        id='orderIndex'
                                        name='orderIndex'
                                        className='d-inline-block'
                                        style={{
                                            width: '20%',
                                            marginRight: '20px',
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                        value={this.props.card.orderIndex + 1}
                                        onChange={(event) => {
                                            this.props.manualIdxChange(
                                                event.target.value - 1,
                                                this.props.card.orderIndex
                                            );
                                        }}
                                        readOnly={
                                            !this.props.editable ||
                                            this.props.card.submissionInProgress
                                        }
                                    />
                                    <Form.Control
                                        type='text'
                                        placeholder='Card title...'
                                        minLength='3'
                                        maxLength='512'
                                        id='cardTitle'
                                        name='cardTitle'
                                        className='d-inline-block'
                                        style={{
                                            width: '50%',
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                        value={this.props.card.cardTitle}
                                        onChange={(event) => {
                                            this.props.handleCardChange(
                                                this.props.card,
                                                event.target.name,
                                                event.target.value
                                            );
                                        }}
                                        readOnly={
                                            !this.props.editable ||
                                            this.props.card.submissionInProgress
                                        }
                                    />
                                </Col>
                                <Col
                                    xs={12}
                                    md={5}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}>
                                    <Select
                                        name='cardType'
                                        placeholder='Card type...'
                                        menuPortalTarget={document.body}
                                        menuPosition={'fixed'}
                                        value={
                                            this.cardTypeOpts.filter((opt) => {
                                                return (
                                                    this.props.card.cardType ===
                                                    opt.value
                                                );
                                            })[0]
                                        }
                                        defaultValue={this.cardTypeOpts[0]}
                                        options={this.cardTypeOpts}
                                        onChange={(event) => {
                                            this.props.handleCardChange(
                                                this.props.card,
                                                'cardType',
                                                event.value
                                            );
                                        }}
                                        isDisabled={
                                            !this.props.editable ||
                                            this.props.card.submissionInProgress
                                        }></Select>
                                </Col>
                                {this.props.editable ? (
                                    <Col
                                        xs={12}
                                        md={3}
                                        className='my-2 my-md-0'>
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip>Delete</Tooltip>}>
                                            <Button
                                                variant='link'
                                                type='button'
                                                className='float-right ml-1'
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.setState({
                                                        modalShow: true,
                                                    });
                                                }}
                                                disabled={
                                                    this.props.card
                                                        .submissionInProgress
                                                }>
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                />
                                            </Button>
                                        </OverlayTrigger>
                                        <OverlayTrigger
                                            placement='top'
                                            overlay={<Tooltip>Edit</Tooltip>}>
                                            <Accordion.Toggle
                                                as={Button}
                                                variant='link'
                                                className='float-right mx-1'
                                                eventKey='0'>
                                                <FontAwesomeIcon
                                                    icon={faPencilAlt}
                                                />
                                            </Accordion.Toggle>
                                        </OverlayTrigger>
                                    </Col>
                                ) : (
                                    <></>
                                )}
                            </Row>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey='0'>
                            <Card.Body>
                                <Row>
                                    {this.props.card.cardType ===
                                    'SINGLE_QUESTION' ? (
                                        <Col>
                                            <FormGroup>
                                                <Form.Label htmlFor='question'>
                                                    Question
                                                </Form.Label>
                                                <Form.Control
                                                    id='question'
                                                    name='question'
                                                    type='text'
                                                    minLength='3'
                                                    maxLength='512'
                                                    value={
                                                        this.props.card.question
                                                    }
                                                    onChange={(event) => {
                                                        this.props.handleCardChange(
                                                            this.props.card,
                                                            event.target.name,
                                                            event.target.value
                                                        );
                                                    }}
                                                    readOnly={
                                                        !this.props.editable ||
                                                        this.props.card
                                                            .submissionInProgress
                                                    }
                                                />
                                            </FormGroup>
                                            <Form.Group>
                                                <Form.Label>Answers</Form.Label>
                                                <Col>
                                                    {[0, 1, 2, 3].map((i) => {
                                                        return (
                                                            <Form.Label
                                                                className='d-block'
                                                                key={i}>
                                                                <Form.Check
                                                                    type='radio'
                                                                    name={`answersCorrect-${this.props.card.uuid}`}
                                                                    className='d-inline-block'
                                                                    checked={
                                                                        this
                                                                            .props
                                                                            .card
                                                                            .answers &&
                                                                        this
                                                                            .props
                                                                            .card
                                                                            .answers[
                                                                            i
                                                                        ]
                                                                            .correct
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        const answers =
                                                                            this.props.card.answers.map(
                                                                                (
                                                                                    answer,
                                                                                    j
                                                                                ) => {
                                                                                    answer.correct =
                                                                                        j ===
                                                                                        i;
                                                                                    return answer;
                                                                                }
                                                                            );

                                                                        this.props.handleCardChange(
                                                                            this
                                                                                .props
                                                                                .card,
                                                                            'answers',
                                                                            answers
                                                                        );
                                                                    }}
                                                                    disabled={
                                                                        !this
                                                                            .props
                                                                            .editable ||
                                                                        this
                                                                            .props
                                                                            .card
                                                                            .submissionInProgress
                                                                    }
                                                                />
                                                                <Form.Control
                                                                    className='d-inline-block ml-3'
                                                                    style={{
                                                                        width: '80%',
                                                                    }}
                                                                    value={
                                                                        this
                                                                            .props
                                                                            .card
                                                                            .answers &&
                                                                        this
                                                                            .props
                                                                            .card
                                                                            .answers[
                                                                            i
                                                                        ].value
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        const answers =
                                                                            this.props.card.answers.map(
                                                                                (
                                                                                    answer,
                                                                                    j
                                                                                ) => {
                                                                                    if (
                                                                                        i ===
                                                                                        j
                                                                                    ) {
                                                                                        answer.value =
                                                                                            event.target.value;
                                                                                    }
                                                                                    return answer;
                                                                                }
                                                                            );

                                                                        this.props.handleCardChange(
                                                                            this
                                                                                .props
                                                                                .card,
                                                                            'answers',
                                                                            answers
                                                                        );
                                                                    }}
                                                                />
                                                            </Form.Label>
                                                        );
                                                    })}
                                                </Col>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Check
                                                    className='ml-3 mt-3'
                                                    id='requiresCorrectAnswer'
                                                    name='requiresCorrectAnswer'
                                                    type='checkbox'
                                                    label='&nbsp;Require Correct Answer To Proceed'
                                                    checked={
                                                        this.props.card
                                                            .requiresCorrectAnswer
                                                    }
                                                    onChange={(event) => {
                                                        this.props.handleCardChange(
                                                            this.props.card,
                                                            event.target.name,
                                                            event.target.checked
                                                        );
                                                    }}
                                                    disabled={
                                                        !this.props.editable ||
                                                        this.props.card
                                                            .submissionInProgress
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    ) : this.props.card.cardType === 'QUIZ' ? (
                                        <Col>
                                            <Button
                                                onClick={
                                                    this.quizModalHandleShow
                                                }>
                                                {this.props.card.quiz &&
                                                !this.props.editable
                                                    ? 'View'
                                                    : 'Edit'}
                                            </Button>
                                        </Col>
                                    ) : (
                                        <Col>
                                            <FormGroup>
                                                <Form.Label htmlFor='heading'>
                                                    Heading
                                                </Form.Label>
                                                <Form.Control
                                                    id='heading'
                                                    name='heading'
                                                    type='text'
                                                    minLength='3'
                                                    maxLength='512'
                                                    value={
                                                        this.props.card.heading
                                                    }
                                                    onChange={(event) => {
                                                        this.props.handleCardChange(
                                                            this.props.card,
                                                            event.target.name,
                                                            event.target.value
                                                        );
                                                    }}
                                                    readOnly={
                                                        !this.props.editable ||
                                                        this.props.card
                                                            .submissionInProgress
                                                    }
                                                />
                                            </FormGroup>

                                            {(this.props.card.cardType ===
                                                'HEADING' ||
                                                this.props.card.cardType ===
                                                    'TEXT' ||
                                                this.props.card.cardType ===
                                                    'IMAGE_LEFT' ||
                                                this.props.card.cardType ===
                                                    'IMAGE_RIGHT' ||
                                                this.props.card.cardType ===
                                                    'IMAGE_TOP' ||
                                                this.props.card.cardType ===
                                                    'IMAGE_BG' ||
                                                this.props.card.cardType ===
                                                    'DOCUMENT') && (
                                                <FormGroup>
                                                    <Form.Label htmlFor='text'>
                                                        Text
                                                    </Form.Label>
                                                    <CKEditor
                                                        editor={Editor}
                                                        data={
                                                            this.props.card
                                                                .content
                                                        }
                                                        onChange={(
                                                            event,
                                                            editor
                                                        ) => {
                                                            this.props.handleCardChange(
                                                                this.props.card,
                                                                'content',
                                                                editor.getData()
                                                            );
                                                        }}
                                                        disabled={
                                                            !this.props
                                                                .editable ||
                                                            this.props.card
                                                                .submissionInProgress
                                                        }
                                                    />
                                                </FormGroup>
                                            )}

                                            {(this.props.card.cardType ===
                                                'VIDEO' ||
                                                this.props.card.cardType ===
                                                    'AUDIO' ||
                                                this.props.card.cardType ===
                                                    'AUDIO_BIG') && (
                                                <FormGroup>
                                                    <Form.Label htmlFor='subHeading'>
                                                        Sub Heading
                                                    </Form.Label>
                                                    <Form.Control
                                                        id='subHeading'
                                                        name='subHeading'
                                                        type='text'
                                                        minLength='3'
                                                        maxLength='512'
                                                        value={
                                                            this.props.card
                                                                .subHeading
                                                        }
                                                        onChange={(event) => {
                                                            this.props.handleCardChange(
                                                                this.props.card,
                                                                event.target
                                                                    .name,
                                                                event.target
                                                                    .value
                                                            );
                                                        }}
                                                        readOnly={
                                                            !this.props
                                                                .editable ||
                                                            this.props.card
                                                                .submissionInProgress
                                                        }
                                                    />
                                                </FormGroup>
                                            )}

                                            {this.props.card.cardType ===
                                                'HEADING' && (
                                                <>
                                                    <FormGroup>
                                                        <Accordion defaultActiveKey='1'>
                                                            <Form.Label htmlFor='bgColor'>
                                                                <Accordion.Toggle
                                                                    as={Button}
                                                                    variant='link'
                                                                    eventKey='0'>
                                                                    <strong>
                                                                        Background
                                                                        Color
                                                                    </strong>
                                                                </Accordion.Toggle>
                                                            </Form.Label>
                                                            <Accordion.Collapse eventKey='0'>
                                                                <Form.Control
                                                                    type='color'
                                                                    id='bgColor'
                                                                    name='bgColor'
                                                                    value={
                                                                        this
                                                                            .props
                                                                            .card
                                                                            .bgColor
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        this.props.handleCardChange(
                                                                            this
                                                                                .props
                                                                                .card,
                                                                            event
                                                                                .target
                                                                                .name,
                                                                            event
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                    readOnly={
                                                                        !this
                                                                            .props
                                                                            .editable ||
                                                                        this
                                                                            .props
                                                                            .card
                                                                            .submissionInProgress
                                                                    }
                                                                />
                                                            </Accordion.Collapse>
                                                        </Accordion>
                                                    </FormGroup>

                                                    <FormGroup>
                                                        <Accordion defaultActiveKey='1'>
                                                            <Form.Label htmlFor='fgColor'>
                                                                <Accordion.Toggle
                                                                    as={Button}
                                                                    variant='link'
                                                                    eventKey='0'>
                                                                    <strong>
                                                                        Font
                                                                        Color
                                                                    </strong>
                                                                </Accordion.Toggle>
                                                            </Form.Label>
                                                            <Accordion.Collapse eventKey='0'>
                                                                <Form.Control
                                                                    type='color'
                                                                    id='fgColor'
                                                                    name='fgColor'
                                                                    value={
                                                                        this
                                                                            .props
                                                                            .card
                                                                            .fgColor
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        this.props.handleCardChange(
                                                                            this
                                                                                .props
                                                                                .card,
                                                                            event
                                                                                .target
                                                                                .name,
                                                                            event
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                    readOnly={
                                                                        !this
                                                                            .props
                                                                            .editable ||
                                                                        this
                                                                            .props
                                                                            .card
                                                                            .submissionInProgress
                                                                    }
                                                                />
                                                            </Accordion.Collapse>
                                                        </Accordion>
                                                    </FormGroup>
                                                </>
                                            )}

                                            {(this.props.card.cardType ===
                                                'IMAGE_LEFT' ||
                                                this.props.card.cardType ===
                                                    'IMAGE_RIGHT' ||
                                                this.props.card.cardType ===
                                                    'IMAGE_TOP' ||
                                                this.props.card.cardType ===
                                                    'IMAGE_BG') && (
                                                <Row>
                                                    <Col>
                                                        <FormGroup>
                                                            <FileUpload
                                                                id='sourceImage'
                                                                name='sourceImage'
                                                                url={
                                                                    this.props
                                                                        .card
                                                                        .sourceImage
                                                                }
                                                                handleFileChange={
                                                                    this
                                                                        .handleFileChange
                                                                }
                                                                type='image'
                                                                disabled={
                                                                    !this.props
                                                                        .editable ||
                                                                    this.props
                                                                        .card
                                                                        .submissionInProgress
                                                                }
                                                            />
                                                        </FormGroup>
                                                    </Col>
                                                    <Col className='pt-2'>
                                                        <Form.Group>
                                                            <Form.Check
                                                                id='imageImportance'
                                                                name='imageImportance'
                                                                type='checkbox'
                                                                label='&nbsp;Mark Image as
                                                        Important'
                                                                checked={
                                                                    this.props
                                                                        .card
                                                                        .imageImportance
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    this.props.handleCardChange(
                                                                        this
                                                                            .props
                                                                            .card,
                                                                        event
                                                                            .target
                                                                            .name,
                                                                        event
                                                                            .target
                                                                            .checked
                                                                    );
                                                                }}
                                                                disabled={
                                                                    !this.props
                                                                        .editable ||
                                                                    this.props
                                                                        .card
                                                                        .submissionInProgress
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            )}

                                            {(this.props.card.cardType ===
                                                'AUDIO' ||
                                                this.props.card.cardType ===
                                                    'AUDIO_BIG') && (
                                                <FormGroup>
                                                    <FileUpload
                                                        id='sourceAudio'
                                                        name='sourceAudio'
                                                        url={
                                                            this.props.card
                                                                .sourceAudio
                                                        }
                                                        handleFileChange={
                                                            this
                                                                .handleFileChange
                                                        }
                                                        type='audio'
                                                        disabled={
                                                            !this.props
                                                                .editable ||
                                                            this.props.card
                                                                .submissionInProgress
                                                        }
                                                    />
                                                </FormGroup>
                                            )}

                                            {this.props.card.cardType ===
                                                'VIDEO' && (
                                                <FormGroup>
                                                    <Form.Label htmlFor='heading'>
                                                        Video ID
                                                    </Form.Label>
                                                    <Form.Control
                                                        id='sourceVideo'
                                                        name='sourceVideo'
                                                        placeholder='123456789'
                                                        type='url'
                                                        value={
                                                            this.props.card
                                                                .sourceVideo
                                                        }
                                                        onChange={(event) => {
                                                            this.props.handleCardChange(
                                                                this.props.card,
                                                                event.target
                                                                    .name,
                                                                event.target
                                                                    .value
                                                            );
                                                        }}
                                                        onPaste={
                                                            this
                                                                .handleVideoIdPaste
                                                        }
                                                        readOnly={
                                                            !this.props
                                                                .editable ||
                                                            this.props.card
                                                                .submissionInProgress
                                                        }
                                                    />
                                                </FormGroup>
                                            )}

                                            {this.props.card.cardType !==
                                                'TEXT' &&
                                                this.props.card.cardType !==
                                                    'HEADING' &&
                                                this.props.card.cardType !==
                                                    'DOCUMENT' &&
                                                this.props.card.cardType.indexOf(
                                                    'IMAGE'
                                                ) < 0 && (
                                                    <FormGroup>
                                                        <Form.Label htmlFor='transcript'>
                                                            Transcript
                                                        </Form.Label>
                                                        <CKEditor
                                                            editor={Editor}
                                                            data={
                                                                this.props.card
                                                                    .transcript
                                                            }
                                                            onChange={(
                                                                event,
                                                                editor
                                                            ) => {
                                                                this.props.handleCardChange(
                                                                    this.props
                                                                        .card,
                                                                    'transcript',
                                                                    editor.getData()
                                                                );
                                                            }}
                                                            disabled={
                                                                !this.props
                                                                    .editable ||
                                                                this.props.card
                                                                    .submissionInProgress
                                                            }
                                                        />
                                                    </FormGroup>
                                                )}

                                            {this.props.card.cardType ===
                                                'DOCUMENT' && (
                                                <FormGroup>
                                                    <FileUpload
                                                        id='sourceDocument'
                                                        name='sourceDocument'
                                                        url={
                                                            this.props.card
                                                                .sourceDocument
                                                        }
                                                        handleFileChange={
                                                            this
                                                                .handleFileChange
                                                        }
                                                        type='document'
                                                        disabled={
                                                            !this.props
                                                                .editable ||
                                                            this.props.card
                                                                .submissionInProgress
                                                        }
                                                    />
                                                </FormGroup>
                                            )}

                                            <FormGroup>
                                                <Accordion defaultActiveKey='1'>
                                                    <Form.Label htmlFor='info'>
                                                        <Accordion.Toggle
                                                            as={Button}
                                                            variant='link'
                                                            eventKey='0'>
                                                            <strong>
                                                                Information
                                                            </strong>
                                                        </Accordion.Toggle>
                                                    </Form.Label>
                                                    <Accordion.Collapse eventKey='0'>
                                                        <CKEditor
                                                            editor={Editor}
                                                            data={
                                                                this.props.card
                                                                    .info
                                                            }
                                                            onChange={(
                                                                event,
                                                                editor
                                                            ) => {
                                                                this.props.handleCardChange(
                                                                    this.props
                                                                        .card,
                                                                    'info',
                                                                    editor.getData()
                                                                );
                                                            }}
                                                            disabled={
                                                                !this.props
                                                                    .editable ||
                                                                this.props.card
                                                                    .submissionInProgress
                                                            }
                                                        />
                                                    </Accordion.Collapse>
                                                </Accordion>
                                            </FormGroup>
                                        </Col>
                                    )}
                                </Row>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
                {this.props.editable ? (
                    <Button
                        className='btn-plus my-1'
                        size='sm'
                        block
                        onClick={(e) => {
                            this.props.addNewCard(
                                this.props.card.orderIndex + 1
                            );
                        }}>
                        +
                    </Button>
                ) : (
                    <></>
                )}
            </div>
        );
    }
}
