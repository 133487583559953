import './Admin.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import AdminLayout from '../../layouts/Admin';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { Dashboard } from './Dashboard';
import { Messages } from './Messages';
import { Users } from './Users';
import { Courses } from './Courses';
import { Packages } from './Packages';
import { CoreLibrary } from './CoreLibrary';
import { Quizzes } from './Quizzes';
import { Invoices } from './Invoices';
import { Settings } from './Settings';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Sessions } from './Sessions';
import UserActions from './Users/UserActions';

class Admin extends Component {
    componentDidUpdate(prevProps) {
        if (prevProps.location != this.props.location) {
            this.props.createFormActions({});
        }
    }

    handleCancel() {
        this.props.createFormActions({
            ...this.props.formActions.state,
            reload: true,
        });
    }

    handleSave() {
        this.props.createFormActions({
            ...this.props.formActions.state,
            save: true,
        });
    }

    render() {
        const breadcrumb = Object.keys(this.props.breadcrumb);
        return (
            <AdminLayout>
                <ul id='breadcrumb'>
                    <li>
                        <Link to='/admin'>Dashboard</Link>
                        {breadcrumb && breadcrumb.length > 0 && (
                            <Fa icon={faChevronDown} />
                        )}
                    </li>
                    {breadcrumb.map((path, index) => {
                        return (
                            <li key={path}>
                                <Link to={path}>
                                    {this.props.breadcrumb[path]}
                                </Link>
                                {index !== breadcrumb.length - 1 && (
                                    <Fa icon={faChevronDown} />
                                )}
                            </li>
                        );
                    })}
                    {this.props.formActions.state ? (
                        <li>
                            {this.props.formActions.state.save ? (
                                <button
                                    className='btn btn--primary'
                                    onClick={() => this.handleSave()}
                                    type='submit'
                                    form={this.props.formActions.state.id}>
                                    Save
                                </button>
                            ) : null}

                            {this.props.formActions.state.cancel ? (
                                <button
                                    className='btn btn--light'
                                    type='button'
                                    form={this.props.formActions.state.id}
                                    onClick={() => this.handleCancel()}>
                                    Cancel
                                </button>
                            ) : null}

                            {this.props.formActions.state.userActions ? (
                                <div>
                                    <UserActions
                                        verified={
                                            !!this.props.formActions.state.user
                                                .verified
                                        }
                                        suspended={
                                            !!this.props.formActions.state.user
                                                .suspended
                                        }
                                        userId={
                                            this.props.formActions.state.user
                                                ._id
                                        }
                                    />
                                </div>
                            ) : null}
                        </li>
                    ) : null}
                </ul>
                <div id='admin-content'>
                    <Switch>
                        <Route path='/admin/dashboard' component={Dashboard} />
                        <Route path='/admin/sessions' component={Sessions} />
                        <Route path='/admin/settings' component={Settings} />
                        <Route path='/admin/messages' component={Messages} />
                        <Route path='/admin/users' component={Users} />
                        <Route path='/admin/quizzes' component={Quizzes} />
                        <Route path='/admin/courses' component={Courses} />
                        <Route path='/admin/invoices' component={Invoices} />
                        <Route path='/admin/packages' component={Packages} />
                        <Route
                            path='/admin/core-libraries'
                            component={CoreLibrary}
                        />
                        <Redirect exact from='/admin' to='/admin/dashboard' />
                    </Switch>
                </div>
            </AdminLayout>
        );
    }
}

export default connect(
    (state) => {
        return {
            loggedIn: state.loggedIn,
            breadcrumb: state.globalBreadcrumb,
            formActions: state.formActions,
        };
    },
    {
        setLoggedIn: (payload) => ({
            type: 'SET_LOGGED_IN',
            payload,
        }),
        pushBreadcrumbLink: (payload) => ({
            type: 'PUSH_BREADCRUMB_LINK',
            payload,
        }),
        createFormActions: (payload) => ({
            type: 'CREATE_FORM_ACTIONS',
            payload,
        }),
    }
)(Admin);
