import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import UserLayout from '../../layouts/User';

import { UserLesson } from './UserLesson';
import { UserChapters } from './UserChapters';
import { UserCourse } from './UserCourse';
import { UserExam } from './UserExam';
import { UserDocumentViewer } from './UserDocumentViewer';
import { UserCertificate } from './UserCertificate';
import PurchasePackage from './PurchasePackage/PurchaseCourse';
import Verify from '../../components/User/TypingDNA/Verify';
import Enroll from './UserForms/Enroll';
import PreExam from './UserForms/PreExam';
import PostExam from './UserForms/PostExam';
import EnrollTypingPattern from '../../components/User/TypingDNA/Enroll';
import VerifyTypingPattern from '../../components/User/TypingDNA/Verify';

class User extends Component {
    render() {
        return (
            <Switch>
                <UserLayout>
                    <Route
                        exact
                        path='/user/course/:courseId/chapters/:chapterId/lesson/:lessonId'
                        component={UserLesson}
                    />
                    <Route
                        exact
                        path='/user/enroll/:courseId'
                        component={Enroll}
                    />
                    <Route
                        exact
                        path='/user/preexam/:courseId'
                        component={PreExam}
                    />
                    <Route
                        exact
                        path='/user/certificate/:courseId'
                        component={UserCertificate}
                    />
                    <Route
                        exact
                        path='/user/postexam/:courseId'
                        component={PostExam}
                    />
                    <Route
                        exact
                        path='/user/typingdnaverify'
                        component={VerifyTypingPattern}
                    />
                    <Route
                        exact
                        path='/user/typingdnaenroll'
                        component={EnrollTypingPattern}
                    />
                    <Route
                        exact
                        path='/user/exam/:courseId'
                        component={UserExam}
                    />
                    <Route
                        exact
                        path='/user/orderform'
                        component={PurchasePackage}
                    />
                    <Route
                        exact
                        path='/user/course/:courseId/chapters/'
                        component={UserChapters}
                    />
                    <Route
                        exact
                        path='/user/:packageId/courses'
                        component={UserCourse}
                    />
                    <Route
                        exact
                        path='/user/document/:documentId'
                        component={UserDocumentViewer}
                    />
                </UserLayout>
            </Switch>
        );
    }
}

// const mapState = (state) => {
//     const { loggedIn } = state;
//     return { loggedIn };
// };

// export default connect(mapState, {
//     setLoggedIn: (payload) => ({
//         type: 'SET_LOGGED_IN',
//         payload,
//     }),
// })(User);

export default User;
