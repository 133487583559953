import './User.local.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import CourseMenu from '../components/User/CourseMenu/CourseMenu';
class UserLayout extends Component {
    render() {
        return (
            <div>
                <div>
                    {this.props.showCourseMenu.state ? <CourseMenu /> : <div />}
                </div>

                <main
                    className={`main ${
                        !this.props.showCourseMenu.state
                            ? ''
                            : (this.props.showCourseMenu &&
                                  this.props.currentLessonLayout.state ==
                                      'PAGE') ||
                              this.props.courseMenuOpen.state
                            ? 'with-fixed-sidebar'
                            : ''
                    }`}>
                    {this.props.children}
                </main>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    const { currentLessonLayout, courseMenuOpen, showCourseMenu } = state;
    return {
        currentLessonLayout,
        courseMenuOpen,
        showCourseMenu,
    };
};

export default connect(mapStateToProps)(UserLayout);
