import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Select from 'react-select';
import {
    Row,
    Col,
    Accordion,
    Card,
    Button,
    FormGroup,
    Form,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPencilAlt,
    faTrash,
    faTimes,
    faEdit,
    faUnlink,
    faEllipsisV,
    faExternalLinkAlt,
    faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { faEye as farEye } from '@fortawesome/free-regular-svg-icons';
import { faClone as farClone } from '@fortawesome/free-regular-svg-icons';
import ContentItems from './ContentItems';
import apiCall from '../../helpers/apiCall';
import { ConfirmationModal } from '../ConfirmationModal';

class ItemCard extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);

        const typeTitle =
            props.type[0].toUpperCase() + props.type.substr(1).toLowerCase();

        let item = props.item;

        if (['course', 'package'].indexOf(this.props.type) >= 0) {
            item = null;
        }

        this.state = {
            item,
            conditionStatementOpts: [],
            submittedDocId: null,
            typeTitle,
            modalShow: false,
        };
    }

    componentDidMount = async () => {
        this._isMounted = true;

        const conditionStatementMap = {
            ANY_TIME: `Start ${this.props.type} at any time`,
            PREVIOUS_TIME_MET: `Cannot start until previous ${this.props.type} time met`,
            PREVIOUS_STARTED: `Cannot start until previous ${this.props.type} started`,
            PREVIOUS_COMPLETED: `Cannot start until previous ${this.props.type} completed`,
            PREVIOUS_PASSED: `Cannot start until previous ${this.props.type} passed`,
            PROMPT_STARTED: `Prompt if started before previous ${this.props.type} started`,
            PROMPT_COMPLETED: `Prompt if started before previous ${this.props.type} completed`,
            PROMPT_PASSED: `Prompt if started before previous ${this.props.type} passed`,
        };

        let conditions = [];
        if (this.props.type === 'lesson') {
            conditions = ['ANY_TIME', 'PREVIOUS_TIME_MET', 'PREVIOUS_STARTED'];
        } else if (this.props.type === 'chapter') {
            conditions = [
                'ANY_TIME',
                'PREVIOUS_TIME_MET',
                'PREVIOUS_STARTED',
                'PREVIOUS_COMPLETED',
                'PREVIOUS_PASSED',
            ];
        } else if (this.props.type === 'course') {
            conditions = [
                'ANY_TIME',
                'PREVIOUS_STARTED',
                'PREVIOUS_COMPLETED',
                'PREVIOUS_PASSED',
                'PROMPT_STARTED',
                'PROMPT_COMPLETED',
                'PROMPT_PASSED',
            ];
        }

        conditions = conditions.map((condition) => {
            return {
                value: condition,
                label: conditionStatementMap[condition],
            };
        });

        if (['course', 'package'].indexOf(this.props.type) >= 0) {
            let url;
            if (this.props.type === 'course') {
                url = `/courses/${this.props.item.courseId}`;
            } else {
                url = `/packages/${this.props.item}`;
            }
            const { success, response } = await apiCall('GET', url);

            if (success && this._isMounted) {
                this.setState({
                    item: {
                        ...this.props.item,
                        ...this.state.item,
                        ...response,
                    },
                });
            }
        } else {
            this.setState({
                item: this.props.item,
            });
        }

        if (this._isMounted) {
            this.setState({
                conditionStatementOpts: conditions,
            });
        }
    };

    componentWillUnmount = async () => {
        this._isMounted = false;
    };

    submit = async () => {
        let item = {
            ...this.state.item,
            _id: undefined,
            menuIndex: this.props.itemIdx,
        };

        if (this.props.source === 'courses') {
            item[
                (this.props.type === 'chapter' ? 'course' : 'chapter') + 'Id'
            ] = this.props.parentDocId;
        } else if (this.props.source === 'core') {
            item[
                (this.props.type === 'chapter'
                    ? 'coreLibrary'
                    : 'coreChapter') + 'Id'
            ] = this.props.parentDocId;
        }

        if (
            this.props.source === 'courses' &&
            this.props.type === 'chapter' &&
            this.state.item.coreChaptersLink
        ) {
            if (this.props.item.title !== this.state.item.title) {
                item.titleModified = true;
            }
            if (this.props.item.chapterTime !== this.state.item.chapterTime) {
                item.chapterTimeModified = true;
            }

            if (
                this.props.item.title === this.state.item.title &&
                !this.props.item.titleModified
            ) {
                item.title = '';
            }
            if (
                this.props.item.chapterTime === this.state.item.chapterTime &&
                !this.props.item.chapterTimeModified
            ) {
                item.chapterTime = 0;
            }
        }

        let method = this.props.item.isNew ? 'POST' : 'PUT';
        if (
            this.props.source === 'courses' &&
            this.props.type === 'lesson' &&
            !this.props.parentsCoreLink &&
            this.state.item.coreChapterId
        ) {
            method = 'POST';
            item.conditionStatement = 'ANY_TIME';
            item._id = undefined;
            item.chapterId = this.props.parentDocId;
        }

        let url = `/${this.props.source}/${this.props.type}s/`;
        if (method === 'PUT') {
            url += this.props.item._id;
        }

        if (this.props.type === 'lesson' && !this.state.item.isNew) {
            item = {
                ...item.draft,
                chapterId: item.chapterId,
                menuIndex: item.menuIndex,
                conditionStatement: item.conditionStatement,
                coreLessonsLink: item.coreLessonsLink || '',
            };
            delete item.updatedAt;
        }

        delete item.ref;
        delete item.draggableId;

        const { success, response } = await apiCall(method, url, item);

        if (this._isMounted && success && response) {
            this.setState({
                submittedDocId: this.props.item.isNew
                    ? response._id
                    : this.props.item._id,
            });
            this.props.updateRegister(this.state.item, 'submitted');
        } else {
            this.props.updateRegister(this.state.item, 'error');
        }
    };

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (
            !prevProps.doSubmit &&
            this.props.doSubmit &&
            !this.state.submittedDocId
        ) {
            this.submit();
        }

        if (prevProps.parentsCoreLink && !this.props.parentsCoreLink) {
            this.props.updateRegister(
                this.state.item,
                'add',
                this.props.parentDocId
            );
        }
    };

    duplicateItem = () => {
        this.props.cloneItem(this.props.item);
    };

    render() {
        let editItemLinkTarget;
        if (this.props.type === 'course') {
            editItemLinkTarget = `/admin/courses/${this.props.item.courseId}`;
        } else if (this.props.type === 'package') {
            editItemLinkTarget = `/admin/packages/edit/${this.props.item}`;
        } else if (this.props.type === 'lesson') {
            if (this.state.item.coreLessonsLink) {
                editItemLinkTarget = `/admin/core-libraries/lessons/edit/${this.state.item.coreLessonsLink}`;
            } else if (this.props.source === 'courses') {
                editItemLinkTarget = `/admin/courses/chapters/${this.state.item.chapterId}/ext/lessons/edit/${this.state.item._id}`;
            } else {
                editItemLinkTarget = `/admin/core-libraries/lessons/edit/${this.state.item._id}`;
            }
        }

        let editItemLinkIcon = null;
        let editTooltipText = 'Edit';
        if (this.props.source === 'core') {
            if (this.props.type === 'chapter') {
                editItemLinkIcon = faPencilAlt;
            } else {
                editItemLinkIcon = faEdit;
            }
        } else if (this.props.type === 'course') {
            editItemLinkIcon = faExternalLinkAlt;
        } else if (this.state.item && this.state.item[this.props.coreKey]) {
            if (this.props.type === 'chapter') {
                editItemLinkIcon = farEye;
                editTooltipText = 'View';
            } else {
                editItemLinkIcon = faExternalLinkAlt;
            }
        } else if (this.props.type === 'chapter') {
            editItemLinkIcon = faPencilAlt;
        } else {
            editItemLinkIcon = faEdit;
        }

        if (
            this.props.type === 'lesson' &&
            this.state.item.isNew &&
            !this.state.item.coreLessonsLink
        ) {
            editTooltipText = 'To edit this lesson save the form first';
        }

        return (
            <div>
                <ConfirmationModal
                    show={this.state.modalShow}
                    hideModal={() => {
                        this.setState({
                            modalShow: false,
                        });
                    }}
                    confirmAction={this.deleteQuiz}
                    titleText={'Are you sure?'}
                    bodyText={[`You are about to delete this Quiz.`]}
                />
                <Accordion
                    defaultActiveKey={
                        this.props.accordionOpen ? this.props.itemIdx + 1 : ''
                    }>
                    <Card>
                        <Accordion.Toggle
                            style={
                                this.props.type === 'chapter'
                                    ? {
                                          backgroundColor: '#dcf5ee',
                                          fontWeight: 700,
                                      }
                                    : {}
                            }
                            as={Card.Header}
                            eventKey={this.props.itemIdx + 1}>
                            <Row>
                                <Col
                                    xs={12}
                                    lg={5}
                                    style={{
                                        paddingTop: '6px',
                                    }}
                                    className='my-2 my-lg-0'>
                                    {!this.props.parentsCoreLink && (
                                        <span
                                            {...this.props.dragHandleProps}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}>
                                            <FontAwesomeIcon
                                                icon={faEllipsisV}
                                                style={{ marginRight: '20px' }}
                                            />
                                        </span>
                                    )}
                                    <span style={{ marginRight: '10px' }}>
                                        {this.state.item
                                            ? this.props.type === 'lesson' &&
                                              !this.state.item.isNew
                                                ? this.state.item.draft.title
                                                : this.state.item.title
                                            : ''}
                                    </span>
                                </Col>
                                <Col xs={12} lg={7} className='my-2 my-lg-0'>
                                    <Row>
                                        <Col
                                            xs={12}
                                            lg={6}
                                            xl={7}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}>
                                            {this.props.source !== 'core' &&
                                                !this.props.parentsCoreLink &&
                                                this.props.itemIdx > 0 &&
                                                this.props.type !==
                                                    'package' && (
                                                    <Select
                                                        name='conditionStatement'
                                                        defaultValue={
                                                            this.state.item
                                                                ? this.state
                                                                      .item
                                                                      .conditionStatement
                                                                : ''
                                                        }
                                                        value={
                                                            this.state.conditionStatementOpts.filter(
                                                                (opt) => {
                                                                    return (
                                                                        this
                                                                            .state
                                                                            .item &&
                                                                        opt.value ===
                                                                            this
                                                                                .state
                                                                                .item
                                                                                .conditionStatement
                                                                    );
                                                                }
                                                            )[0]
                                                        }
                                                        options={
                                                            this.state
                                                                .conditionStatementOpts
                                                        }
                                                        menuPortalTarget={
                                                            document.body
                                                        }
                                                        menuPosition={'fixed'}
                                                        onChange={(event) => {
                                                            const updatedItem =
                                                                {
                                                                    ...this
                                                                        .state
                                                                        .item,
                                                                    conditionStatement:
                                                                        event.value,
                                                                };
                                                            if (
                                                                this.props
                                                                    .updateItem
                                                            ) {
                                                                this.props.updateItem(
                                                                    updatedItem
                                                                );
                                                            } else {
                                                                this.setState({
                                                                    item: updatedItem,
                                                                });
                                                            }
                                                            if (
                                                                this.props
                                                                    .setIsDirty
                                                            ) {
                                                                this.props.setIsDirty(
                                                                    true
                                                                );
                                                            }
                                                        }}></Select>
                                                )}
                                        </Col>

                                        {this.props.type != 'lesson' ||
                                        !this.props.parentsCoreLink ? (
                                            <Col
                                                xs={12}
                                                lg={6}
                                                xl={5}
                                                className='my-2 my-lg-0'>
                                                <OverlayTrigger
                                                    key={`tooltip-delete-${this.props.itemIdx}`}
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip
                                                            id={`tooltip-delete-${this.props.itemIdx}`}>
                                                            {this.props.type ===
                                                                'course' ||
                                                            this.props.type ===
                                                                'package'
                                                                ? 'Remove'
                                                                : 'Delete'}
                                                        </Tooltip>
                                                    }>
                                                    <Button
                                                        variant='link'
                                                        type='button'
                                                        className='float-right ml-1'
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            this.props.deleteItem(
                                                                this.props.item
                                                            );
                                                        }}>
                                                        <FontAwesomeIcon
                                                            icon={
                                                                this.props
                                                                    .type ===
                                                                    'course' ||
                                                                this.props
                                                                    .type ===
                                                                    'package'
                                                                    ? faTimes
                                                                    : faTrash
                                                            }
                                                        />
                                                    </Button>
                                                </OverlayTrigger>
                                                <OverlayTrigger
                                                    key={`tooltip-edit-${this.props.itemIdx}`}
                                                    placement='top'
                                                    overlay={
                                                        <Tooltip
                                                            id={`tooltip-edit-${this.props.itemIdx}`}>
                                                            {editTooltipText}
                                                        </Tooltip>
                                                    }>
                                                    {this.props.type ===
                                                    'chapter' ? (
                                                        <Accordion.Toggle
                                                            as={Button}
                                                            variant='link'
                                                            className='float-right mx-1'
                                                            eventKey={
                                                                this.props
                                                                    .itemIdx + 1
                                                            }>
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    editItemLinkIcon
                                                                }
                                                            />
                                                        </Accordion.Toggle>
                                                    ) : this.props.type ===
                                                          'lesson' &&
                                                      this.state.item.isNew &&
                                                      !this.state.item
                                                          .coreLessonsLink ? (
                                                        <span className='d-inline-block float-right mx-1'>
                                                            <Button
                                                                style={{
                                                                    pointerEvents:
                                                                        'none',
                                                                }}
                                                                disabled
                                                                className='btn'>
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        editItemLinkIcon
                                                                    }></FontAwesomeIcon>
                                                            </Button>
                                                        </span>
                                                    ) : this.props.type !=
                                                      'package' ? (
                                                        <Link
                                                            to={
                                                                editItemLinkTarget
                                                            }
                                                            className='btn float-right mx-1'>
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    editItemLinkIcon
                                                                }
                                                            />
                                                        </Link>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </OverlayTrigger>
                                                {this.props.type ===
                                                    'chapter' ||
                                                (this.props.type === 'lesson' &&
                                                    !this.props
                                                        .parentsCoreLink) ? (
                                                    <OverlayTrigger
                                                        key={`tooltip-clone-${this.props.itemIdx}`}
                                                        placement='top'
                                                        overlay={
                                                            <Tooltip
                                                                id={`tooltip-clone-${this.props.itemIdx}`}>
                                                                {this.state.item
                                                                    .isNew
                                                                    ? `To duplicate this ${this.state.typeTitle} save the form first`
                                                                    : 'Duplicate'}
                                                            </Tooltip>
                                                        }>
                                                        <Button
                                                            variant='link'
                                                            type='button'
                                                            className='float-right ml-1'
                                                            disabled={
                                                                this.state.item
                                                                    .isNew
                                                            }
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.props.cloneItem(
                                                                    this.props
                                                                        .item
                                                                );
                                                            }}>
                                                            <FontAwesomeIcon
                                                                icon={farClone}
                                                            />
                                                        </Button>
                                                    </OverlayTrigger>
                                                ) : (
                                                    <></>
                                                )}

                                                {this.state.item &&
                                                this.state.item[
                                                    this.props.coreKey
                                                ] ? (
                                                    [
                                                        <OverlayTrigger
                                                            key={`tooltip-unlink-${this.props.itemIdx}`}
                                                            placement='top'
                                                            overlay={
                                                                <Tooltip
                                                                    id={`tooltip-unlink-${this.props.itemIdx}`}>
                                                                    Unlink from
                                                                    Core
                                                                </Tooltip>
                                                            }>
                                                            <Button
                                                                variant='link'
                                                                type='button'
                                                                className='float-right mx-1'
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    this.props.unlinkItem(
                                                                        this
                                                                            .props
                                                                            .item
                                                                    );
                                                                }}>
                                                                <FontAwesomeIcon
                                                                    icon={
                                                                        faUnlink
                                                                    }
                                                                />
                                                            </Button>
                                                        </OverlayTrigger>,
                                                        this.props.type ===
                                                        'chapter' ? (
                                                            <OverlayTrigger
                                                                key={`tooltip-edit-${this.props.itemIdx}`}
                                                                placement='top'
                                                                overlay={
                                                                    <Tooltip
                                                                        id={`tooltip-edit-${this.props.itemIdx}`}>
                                                                        Edit
                                                                    </Tooltip>
                                                                }>
                                                                <Link
                                                                    to={`/admin/core-libraries/edit/${this.state.item.coreLibraryId}?focus=${this.state.item.coreChaptersLink}`}
                                                                    className='btn float-right mx-1'>
                                                                    <FontAwesomeIcon
                                                                        icon={
                                                                            faExternalLinkAlt
                                                                        }
                                                                    />
                                                                </Link>
                                                            </OverlayTrigger>
                                                        ) : (
                                                            <div key='dummyKey'></div>
                                                        ),
                                                    ]
                                                ) : (
                                                    <></>
                                                )}
                                            </Col>
                                        ) : (
                                            <></>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        </Accordion.Toggle>

                        {this.props.type === 'chapter' ? (
                            <Accordion.Collapse
                                eventKey={this.props.itemIdx + 1}>
                                <Card.Body>
                                    <Row>
                                        <Col md={7}>
                                            <FormGroup>
                                                <Form.Label htmlFor='chapterTitle'>
                                                    Chapter Title
                                                </Form.Label>
                                                <Form.Control
                                                    type='text'
                                                    required
                                                    minLength='3'
                                                    maxLength='512'
                                                    id='chapterTitle'
                                                    name='title'
                                                    value={
                                                        this.state.item
                                                            ? this.state.item
                                                                  .title
                                                            : ''
                                                    }
                                                    onChange={(event) => {
                                                        this.setState({
                                                            item: {
                                                                ...this.state
                                                                    .item,
                                                                title: event
                                                                    .target
                                                                    .value,
                                                            },
                                                        });
                                                        if (
                                                            this.props
                                                                .setIsDirty
                                                        ) {
                                                            this.props.setIsDirty(
                                                                true
                                                            );
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={5}>
                                            <FormGroup>
                                                <Form.Label htmlFor='chapterTime'>
                                                    Chapter Time (minutes)
                                                </Form.Label>
                                                <Form.Control
                                                    type='number'
                                                    required
                                                    min='0'
                                                    id='chapterTime'
                                                    name='chapterTime'
                                                    value={
                                                        this.state.item
                                                            ? this.state.item
                                                                  .chapterTime !==
                                                              undefined
                                                                ? this.state
                                                                      .item
                                                                      .chapterTime
                                                                : ''
                                                            : ''
                                                    }
                                                    onChange={(event) => {
                                                        this.setState({
                                                            item: {
                                                                ...this.state
                                                                    .item,
                                                                chapterTime:
                                                                    event.target
                                                                        .value,
                                                            },
                                                        });
                                                        if (
                                                            this.props
                                                                .setIsDirty
                                                        ) {
                                                            this.props.setIsDirty(
                                                                true
                                                            );
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <ContentItems
                                                    type='lesson'
                                                    source={this.props.source}
                                                    parentDocId={
                                                        this.state
                                                            .submittedDocId ||
                                                        this.props.item._id
                                                    }
                                                    doSubmit={
                                                        this.state
                                                            .submittedDocId &&
                                                        this.props.doSubmit
                                                    }
                                                    parentsCoreLink={
                                                        this.state.item
                                                            ? this.state.item[
                                                                  this.props
                                                                      .coreKey
                                                              ]
                                                            : null
                                                    }
                                                    setIsDirty={
                                                        this.props.setIsDirty
                                                    }
                                                    updateRegister={
                                                        this.props
                                                            .updateRegister
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Accordion.Collapse>
                        ) : (
                            <></>
                        )}
                    </Card>
                </Accordion>
            </div>
        );
    }
}

export default connect(null, {
    setGlobalAlert: (payload) => ({
        type: 'SET_GLOBAL_ALERT',
        payload,
    }),
})(withRouter(ItemCard));
