import React from 'react';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import {
    BsFillSkipEndFill,
    BsFillSkipStartFill,
    BsFillPauseFill,
} from 'react-icons/bs';
import { Row, Col } from 'react-bootstrap';
import './index.scss';
import Player from './Player';

const AudioDetails = (props) => {
    const { duration, currentPosition } = props;

    function formatDuration(duration) {
        return moment
            .duration(duration, 'seconds')
            .format('mm:ss', { trim: false });
    }

    return (
        <Row className='audio'>
            <div className='audio__time'>
                <span style={{ display: 'block', float: 'left' }}>
                    {formatDuration(currentPosition)}
                </span>
            </div>
            <div>
                <div style={{ display: 'block', textAlign: 'center' }}>
                    <span className='audio__title' style={{ display: 'block' }}>
                        {props.title}
                    </span>
                    <span
                        className='audio__sub-title'
                        style={{ display: 'block' }}>
                        {props.subTitle}
                    </span>
                    <div>
                        {/* <span className='audio__icon'>
                            <BsFillSkipStartFill size={30} />
                        </span> */}
                        <span className='audio__icon'>
                            <Player
                                playAudio={props.playAudio}
                                pauseAudio={props.pauseAudio}
                                audio={props.audio}
                            />
                        </span>
                        {/* <span className='audio__icon'>
                            <BsFillSkipEndFill size={30} />
                        </span> */}
                    </div>
                </div>
            </div>
            <div className='audio__time'>
                <span style={{ display: 'block', float: 'right' }}>
                    {formatDuration(duration)}
                </span>
            </div>
        </Row>
    );
};

export default AudioDetails;
