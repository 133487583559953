import React from 'react';

import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import './index.scss';

const DocumentViewer = (props) => {
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    return (
        <div className='docview'>
            <div className='docviewcont'>
                <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.js'>
                    <Viewer
                        fileUrl={props.document}
                        plugins={[defaultLayoutPluginInstance]}
                    />
                </Worker>
            </div>
        </div>
    );
};

export default DocumentViewer;
