import React, { Component } from 'react';
import StudentForm from '../../../components/User/StudentForm';
import { connect } from 'react-redux';

class Enroll extends Component {
    componentDidMount() {
        this.props.setShowCourseMenu(false);
    }

    render() {
        return (
            <StudentForm
                typeSlug='enrollment'
                typeTitle='Enrollment'
                courseId={this.props.match.params.courseId}
            />
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setShowCourseMenu: (payload) => {
            dispatch({
                type: 'SET_SHOW_COURSE_MENU',
                payload,
            });
        },
    };
};

const mapStateToProps = (state) => {
    const { loggedIn } = state;
    return { loggedIn };
};

export default connect(mapStateToProps, mapDispatchToProps)(Enroll);
