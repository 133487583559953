import React, { history } from 'react';

import { Card, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import './PackageCard.scss';

export const PackageCard = ({
    image,
    title,
    description,
    packageId,
    getCourses,
}) => {
    const history = useHistory();

    return (
        <div
            className='xpc'
            onClick={() => getCourses()}
            style={{ cursor: 'pointer' }}>
            <div
                style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: `cover`,
                    backgroundRepat: `no-repeat`,
                }}>
                <div className='xpcb'>
                    <span>Bundle Pack</span>
                </div>
            </div>
            <div>
                <h2>{title}</h2>
                <p>{description}</p>
            </div>
            <div>
                <button className='bp' onClick={() => getCourses()}>
                    View Courses
                </button>
            </div>
        </div>
    );
};
