import React, { Component } from 'react';
import { Container, Button } from 'react-bootstrap';
import { connect } from 'react-redux';

class UserExam extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount = async () => {
        this.props.setShowCourseMenu(false);
    };

    render() {
        return (
            <Container id='exam-form'>
                <Button
                    onClick={() =>
                        this.props.history.push(
                            `/user/postexam/${this.props.match.params.courseId}`
                        )
                    }>
                    Go to post exam
                </Button>
            </Container>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setShowCourseMenu: (payload) => {
            dispatch({
                type: 'SET_SHOW_COURSE_MENU',
                payload,
            });
        },
    };
};

export default connect(null, mapDispatchToProps)(UserExam);
