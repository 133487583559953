import React, { Component } from 'react';
import apiCall from '../../../helpers/apiCall';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col, Container, Form, FormGroup } from 'react-bootstrap';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import './index.scss';
class StudentForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formInputs: [],
            userAnswers: [],
            firstChapterId: '',
            firstLessonId: '',
        };
    }

    componentDidMount = async () => {
        const courseId = this.props.courseId;

        const { success: foundCurrentCourse, response: currentCourse } =
            await apiCall('GET', `/users/courses/${courseId}`);
        if (foundCurrentCourse) {
            this.props.setCurrentCourse(currentCourse);
        }

        const { success, response } = await apiCall(
            'GET',
            `/users/${this.props.typeSlug}/${courseId}`
        );

        if (success && 'fields' in response) {
            this.setState({
                formInputs: response.fields,
                userAnswers: response.fields.map((field, i) => {
                    return {
                        key: field.key,
                        response: '',
                    };
                }),
            });
        }
        const { success: foundChapters, response: chapters } = await apiCall(
            'GET',
            `/users/courses/${courseId}/chapters`
        );
        if (foundChapters) {
            const url = `/users/chapters/${chapters.chapters[0]._id}/lessons`;
            const { success: foundLessons, response: lessons } = await apiCall(
                'GET',
                url
            );
            if (foundLessons) {
                this.setState({
                    firstChapterId: chapters.chapters[0]._id,
                    firstLessonId: lessons.docs[0]._id,
                });
            }
        }
    };

    onIframeRef = (node) => {
        if (!node) {
            return;
        }

        node.contentWindow.addEventListener('load', () => {
            node.contentWindow.document.querySelector('header').style.display =
                'none';
            node.contentWindow.document.querySelector('body').style.overflow =
                'hidden';
        });
    };

    setInputRef = (i, element) => {
        if (element && this.inputRefs.indexOf(element) < 0) {
            this.inputRefs.push(element);
        }
    };

    handleChange = (i, event) => {
        let userAnswers = [...this.state.userAnswers];
        userAnswers[i].response = event.target.value;
        this.setState({
            userAnswers,
        });
    };

    handleMulticheckboxChange = (i, event, response) => {
        let userAnswers = [...this.state.userAnswers];
        if (!userAnswers[i].response) {
            userAnswers[i].response = [];
        }
        if (event.target.checked) {
            userAnswers[i].response.push(response);
        } else {
            userAnswers[i].response = userAnswers[i].response.filter((res) => {
                return res !== response;
            });
        }
        this.setState({
            userAnswers,
        });
    };

    handleRadioChange = (i, opt) => {
        let userAnswers = [...this.state.userAnswers];
        userAnswers[i].response = opt;
        this.setState({
            userAnswers,
        });
    };

    handleFileChange = (i, event) => {
        if (event.target.value) {
            let userAnswers = [...this.state.userAnswers];
            userAnswers[i].response = event.target.files[0];
            this.setState({
                userAnswers,
            });
        }
    };

    uploadFiles = async () => {
        const uploadPromises = this.state.userAnswers.map((answer, i) => {
            if (
                this.state.formInputs[i].inputType === 'file' &&
                answer.response
            ) {
                let filePostData = new FormData();
                filePostData.append('file', answer.response);
                return apiCall('POST', '/file', filePostData).then(
                    ({ success, response }) => {
                        if (success) {
                            let userAnswers = [...this.state.userAnswers];
                            userAnswers[i].response = response.url;
                            this.setState({
                                userAnswers,
                            });
                        }
                    }
                );
            }
        });

        await Promise.all(uploadPromises);
    };

    submitForm = async (e) => {
        e.preventDefault();
        await this.uploadFiles();
        const { typeSlug, courseId } = this.props;

        const { success } = await apiCall(
            'POST',
            `/users/${typeSlug}/${courseId}`,
            this.state.userAnswers
        );
        if (success) {
            if (typeSlug == 'enrollment') {
                this.props.history.push(
                    `/user/course/${courseId}/chapters/${this.state.firstChapterId}/lesson/${this.state.firstLessonId}`
                );
                this.props.setNewCoursePopUp(true);
            } else if (typeSlug == 'pre-exam') {
                this.props.history.push(`/user/exam/${courseId}`);
            } else if (typeSlug == 'post-exam') {
                this.props.history.push(`/user/certificate/${courseId}`);
            }
        }
    };

    render() {
        return (
            <Container
                className='student-form-container'
                fluid
                style={{ padding: 0 }}>
                <div className='title-banner'>
                    <div className='row'>
                        <div>
                            {this.props.currentCourse.state
                                ? this.props.currentCourse.state.title
                                : ''}
                            <Fa
                                icon={faChevronRight}
                                style={{
                                    color: '#dadada',
                                    fontSize: '14px',
                                    width: '22px',
                                }}
                            />{' '}
                            {this.props.typeTitle}
                        </div>
                    </div>
                </div>
                <div className='form-wrapper pt-5'>
                    <Form
                        onSubmit={this.submitForm}
                        id={`${this.props.typeSlug}-form`}>
                        {this.state.formInputs &&
                            this.state.formInputs.map((input, i) => {
                                return (
                                    <Row key={i}>
                                        <Col
                                            className='py-1'
                                            md={
                                                [
                                                    'document',
                                                    'image',
                                                    'textarea',
                                                    'textBlock',
                                                ].indexOf(input.inputType) >= 0
                                                    ? 12
                                                    : 6
                                            }>
                                            <FormGroup>
                                                <Form.Label
                                                    htmlFor={input.label}
                                                    className={
                                                        input.required
                                                            ? 'required-asterisk'
                                                            : ''
                                                    }>
                                                    {input.required ? '*' : ''}
                                                </Form.Label>
                                                <Form.Label
                                                    htmlFor={input.label}>
                                                    {input.label}
                                                </Form.Label>
                                                {input.inputType === 'text' && (
                                                    <Form.Control
                                                        type='text'
                                                        required={
                                                            input.required
                                                        }
                                                        id={input.label}
                                                        name={input.key}
                                                        value={
                                                            this.state
                                                                .userAnswers[i]
                                                                .response
                                                        }
                                                        onChange={(event) => {
                                                            this.handleChange(
                                                                i,
                                                                event
                                                            );
                                                        }}
                                                    />
                                                )}
                                                {input.inputType ===
                                                    'number' && (
                                                    <Form.Control
                                                        type='number'
                                                        required={
                                                            input.required
                                                        }
                                                        id={input.label}
                                                        name={input.key}
                                                        value={
                                                            this.state
                                                                .userAnswers[i]
                                                                .response
                                                        }
                                                        onChange={(event) => {
                                                            this.handleChange(
                                                                i,
                                                                event
                                                            );
                                                        }}
                                                    />
                                                )}
                                                {input.inputType === 'date' && (
                                                    <Form.Control
                                                        type='date'
                                                        required={
                                                            input.required
                                                        }
                                                        id={input.label}
                                                        name={input.key}
                                                        value={
                                                            this.state
                                                                .userAnswers[i]
                                                                .response
                                                        }
                                                        onChange={(event) => {
                                                            this.handleChange(
                                                                i,
                                                                event
                                                            );
                                                        }}
                                                    />
                                                )}
                                                {input.inputType === 'file' && (
                                                    <Form.File
                                                        required={
                                                            input.required
                                                        }
                                                        accept={
                                                            input.extra
                                                                .allowedFileTypes
                                                        }
                                                        id={input.label}
                                                        name={input.key}
                                                        onChange={(event) => {
                                                            this.handleFileChange(
                                                                i,
                                                                event
                                                            );
                                                        }}
                                                    />
                                                )}
                                                {input.inputType ===
                                                    'typeDNA' && (
                                                    <Form.Control
                                                        type='text'
                                                        required={
                                                            input.required
                                                        }
                                                        id={input.label}
                                                        name={input.key}
                                                        value={
                                                            this.state
                                                                .userAnswers[i]
                                                                .response
                                                        }
                                                        onChange={(event) => {
                                                            this.handleChange(
                                                                i,
                                                                event
                                                            );
                                                        }}
                                                    />
                                                )}
                                                {input.inputType ===
                                                    'textarea' && (
                                                    <Form.Control
                                                        as='textarea'
                                                        type='text'
                                                        required={
                                                            input.required
                                                        }
                                                        id={input.label}
                                                        name={input.key}
                                                        rows='4'
                                                        value={
                                                            this.state
                                                                .userAnswers[i]
                                                                .response
                                                        }
                                                        onChange={(event) => {
                                                            this.handleChange(
                                                                i,
                                                                event
                                                            );
                                                        }}
                                                    />
                                                )}
                                                {input.inputType ===
                                                    'options' && (
                                                    <Form.Control
                                                        as='select'
                                                        required={
                                                            input.required
                                                        }
                                                        value={
                                                            this.state
                                                                .userAnswers[i]
                                                                .response
                                                        }
                                                        onChange={(event) => {
                                                            this.handleChange(
                                                                i,
                                                                event
                                                            );
                                                        }}>
                                                        <option
                                                            disabled
                                                            value=''></option>
                                                        {input.extra.options.map(
                                                            (opt, i) => {
                                                                return (
                                                                    <option
                                                                        key={i}
                                                                        value={
                                                                            opt
                                                                        }>
                                                                        {opt}
                                                                    </option>
                                                                );
                                                            }
                                                        )}
                                                    </Form.Control>
                                                )}
                                                {input.inputType ===
                                                    'checkbox' && (
                                                    <div>
                                                        {input.extra.options.map(
                                                            (opt, j) => {
                                                                return (
                                                                    <Form.Check
                                                                        key={j}
                                                                        type='checkbox'
                                                                        label={
                                                                            opt
                                                                        }
                                                                        required={
                                                                            input.required
                                                                        }
                                                                        checked={
                                                                            this.state.userAnswers[
                                                                                i
                                                                            ].response.indexOf(
                                                                                opt
                                                                            ) >=
                                                                            0
                                                                        }
                                                                        onChange={(
                                                                            event
                                                                        ) => {
                                                                            this.handleMulticheckboxChange(
                                                                                i,
                                                                                event,
                                                                                opt
                                                                            );
                                                                        }}
                                                                    />
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                )}
                                                {input.inputType ===
                                                    'radio' && (
                                                    <div>
                                                        {input.extra.options.map(
                                                            (opt, j) => {
                                                                return (
                                                                    <Form.Check
                                                                        key={j}
                                                                        type='radio'
                                                                        name={
                                                                            input.label
                                                                        }
                                                                        label={
                                                                            opt
                                                                        }
                                                                        required={
                                                                            input.required
                                                                        }
                                                                        checked={
                                                                            this
                                                                                .state
                                                                                .userAnswers[
                                                                                i
                                                                            ]
                                                                                .response ===
                                                                            opt
                                                                        }
                                                                        onChange={(
                                                                            event
                                                                        ) => {
                                                                            this.handleRadioChange(
                                                                                i,
                                                                                opt
                                                                            );
                                                                        }}
                                                                    />
                                                                );
                                                            }
                                                        )}
                                                    </div>
                                                )}
                                                {input.inputType ===
                                                    'textBlock' && (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: input.extra
                                                                .copy,
                                                        }}></div>
                                                )}
                                                {input.inputType ===
                                                    'document' && (
                                                    <div>
                                                        <iframe
                                                            ref={
                                                                this.onIframeRef
                                                            }
                                                            style={{
                                                                width: '100%',
                                                                height: '800px',
                                                            }}
                                                            src={`${
                                                                process.env
                                                                    .REACT_APP_URL
                                                            }/user/document/${input.extra.sourceUrl.substr(
                                                                input.extra.sourceUrl.lastIndexOf(
                                                                    '/'
                                                                ) + 1
                                                            )}`}></iframe>
                                                    </div>
                                                )}
                                                {input.inputType ===
                                                    'image' && (
                                                    <div>
                                                        <img
                                                            src={
                                                                input.extra
                                                                    .sourceUrl
                                                            }
                                                            alt='image'
                                                            width='100%'></img>
                                                    </div>
                                                )}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                );
                            })}
                        <Row className='pt-3 pb-5'>
                            <Col>
                                <button
                                    className='bp d-block'
                                    style={{ margin: '0 auto' }}
                                    type='submit'>
                                    Submit {this.props.typeTitle} Form
                                </button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Container>
        );
    }
}
const mapStateToProps = (state) => {
    // state argument is the entire redux store
    const { newCoursePopUp, currentCourse } = state; // inside curly braces we have the name of the state we want
    return {
        newCoursePopUp,
        currentCourse,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        setNewCoursePopUp: (payload) => {
            dispatch({
                type: 'SET_NEW_COURSE_POP_UP',
                payload,
            });
        },
        setShowCourseMenu: (payload) => {
            dispatch({
                type: 'SET_SHOW_COURSE_MENU',
                payload,
            });
        },
        setCurrentCourse: (payload) => {
            dispatch({
                type: 'SET_CURRENT_COURSE',
                payload,
            });
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(StudentForm));
