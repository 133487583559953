import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ApiTable } from '../../../components/ApiTable';
import apiCall from '../../../helpers/apiCall';
import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faClone as farClone } from '@fortawesome/free-regular-svg-icons';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

class CoreLibraryTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalShow: false,
            modalAction: null,
            modalType: '',
        };
    }

    componentDidMount = () => {
        this.props.pushBreadcrumbLink({
            text: 'Core Libraries',
            path: '/admin/core-libraries',
        });
    };

    componentWillUnmount() {
        this.props.removeBreadcrumbLink({
            text: 'Core Libraries',
            path: '/admin/core-libraries',
        });
    }

    duplicateCoreLibrary = async (docId, reloadTable) => {
        const { success } = await apiCall('POST', `/core/clone/${docId}/`);

        if (success) {
            this.setState({
                modalShow: false,
            });
            await reloadTable();
            this.props.setGlobalAlert({
                type: 'success',
                message: 'Core Library has been duplicated',
            });
        } else {
            this.props.setGlobalAlert({
                type: 'error',
                message:
                    'Core Library has not been duplicated. Please try again.',
            });
        }
    };

    deleteCoreLibrary = async (docId, reloadTable) => {
        const { success } = await apiCall('DELETE', `/core/${docId}`);

        if (success) {
            this.setState({
                modalShow: false,
            });
            await reloadTable();
            this.props.setGlobalAlert({
                type: 'success',
                message: 'Core Library has been deleted',
            });
        } else {
            this.props.setGlobalAlert({
                type: 'error',
                message: 'Core Library has not been deleted. Please try again.',
            });
        }
    };

    render() {
        return (
            <div>
                <ConfirmationModal
                    show={this.state.modalShow}
                    hideModal={() => {
                        this.setState({
                            modalShow: false,
                        });
                    }}
                    confirmAction={this.state.modalAction}
                    titleText={'Are you sure?'}
                    bodyText={[
                        'You are about to ',
                        <strong key='modal-type'>
                            {this.state.modalType}
                        </strong>,
                        ' this Core Library.',
                    ]}
                />
                <a
                    className='btn btn--primary btn--small'
                    href={'/admin/core-libraries/create'}>
                    New Core Library
                </a>
                <ApiTable
                    basePath='/admin/core-libraries'
                    apiCall={{
                        method: 'GET',
                        path: '/core',
                    }}
                    columns={[
                        {
                            text: '',
                            field: (row) => (
                                <OverlayTrigger
                                    placement='top'
                                    overlay={
                                        <Tooltip
                                            id={`tooltip-${row._id}-edit-core-library)}`}>
                                            Edit core library
                                        </Tooltip>
                                    }>
                                    <Link
                                        className='btn btn--small'
                                        to={
                                            '/admin/core-libraries/edit/' +
                                            row._id
                                        }>
                                        <Fa icon={faEdit} />
                                    </Link>
                                </OverlayTrigger>
                            ),
                            maxWidth: '3.11rem',
                        },
                        {
                            text: 'Title',
                            field: 'title',
                            minWidth: '55%',
                            sortKey: 'title',
                        },
                        {
                            text: 'Created at',
                            field: 'createdAt',
                            sortKey: 'createdAt',
                        },
                    ]}
                    rowButtons={[
                        {
                            type: 'button',
                            text: 'Duplicate core library',
                            icon: farClone,
                            clickCallback: (e, doc, reload) => {
                                this.setState({
                                    modalShow: true,
                                    modalAction: () => {
                                        this.duplicateCoreLibrary(
                                            doc._id,
                                            reload
                                        );
                                    },
                                    modalType: 'duplicate',
                                });
                            },
                        },
                        {
                            type: 'button',
                            text: 'Delete core library',
                            icon: faTrash,
                            clickCallback: (e, doc, reload) => {
                                this.setState({
                                    modalShow: true,
                                    modalAction: () => {
                                        this.deleteCoreLibrary(doc._id, reload);
                                    },
                                    modalType: 'delete',
                                });
                            },
                        },
                    ]}
                />
            </div>
        );
    }
}

export default connect(null, {
    pushBreadcrumbLink: (payload) => ({
        type: 'PUSH_BREADCRUMB_LINK',
        payload,
    }),
    removeBreadcrumbLink: (payload) => ({
        type: 'REMOVE_BREADCRUMB_LINK',
        payload,
    }),
    setGlobalAlert: (payload) => ({
        type: 'SET_GLOBAL_ALERT',
        payload,
    }),
})(CoreLibraryTable);
