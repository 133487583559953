import React, { Component } from 'react';
import {
    Accordion,
    Card,
    Form,
    Button,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';
import apiCall from '../../../helpers/apiCall';
import { Table } from '../../../components/Table';
import { connect } from 'react-redux';
import CourseProgressAssessment from './CourseProgressAssessment';
import './CourseProgress.scss';

class CourseProgress extends Component {
    state = {
        chapters: [],
        checkedChapters: new Set(),
        checkedLessons: new Set(),
        bulkAction: '',
        updatedAt: '',
        lessonChapters: {},
    };

    allLessons = new Set();

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        const { courseId } = this.props,
            { success, response } = await apiCall(
                'GET',
                `/users/courses/${courseId}/chapters/admin`
            );

        if (success) {
            this.allLessons = new Set();

            response.chapters.map(
                (chapter) => (this[`ref-${chapter._id}`] = React.createRef())
            );

            this.setState({
                chapters: response.chapters,
            });
        }
    };

    handleSelectAll = (e) => {
        if (e.target.checked) {
            this.setState({
                checkedChapters: new Set(
                    this.state.chapters.map((chapter) => chapter._id)
                ),
                checkedLessons: new Set(this.allLessons),
            });
        } else {
            this.setState({
                checkedChapters: new Set(),
                checkedLessons: new Set(),
            });
        }
    };

    handleChapterCheck = (e) => {
        const { checkedChapters, checkedLessons } = this.state,
            chapter = e.target;
        let lessons = chapter.parentElement.parentElement.querySelectorAll(
            'input[type="checkbox"][name^="lesson"]'
        );

        if (lessons.length) {
            lessons = [...lessons];
        }

        if (chapter.checked) {
            checkedChapters.add(chapter.dataset.id);
            lessons.map((lesson) => checkedLessons.add(lesson.dataset.id));
        } else {
            checkedChapters.delete(chapter.dataset.id, 1);
            lessons.map((lesson) => checkedLessons.delete(lesson.dataset.id));
        }

        this.setState({
            checkedChapters,
            checkedLessons,
        });
    };

    handleLessonCheck = (e) => {
        const { checkedLessons } = this.state,
            lesson = e.target;

        if (lesson.checked) {
            checkedLessons.add(lesson.dataset.id);
        } else {
            checkedLessons.delete(lesson.dataset.id);
        }

        this.setState({
            checkedLessons,
        });
    };

    handleActionSelect = (e) => {
        this.setState({
            bulkAction: e.target.value,
        });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        const { bulkAction, checkedChapters, checkedLessons } = this.state;

        if (bulkAction) {
            const { success, message } = await apiCall(
                'POST',
                `/users/courses/many/${bulkAction}`,
                {
                    chapters: Array.from(checkedChapters),
                    lessons: Array.from(checkedLessons),
                }
            );

            this.props.setGlobalAlert({
                type: success ? 'success' : 'error',
                message,
            });

            this.setState(
                {
                    checkedChapters: new Set(),
                    checkedLessons: new Set(),
                },
                this.loadData
            );
        }
    };

    render() {
        const { chapters, checkedChapters, checkedLessons, bulkAction } =
            this.state;

        return (
            <form onSubmit={this.handleSubmit}>
                <div className='bulk-actions d-flex align-items-center'>
                    <label
                        htmlFor='selectAll'
                        className=' d-flex align-items-center'>
                        <input
                            type='checkbox'
                            name='selectAll'
                            id='selectAll'
                            onChange={this.handleSelectAll}
                            checked={
                                checkedChapters.size === chapters.length &&
                                checkedLessons.size === this.allLessons.size
                            }
                        />
                        &nbsp; Select all
                    </label>
                    <Form.Control
                        as='select'
                        onChange={this.handleActionSelect}>
                        <option value=''>Bulk action</option>
                        <option value='unlock'>Unlock selected</option>
                        <option value='reset'>Reset selected</option>
                    </Form.Control>
                    <Button
                        className='bd'
                        type='submit'
                        disabled={
                            (checkedChapters.size === 0 &&
                                checkedLessons.size === 0) ||
                            !bulkAction
                        }>
                        Update
                    </Button>
                </div>
                <Accordion className='table-tree'>
                    {chapters.map((chapter) => (
                        <Card key={chapter._id}>
                            <Card.Header>
                                <input
                                    type='checkbox'
                                    name={`chapter-${chapter._id}`}
                                    data-id={chapter._id}
                                    onChange={this.handleChapterCheck}
                                    checked={checkedChapters.has(chapter._id)}
                                />
                                <Accordion.Toggle
                                    as='span'
                                    eventKey={chapter._id}>
                                    {chapter.title}
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey={chapter._id}>
                                <Card.Body>
                                    <Table
                                        rows={chapter?.lessons}
                                        columns={[
                                            {
                                                text: '',
                                                field: (row) => {
                                                    this.allLessons.add(
                                                        row._id
                                                    );

                                                    return (
                                                        <input
                                                            type='checkbox'
                                                            name={`lesson-${row._id}`}
                                                            data-id={row._id}
                                                            onChange={
                                                                this
                                                                    .handleLessonCheck
                                                            }
                                                            checked={this.state.checkedLessons.has(
                                                                row._id
                                                            )}
                                                        />
                                                    );
                                                },
                                                maxWidth: '1.5rem',
                                            },
                                            {
                                                text: 'Lesson Name (Progress Time)',
                                                field: (row) => (
                                                    <>
                                                        <OverlayTrigger
                                                            placement='right'
                                                            overlay={
                                                                <Tooltip
                                                                    id={`ltt-${row._id}`}>
                                                                    {row.title}
                                                                </Tooltip>
                                                            }>
                                                            <span>
                                                                {row.title}
                                                            </span>
                                                        </OverlayTrigger>
                                                        <div>
                                                            &nbsp;(
                                                            {new Date(
                                                                row.maxTime *
                                                                    60000
                                                            )
                                                                .toISOString()
                                                                .substr(11, 8)}
                                                            )
                                                        </div>
                                                    </>
                                                ),
                                                minWidth: '30rem',
                                                className: 'limitTitle',
                                            },
                                            {
                                                text: 'Status',
                                                field: (row) => {
                                                    if (row.completed) {
                                                        return 'Complete';
                                                    }

                                                    if (row.unlocked) {
                                                        return 'Unlocked';
                                                    }

                                                    return 'Locked';
                                                },
                                            },
                                            {
                                                text: 'Time in lesson',
                                                field: (row) =>
                                                    new Date(
                                                        row.currentTime * 1000
                                                    )
                                                        .toISOString()
                                                        .substr(11, 8),
                                            },
                                            {
                                                text: 'Completed at',
                                                field: (row) =>
                                                    row.completed
                                                        ? new Date(
                                                              row.completed
                                                          ).toLocaleString()
                                                        : '-',
                                            },
                                            {
                                                text: 'Assessment',
                                                field: (row) =>
                                                    row.isQuiz ? (
                                                        <CourseProgressAssessment
                                                            chapterId={
                                                                chapter._id
                                                            }
                                                        />
                                                    ) : (
                                                        '-'
                                                    ),
                                            },
                                        ]}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                </Accordion>
            </form>
        );
    }
}

export default connect(null, {
    setGlobalAlert: (payload) => ({
        type: 'SET_GLOBAL_ALERT',
        payload,
    }),
})(CourseProgress);
