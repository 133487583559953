import React, { Component } from 'react';
import TypingDNA from './typingRecorder';
import axios from 'axios';
import { connect } from 'react-redux';

import { Container, Row, Col, FormControl, Button } from 'react-bootstrap';

class EnrollTypingPattern extends Component {
    constructor(props) {
        super(props);

        this.state = {
            typingPattern: '',
            message: '',
            numberOfEnrollments: 0,
        };
    }

    componentDidMount() {
        this.props.setShowCourseMenu(false);
    }

    render() {
        const tdna = new TypingDNA();

        const currentQuote = 'hello my name is haroon';

        TypingDNA.addTarget('typing-dna-enroll');

        const compareTexts = (t1, t2) => {
            var dt1 = t1.split(' ');
            var dt2 = t2.split(' ');
            var total2 = 0;
            var total1 = 0;
            for (var i in dt2) {
                total2 += dt1.indexOf(dt2[i]) > -1 ? 1 : 0;
            }
            for (var i in dt1) {
                total1 += dt2.indexOf(dt1[i]) > -1 ? 1 : 0;
            }
            var total = total1 < total2 ? total1 : total2;
            var length = dt1.length > dt2.length ? dt1.length : dt2.length;
            /** returns a number between 0 (completely different texts) and 1 (identical texts) */
            return total / length;
        };

        const EnrollUser = async (typingPatern) => {
            if (typingPatern != (null || undefined)) {
                const response = await axios.post(
                    `http://localhost:5000/api/enroll`,
                    { tp: typingPatern },
                    {
                        withCredentials: true,
                    }
                );
                console.log(response);
                if (
                    this.state.numberOfEnrollments <= 3 &&
                    response.data.data.page == 'enroll'
                ) {
                    this.setState({
                        numberOfEnrollments: this.state.numberOfEnrollments + 1,
                    });
                }
                console.log(typingPatern);

                TypingDNA.reset(); // reset the typing pattern so we can send a new one
            }
        };

        // authenticate typing pattern

        const CheckUser = async () => {
            const user = {
                userId: this.props.loggedIn.user._id,
            };
            console.log(user);
            !this.props.loggedIn;
            const token = localStorage.getItem('authToken');

            const response = await axios.post(
                `http://localhost:5000/api/index`,
                user,
                {
                    withCredentials: true,
                }
            );
            alert(response.data.data.page);
            console.log(response.data);
        };

        const onEnterPress = (e) => {
            // when they press enter, start verification
            if (e.charCode === 13 && e.shiftKey == false) {
                e.preventDefault();
                verifyText();
            }
        };

        const verifyText = () => {
            if (
                compareTexts(
                    document.getElementById('typing-dna-enroll').value,
                    currentQuote
                ) > 0.7
            ) {
                /** at least 70% of the words should be typed correctly */
                let tp = tdna.getTypingPattern({ type: 1 });
                this.setState({ typingPattern: tp });
                if (
                    tp != (null || undefined) &&
                    tdna.isMobile() &&
                    TypingDNA.checkMobileValidity(tp) < 0.7
                ) {
                    // alert(
                    //     "Looks like you're on mobile. Try authenticating on another device"
                    // );
                    return false;
                }
                EnrollUser(tp);
                return true;
            } else {
                alert('Too many typos, please re-type');
                return false;
            }
        };

        return (
            <Container>
                <Row>
                    <Col xs={6}>
                        <Button variant='primary' onClick={() => CheckUser()}>
                            Check User
                        </Button>
                    </Col>
                    <Col xs={6}>
                        <h4>Enrolling {this.state.numberOfEnrollments}/3 </h4>
                        <h4>{currentQuote}</h4>
                        <FormControl
                            id='typing-dna-enroll'
                            onKeyPress={onEnterPress}
                        />
                        <Button onClick={verifyText}>Enroll</Button>
                    </Col>
                </Row>
            </Container>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setShowCourseMenu: (payload) => {
            dispatch({
                type: 'SET_SHOW_COURSE_MENU',
                payload,
            });
        },
    };
};

const mapStateToProps = (state) => {
    const { loggedIn } = state;
    return { loggedIn };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EnrollTypingPattern);
