import React, { Component } from 'react';
import './EndOfChapterQuiz.scss';
import apiCall from '../../../../helpers/apiCall';
import { Button, Modal } from 'react-bootstrap';
import { QuestionsModal } from '../../QuestionsModal';
import humanizeDuration from 'humanize-duration';
import { withRouter } from 'react-router';
import { LastAttempts } from './LastAttempts';

class EndOfChapterQuiz extends Component {
    state = {
        lastAttempts: [],
        showLastAttempts: false,
        current: {
            pausedAt: [],
            startedAt: [],
        },
        showQuiz: false,
        message: null,
        modalError: '',
        attemptToShow: null,
        showAbandon: false,
    };

    async componentDidMount() {
        const { success, response } = await apiCall(
            'GET',
            `/users/chapters/${this.props.match.params.chapterId}/quiz`
        );

        if (success) {
            const passed =
                response.lastAttempts.findIndex(
                    (item) => item.status === 'SUCCESS'
                ) !== -1;

            this.setState({
                ...response,
                message:
                    response.lastAttempts[0] && passed
                        ? {
                              type: 'success',
                              text: 'You can continue with the course or practice a little more.',
                          }
                        : null,
                showQuiz:
                    (!!response.current?.startedAt?.length &&
                        !response.current?.pausedAt?.length) ||
                    (!!response.current?.startedAt?.length &&
                        response.current?.pausedAt?.length <
                            response.current?.startedAt?.length),
            });
        }

        window.socket.on('quiz started', (startedAt) => {
            this.setState({
                current: {
                    ...this.state.current,
                    startedAt,
                },
                showQuiz: true,
                modalError: '',
            });

            this.setLeaveWarning();
        });

        window.socket.on('quiz paused', (pausedAt) => {
            this.setState({
                current: {
                    ...this.state.current,
                    pausedAt,
                },
                showQuiz: false,
            });

            this.unsetLeaveWarning();
        });

        window.socket.on('quiz resumed', (startedAt) => {
            this.setState({
                current: {
                    ...this.state.current,
                    startedAt,
                },
                modalError: '',
                showQuiz: true,
            });

            this.setLeaveWarning();
        });

        window.socket.on('quiz canceled', (response) => {
            this.setState(
                {
                    ...response,
                    showQuiz: true,
                },
                this.handleAttempt
            );

            console.log(response);
        });

        window.socket.on('quiz submited', (response) => {
            if (response.success) {
                const passed =
                    response.data.lastAttempts[0].status === 'SUCCESS';

                this.setState({
                    ...response.data,
                    message: passed
                        ? {
                              type: 'success',
                              text: 'You can continue with the course or practice a little more.',
                          }
                        : null,
                    showQuiz: false,
                });

                this.unsetLeaveWarning();
            } else {
                this.setState({
                    modalError: response.message,
                });
            }
        });
    }

    leaveWarningCallback(event) {
        const e = event || window.event;

        e.returnValue = '';

        e.preventDefault();
    }

    showAbandonOptions = () => {
        this.setState({
            showAbandon: !this.state.showAbandon,
        });
    };

    setLeaveWarning = () => {
        window.addEventListener('beforeunload', this.leaveWarningCallback);
    };

    unsetLeaveWarning = () => {
        window.removeEventListener('beforeunload', this.leaveWarningCallback);
    };

    checkAnswer = async (questionIndex, option) => {
        const { success, response } = await apiCall(
            'GET',
            `/users/chapters/quiz/${this.state.current._id}/answer/${questionIndex}/${option}`
        );

        if (success) {
            let answers = this.state.current.answers;

            answers[questionIndex] = option;

            this.setState({
                marks: this.state.marks + response.marks,
                current: {
                    ...this.state.current,
                    answers,
                    spentTime: response.spentTime,
                },
            });
        }
    };

    handleCancel = async () => {
        window.socket.emit('cancel quiz', this.state.current?._id);
    };

    handlePause = async () => {
        window.socket.emit('pause quiz', this.state.current?._id);
    };

    handleResume = async () => {
        window.socket.emit('resume quiz', this.state.current?._id);
    };

    handleAttempt = async () => {
        window.socket.emit('start quiz', this.state.current?._id);
    };

    handleSubmit = async () => {
        window.socket.emit('submit quiz', this.state.current?._id);
    };

    hideLastAttempt = () => {
        this.setState({
            attemptToShow: null,
        });
    };

    setAttemptToShow = (attemptToShow) => {
        this.setState({
            attemptToShow,
        });
    };

    render() {
        let { current, lastAttempts } = this.state;

        lastAttempts = !!current.pausedAt[0]
            ? [current, ...lastAttempts]
            : lastAttempts;

        return (
            <div className='quiz-info'>
                <div>
                    <h4>
                        <b>{current?.title ?? lastAttempts[0]?.title}</b>
                    </h4>
                    {this.state.message && (
                        <div
                            className={`quiz__alert quiz__alert--${this.state.message.type}`}>
                            <h6>Passed</h6>
                            <span>{this.state.message.text}</span>
                        </div>
                    )}
                    {current?.questions?.length ??
                        lastAttempts[0]?.questions?.length}{' '}
                    questions
                    <br />
                    Passing percentage:{' '}
                    {current?.passPct ?? lastAttempts[0]?.passPct}
                    %
                    <br />
                    Time limit:{' '}
                    {humanizeDuration(
                        (current?.timeLimit ?? lastAttempts[0]?.timeLimit) *
                            60000
                    )}
                    <br />
                    <br />
                    <Button
                        className='bp'
                        onClick={
                            !current.status &&
                            current.pausedAt.length ===
                                current.startedAt.length &&
                            current.startedAt.length !== 0
                                ? this.showAbandonOptions
                                : this.handleAttempt
                        }>
                        Start new quiz
                    </Button>
                </div>
                {!!lastAttempts[0] && (
                    <LastAttempts
                        items={lastAttempts}
                        onResume={this.handleResume}
                        onItemClick={this.setAttemptToShow}
                    />
                )}
                {current &&
                    current?.startedAt?.length > current?.pausedAt?.length && (
                        <QuestionsModal
                            show={this.state.showQuiz}
                            title={current?.title}
                            questions={current?.questions}
                            answers={current?.answers}
                            error={this.state.modalError}
                            timeLimit={
                                new Date().getTime() +
                                (current.timeLimit - current.spentTime) * 60000
                            }
                            allowSkip={current?.allowSkip}
                            onAnswer={this.checkAnswer}
                            onSubmit={this.handleSubmit}
                            onPause={this.handlePause}
                        />
                    )}
                {this.state.attemptToShow && (
                    <QuestionsModal
                        show={!!this.state.attemptToShow}
                        onHide={this.hideLastAttempt}
                        questions={this.state.attemptToShow?.questions}
                        title={this.state.attemptToShow?.title}
                        answers={this.state.attemptToShow?.answers}
                        attempt={this.state.attemptToShow}
                        readOnly={true}
                    />
                )}
                <Modal
                    show={this.state.showAbandon}
                    onHide={this.showAbandonOptions}>
                    <Modal.Body>
                        Are you sure you want to abandon your previous attempt?
                    </Modal.Body>
                    <Modal.Footer className='d-flex'>
                        <Button
                            className='bd mb-0'
                            onClick={() => {
                                this.handleCancel();
                                this.showAbandonOptions();
                            }}>
                            Yes, start a new quiz
                        </Button>
                        <Button
                            className='bp ml-auto mb-0'
                            onClick={() => {
                                this.handleResume();
                                this.showAbandonOptions();
                            }}>
                            No, resume previous attempt
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default withRouter(EndOfChapterQuiz);
