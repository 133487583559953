import React, { Component } from 'react';
import {
    Row,
    Col,
    Accordion,
    Card,
    Button,
    Form,
    FormGroup,
    OverlayTrigger,
    Tooltip,
    InputGroup,
    FormControl,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPencilAlt,
    faTrash,
    faTimes,
    faEllipsisV,
} from '@fortawesome/free-solid-svg-icons';
import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { FileUpload } from '../../../components/FileUpload';

export default class ItemCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            inputTitle:
                props.item.inputType[0].toUpperCase() +
                props.item.inputType.substr(1),
            modalShow: false,
            modalAction: null,
            modalType: '',
        };
    }

    handleFileChange = (type, url, file) => {
        this.props.handleFileChange(this.props.item, type, url, file);
    };

    render() {
        return (
            <div>
                <ConfirmationModal
                    show={this.state.modalShow}
                    hideModal={() => {
                        this.setState({
                            modalShow: false,
                        });
                    }}
                    confirmAction={this.state.modalAction}
                    titleText={'Are you sure?'}
                    bodyText={[
                        'You are about to ',
                        <strong key='modal-type'>
                            {this.state.modalType}
                        </strong>,
                        ' this Form Input.',
                    ]}
                />
                <Accordion defaultActiveKey='1'>
                    <Card>
                        <Accordion.Toggle as={Card.Header} eventKey='0'>
                            <Row>
                                <Col xs={12} md={6}>
                                    <span
                                        {...this.props.dragHandleProps}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}>
                                        <FontAwesomeIcon
                                            icon={faEllipsisV}
                                            style={{ marginRight: '20px' }}
                                        />
                                    </span>
                                    <Form.Label
                                        htmlFor='blockTitle'
                                        className='pt-2'
                                        style={{ fontWeight: 'normal' }}>
                                        {this.state.inputTitle}
                                    </Form.Label>
                                </Col>
                                <Col xs={12} md={6} className='my-2 my-md-0'>
                                    <OverlayTrigger
                                        key={`tooltip-delete-${this.props.idx}`}
                                        placement='top'
                                        overlay={
                                            <Tooltip
                                                id={`tooltip-delete-${this.props.idx}`}>
                                                Delete
                                            </Tooltip>
                                        }>
                                        <Button
                                            variant='link'
                                            type='button'
                                            className='float-right ml-1'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.setState({
                                                    modalShow: true,
                                                    modalAction: () => {
                                                        this.props.deleteItem(
                                                            this.props.idx
                                                        );
                                                    },
                                                    modalType: 'delete',
                                                });
                                            }}>
                                            <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        key={`tooltip-edit-${this.props.idx}`}
                                        placement='top'
                                        overlay={
                                            <Tooltip
                                                id={`tooltip-edit-${this.props.idx}`}>
                                                Edit
                                            </Tooltip>
                                        }>
                                        <Accordion.Toggle
                                            as={Button}
                                            variant='link'
                                            className='float-right mx-1'
                                            eventKey='0'>
                                            <FontAwesomeIcon
                                                icon={faPencilAlt}
                                            />
                                        </Accordion.Toggle>
                                    </OverlayTrigger>
                                </Col>
                            </Row>
                        </Accordion.Toggle>

                        <Accordion.Collapse eventKey='0'>
                            <Card.Body>
                                {this.props.item.inputType === 'textBlock' && (
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Form.Label htmlFor='textBlock'>
                                                    Text
                                                </Form.Label>
                                                <CKEditor
                                                    id='textBlock'
                                                    name='textBlock'
                                                    editor={Editor}
                                                    data={
                                                        this.props.item.extra
                                                            .copy
                                                    }
                                                    onChange={(
                                                        event,
                                                        editor
                                                    ) => {
                                                        this.props.handleChange(
                                                            this.props.idx,
                                                            'extra.copy',
                                                            editor.getData()
                                                        );
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}
                                {this.props.item.inputType === 'document' && (
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <FileUpload
                                                    id='document'
                                                    name='document'
                                                    url={
                                                        this.props.item.extra
                                                            .sourceUrl
                                                    }
                                                    handleFileChange={
                                                        this.handleFileChange
                                                    }
                                                    type='document'
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}
                                {this.props.item.inputType === 'image' && (
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <FileUpload
                                                    id='image'
                                                    name='image'
                                                    url={
                                                        this.props.item.extra
                                                            .sourceUrl
                                                    }
                                                    handleFileChange={
                                                        this.handleFileChange
                                                    }
                                                    type='image'
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}
                                {this.props.item.inputType !== 'textBlock' &&
                                    this.props.item.inputType !== 'document' &&
                                    this.props.item.inputType !== 'image' && (
                                        <div>
                                            <Row>
                                                <Col xs={12} md={6}>
                                                    <FormGroup>
                                                        <Form.Label htmlFor='label'>
                                                            Label
                                                        </Form.Label>
                                                        <Form.Control
                                                            type='text'
                                                            required
                                                            minLength='3'
                                                            maxLength='512'
                                                            id='label'
                                                            name='label'
                                                            value={
                                                                this.props.item
                                                                    .label
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                this.props.handleChange(
                                                                    this.props
                                                                        .idx,
                                                                    'label',
                                                                    event.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                    </FormGroup>
                                                    {this.props.item
                                                        .inputType ===
                                                        'typeDNA' && (
                                                        <FormGroup>
                                                            <Form.Label htmlFor='passRate'>
                                                                Pass Rate %
                                                            </Form.Label>
                                                            <Form.Control
                                                                type='number'
                                                                required
                                                                min='0'
                                                                max='100'
                                                                id='passRate'
                                                                name='passRate'
                                                                value={
                                                                    this.props
                                                                        .item
                                                                        .extra
                                                                        .passRate
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    this.props.handleChange(
                                                                        this
                                                                            .props
                                                                            .idx,
                                                                        'extra.passRate',
                                                                        event
                                                                            .target
                                                                            .value
                                                                    );
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    )}
                                                    {this.props.item
                                                        .inputType ===
                                                        'file' && (
                                                        <FormGroup>
                                                            <Form.Label htmlFor='allowedFileTypes'>
                                                                Allowed File
                                                                Types
                                                            </Form.Label>
                                                            <Form.Control
                                                                type='text'
                                                                required
                                                                minLength='3'
                                                                maxLength='512'
                                                                id='allowedFileTypes'
                                                                name='allowedFileTypes'
                                                                value={
                                                                    this.props
                                                                        .item
                                                                        .extra
                                                                        .allowedFileTypes
                                                                }
                                                                onChange={(
                                                                    event
                                                                ) => {
                                                                    this.props.handleChange(
                                                                        this
                                                                            .props
                                                                            .idx,
                                                                        'extra.allowedFileTypes',
                                                                        event
                                                                            .target
                                                                            .value
                                                                    );
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    )}
                                                    <FormGroup>
                                                        <Row>
                                                            <Col>
                                                                <Form.Label htmlFor='requiredField'>
                                                                    Required
                                                                    Field
                                                                </Form.Label>
                                                                <Form.Control
                                                                    as='select'
                                                                    id='requiredField'
                                                                    name='requiredField'
                                                                    required
                                                                    value={
                                                                        this
                                                                            .props
                                                                            .item
                                                                            .required
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        this.props.handleChange(
                                                                            this
                                                                                .props
                                                                                .idx,
                                                                            'required',
                                                                            event
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}>
                                                                    <option
                                                                        disabled
                                                                        value=''></option>
                                                                    <option value='true'>
                                                                        Yes
                                                                    </option>
                                                                    <option value='false'>
                                                                        No
                                                                    </option>
                                                                </Form.Control>
                                                            </Col>
                                                            <Col>
                                                                <Form.Label htmlFor='key'>
                                                                    Key
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type='text'
                                                                    required
                                                                    minLength='3'
                                                                    maxLength='512'
                                                                    id='key'
                                                                    name='key'
                                                                    value={
                                                                        this
                                                                            .props
                                                                            .item
                                                                            .key
                                                                    }
                                                                    onChange={(
                                                                        event
                                                                    ) => {
                                                                        this.props.handleChange(
                                                                            this
                                                                                .props
                                                                                .idx,
                                                                            'key',
                                                                            event
                                                                                .target
                                                                                .value
                                                                        );
                                                                    }}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </FormGroup>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <FormGroup>
                                                        <Form.Label htmlFor='description'>
                                                            Description
                                                        </Form.Label>
                                                        <Form.Control
                                                            as='textarea'
                                                            type='text'
                                                            rows='5'
                                                            id='description'
                                                            name='description'
                                                            value={
                                                                this.props.item
                                                                    .description
                                                            }
                                                            onChange={(
                                                                event
                                                            ) => {
                                                                this.props.handleChange(
                                                                    this.props
                                                                        .idx,
                                                                    'description',
                                                                    event.target
                                                                        .value
                                                                );
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            {this.props.item.inputType ===
                                                'options' ||
                                            this.props.item.inputType ===
                                                'checkbox' ||
                                            this.props.item.inputType ===
                                                'radio' ? (
                                                <Row>
                                                    <Col xs={12} md={6}>
                                                        <FormGroup>
                                                            <Form.Label className='mb-0'>
                                                                Options
                                                            </Form.Label>
                                                        </FormGroup>

                                                        {this.props.item.extra.options.map(
                                                            (opt, i) => {
                                                                return (
                                                                    <InputGroup
                                                                        className='mb-3'
                                                                        key={i}>
                                                                        <FormControl
                                                                            value={
                                                                                opt
                                                                            }
                                                                            onChange={(
                                                                                event
                                                                            ) => {
                                                                                this.props.handleOptionChange(
                                                                                    this
                                                                                        .props
                                                                                        .item,
                                                                                    i,
                                                                                    event
                                                                                        .target
                                                                                        .value
                                                                                );
                                                                            }}
                                                                        />
                                                                        <InputGroup.Append>
                                                                            <Button
                                                                                type='button'
                                                                                variant='link'
                                                                                onClick={() => {
                                                                                    this.props.removeItemOption(
                                                                                        this
                                                                                            .props
                                                                                            .item,
                                                                                        i
                                                                                    );
                                                                                }}>
                                                                                <FontAwesomeIcon
                                                                                    icon={
                                                                                        faTimes
                                                                                    }
                                                                                />
                                                                            </Button>
                                                                        </InputGroup.Append>
                                                                    </InputGroup>
                                                                );
                                                            }
                                                        )}
                                                        <Button
                                                            variant='btn btn--light'
                                                            type='button'
                                                            className='mt-3'
                                                            onClick={() => {
                                                                this.props.addItemOption(
                                                                    this.props
                                                                        .item
                                                                );
                                                            }}>
                                                            Add an option
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    )}
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
            </div>
        );
    }
}
