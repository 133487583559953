import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Col, Form, FormGroup, Row, InputGroup } from 'react-bootstrap';
import { FileUpload } from '../../../components/FileUpload/';
import { ContentItems } from '../../../components/FormItems';
import { Spinner } from '../../../components/Spinner';
import apiCall from '../../../helpers/apiCall';
import { RouteLeavingGuard } from '../../../components/RouteLeavingGuard';
import ReactTooltip from 'react-tooltip';
import { BsInfoCircle } from 'react-icons/bs';

class PackageForm extends Component {
    _isMounted = false;

    initialState = {
        loading: true,
        redirect: null,
        title: '',
        description: '',
        imageUrl: '',
        imageFile: '',
        publishDate: '',
        unpublishDate: '',
        price: '',
        taxType: '',
        taxValue: '',
        courses: [],
        upsell: [],
        isDirty: false,
    };

    constructor(props) {
        super(props);

        this.state = { ...this.initialState };
    }

    doRedirect = (packageId) => {
        this.setState({
            redirect: `/admin/packages/edit/${packageId}`,
        });
    };

    getPayload = () => {
        return {
            title: this.state.title,
            description: this.state.description,
            image: this.state.imageUrl,
            publishDate: this.state.publishDate ? this.state.publishDate : null,
            unpublishDate: this.state.unpublishDate
                ? this.state.unpublishDate
                : null,
            price: this.state.price,
            taxType: this.state.taxType ? this.state.taxType : 'FIXED',
            taxValue: this.state.taxValue ? this.state.taxValue : 0,
            courses: this.state.courses,
            upsell: this.state.upsell,
        };
    };

    setIsDirty = (dirty) => {
        this.setState({
            isDirty: dirty,
        });
    };

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            isDirty: true,
        });
    };

    handleImageChange = (type, imageUrl, imageFile) => {
        this.setState({
            imageUrl,
            imageFile,
            isDirty: true,
        });
    };

    handleSubmit = (event) => {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        if (this._isMounted) {
            this.setIsDirty(false);
            this.setState({
                loading: true,
            });
        }
        this.submit();
    };

    submit = async () => {
        if (this.state.imageFile) {
            let imagePostData = new FormData();
            imagePostData.append('file', this.state.imageFile);
            const { success, response } = await apiCall(
                'POST',
                '/file',
                imagePostData
            );

            if (success && this._isMounted) {
                this.setState({
                    imageUrl: response.url,
                });
            }
        }

        if (!this._isMounted) {
            return;
        }

        let submitMethod = 'POST';
        let submitUrl = '/packages';

        if (this.props.docId) {
            submitMethod = 'PUT';
            submitUrl = `/packages/${this.props.docId}`;
        }

        const { success, response } = await apiCall(
            submitMethod,
            submitUrl,
            this.getPayload()
        );

        if (this._isMounted) {
            if (success && response) {
                this.props.setGlobalAlert({
                    type: 'success',
                    message: `Package has been ${
                        this.props.docId ? 'updated' : 'created'
                    }`,
                });
                if (this.props.docId) {
                    this.setState({
                        loading: false,
                    });
                    this.removeBreadcrumbs();
                    this.createBreadcrumbs();
                } else {
                    this.doRedirect(response._id);
                }
            } else {
                this.props.setGlobalAlert({
                    type: 'error',
                    message:
                        'There was a problem with saving this Package. Please try again',
                });
                this.setState({
                    loading: false,
                });
            }
        }
    };

    loadPackage = async () => {
        if (this.props.docId) {
            this.setState({
                loading: true,
            });
            const { success, response } = await apiCall(
                'GET',
                `/packages/${this.props.docId}`
            );
            if (success && this._isMounted) {
                const publishDate = response.publishDate
                    ? response.publishDate.split('T')[0]
                    : '';
                const unpublishDate = response.unpublishDate
                    ? response.unpublishDate.split('T')[0]
                    : '';
                const taxType = response.taxType ? response.taxType : 'FIXED';

                this.setState({
                    ...response,
                    imageUrl: response.image,
                    publishDate,
                    unpublishDate,
                    taxType,
                    courses: response.courses.map((course) => {
                        return {
                            ...course,
                            draggableId: course.courseId,
                        };
                    }),
                });
            }
        } else {
            this.setState({ ...this.initialState });
        }
        if (this._isMounted) {
            this.setState({
                loading: false,
                redirect: null,
            });
        }
    };

    createBreadcrumbs = () => {
        this.props.pushBreadcrumbLink({
            text: 'Packages',
            path: '/admin/packages',
        });
        if (this.props.docId) {
            this.props.pushBreadcrumbLink({
                text: `Package: ${this.state.title}`,
                path: `/admin/packages/edit/${this.props.docId}`,
            });
        }
    };

    removeBreadcrumbs = () => {
        this.props.removeBreadcrumbLink({
            text: 'Packages',
            path: '/admin/packages',
        });
        if (this.props.docId) {
            this.props.removeBreadcrumbLink({
                text: `Package: ${this.state.title}`,
                path: `/admin/packages/edit/${this.props.docId}`,
            });
        }
    };

    componentDidMount = async () => {
        this._isMounted = true;
        await this.loadPackage();
        this.createBreadcrumbs();
        this.props.createFormActions({
            save: true,
            cancel: true,
            id: 'packageForm',
        });
    };

    componentWillUnmount() {
        this._isMounted = false;
        this.removeBreadcrumbs();
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (prevProps.docId !== this.props.docId) {
            this.setState({
                loading: true,
            });
            this.loadPackage();
        }

        if (
            this.props.formActions.state &&
            this.props.formActions.state.reload
        ) {
            this.props.createFormActions({
                ...this.props.formActions.state,
                reload: false,
            });
            this.loadPackage();
        }
    };

    setItems = (type, items) => {
        let stateUpdate = {};
        let key;
        if (type === 'package') {
            key = 'upsell';
        } else if (type === 'course') {
            key = type + 's';
        } else {
            key = type;
        }
        stateUpdate[key] = items;
        this.setState(stateUpdate);
    };

    onKeyPress = (event) => {
        if (event.charCode === 13 && event.target.nodeName !== 'TEXTAREA') {
            event.preventDefault();
            const allowedTags = ['TEXTAREA', 'INPUT', 'SELECT'];
            const form = document.getElementById('form');
            const allowedElems = Array.from(form.elements).filter((elem) => {
                return allowedTags.indexOf(elem.tagName) >= 0;
            });
            const index = allowedElems.indexOf(event.target);
            allowedElems[(index + 1) % allowedElems.length].focus();
        }
    };

    render() {
        let mainContent;

        if (this.state.redirect) {
            mainContent = <Redirect to={this.state.redirect} />;
        } else if (this.state.loading) {
            mainContent = <Spinner />;
        } else {
            mainContent = (
                <Form
                    onSubmit={this.handleSubmit}
                    id='packageForm'
                    onKeyPress={this.onKeyPress}>
                    <Row className='pt-5'>
                        <Col xs={12} lg={4}>
                            <FormGroup>
                                <Form.Label htmlFor='title'>
                                    <ReactTooltip
                                        effect='solid'
                                        multiline={true}
                                    />
                                    Package Name{' '}
                                    <BsInfoCircle data-tip='Package name is used in all menus <br/> and tiles linking to the package.' />
                                </Form.Label>
                                <Form.Control
                                    type='text'
                                    required
                                    minLength='3'
                                    maxLength='512'
                                    id='title'
                                    name='title'
                                    value={this.state.title}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Form.Label htmlFor='description'>
                                    Description
                                </Form.Label>
                                <Form.Control
                                    as='textarea'
                                    type='text'
                                    required
                                    rows='5'
                                    id='description'
                                    name='description'
                                    value={this.state.description}
                                    onChange={this.handleChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col xs={12} lg={4}>
                            <FormGroup>
                                <Form.Label htmlFor='publishDate'>
                                    Publish Date
                                </Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type='date'
                                        id='publishDate'
                                        name='publishDate'
                                        placeholder='YYYY-MM-DD'
                                        pattern='\d{4}-\d{2}-\d{2}'
                                        value={this.state.publishDate}
                                        onChange={this.handleChange}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text
                                            type='button'
                                            onClick={() => {
                                                this.setState({
                                                    publishDate: '',
                                                });
                                            }}>
                                            X
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Form.Label htmlFor='unpublishDate'>
                                    Unpublish Date
                                </Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type='date'
                                        id='unpublishDate'
                                        name='unpublishDate'
                                        placeholder='YYYY-MM-DD'
                                        pattern='\d{4}-\d{2}-\d{2}'
                                        value={this.state.unpublishDate}
                                        onChange={this.handleChange}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text
                                            type='button'
                                            onClick={() => {
                                                this.setState({
                                                    unpublishDate: '',
                                                });
                                            }}>
                                            X
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col>
                                        <Form.Label htmlFor='price'>
                                            Price
                                        </Form.Label>
                                        <Form.Control
                                            type='number'
                                            id='price'
                                            name='price'
                                            required
                                            min='0'
                                            value={this.state.price}
                                            onChange={
                                                this.handleChange
                                            }></Form.Control>
                                    </Col>
                                    <Col>
                                        <Form.Label htmlFor='taxType'>
                                            Tax
                                        </Form.Label>
                                        <Form.Control
                                            as='select'
                                            id='taxType'
                                            name='taxType'
                                            required
                                            value={this.state.taxType}
                                            onChange={this.handleChange}>
                                            <option value='FIXED'>$</option>
                                            <option value='PERCENTAGE'>
                                                %
                                            </option>
                                        </Form.Control>
                                    </Col>
                                    <Col>
                                        <Form.Label htmlFor='taxValue'>
                                            &nbsp;
                                        </Form.Label>
                                        <Form.Control
                                            type='number'
                                            id='taxValue'
                                            name='taxValue'
                                            min='0'
                                            value={this.state.taxValue}
                                            onChange={
                                                this.handleChange
                                            }></Form.Control>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                        <Col xs={12} lg={4}>
                            <FormGroup>
                                <FileUpload
                                    id='image'
                                    name='image'
                                    url={this.state.imageUrl}
                                    handleFileChange={this.handleImageChange}
                                    type='image'
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className='pt-4'>
                        <Col>
                            <ContentItems
                                type='course'
                                items={this.state.courses}
                                setItems={this.setItems}
                                setIsDirty={this.setIsDirty}
                            />
                        </Col>
                    </Row>
                    <Row className='pt-5'>
                        <Col>
                            <ContentItems
                                type='package'
                                items={this.state.upsell}
                                setItems={this.setItems}
                                parentDocId={this.props.docId}
                                setIsDirty={this.setIsDirty}
                            />
                        </Col>
                    </Row>
                </Form>
            );
        }
        return (
            <div>
                <RouteLeavingGuard
                    when={this.state.isDirty}
                    navigate={(path) => this.props.history.push(path)}
                    shouldBlockNavigation={() => {
                        return this.state.isDirty;
                    }}
                />
                <main>{mainContent}</main>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return {
            formActions: state.formActions,
        };
    },
    {
        pushBreadcrumbLink: (payload) => ({
            type: 'PUSH_BREADCRUMB_LINK',
            payload,
        }),
        removeBreadcrumbLink: (payload) => ({
            type: 'REMOVE_BREADCRUMB_LINK',
            payload,
        }),
        setGlobalAlert: (payload) => ({
            type: 'SET_GLOBAL_ALERT',
            payload,
        }),
        createFormActions: (payload) => ({
            type: 'CREATE_FORM_ACTIONS',
            payload,
        }),
    }
)(withRouter(PackageForm));
