import React, { Component } from 'react';
import { ApiTable } from '../../../components/ApiTable';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
// import { GoPencil } from 'react-icons/go';
import apiCall from '../../../helpers/apiCall';
import { Button } from 'react-bootstrap';
import { Link, Switch, Route } from 'react-router-dom';

export default class Quizzes extends Component {
    // constructor(props) {
    //     super(props);

    //     this.state = {
    //         buttonClicked: false,
    //     };
    // }

    // newCourseHandler = () => {
    //     this.setState({
    //         buttonClicked: true,
    //     });
    // };

    render() {
        // const { buttonClicked } = this.state;
        return (
            <div>
                {/* <Button onClick={this.newCourseHandler}>New Quiz</Button> */}

                <Switch>
                    <Route exact path='/admin/quizzes'>
                        <Link
                            // onClick={this.newCourseHandler}
                            to='/admin/quizzes/new'
                            className='btn btn-primary'>
                            New Quiz
                        </Link>
                        <ApiTable
                            basePath='/admin/quizzes'
                            apiCall={{
                                method: 'GET',
                                path: '/quizzes',
                            }}
                            columns={[
                                {
                                    text: 'Title',
                                    field: 'title',
                                    minWidth: '70%',
                                },
                                {
                                    text: 'Status',
                                    field: (row) =>
                                        `${
                                            row.status.charAt(0).toUpperCase() +
                                            row.status.slice(1)
                                        }`,
                                },
                                // {
                                //     text: 'Address',
                                //     field: (row) => {
                                //         let address = row.addressLineOne;

                                //         if (row.addressLineTwo) {
                                //             address += ` ${row.addressLineTwo}`;
                                //         }

                                //         if (row.townCity) {
                                //             address += `, ${row.townCity}`;
                                //         }

                                //         if (row.zipCode) {
                                //             address += ` ${row.zipCode}`;
                                //         }

                                //         if (row.state) {
                                //             address += `, ${row.state}`;
                                //         }

                                //         return address;
                                //     },
                                //     minWidth: '35%',
                                // },
                            ]}
                            rowButtons={[
                                {
                                    text: 'Edit quiz',
                                    url: '/admin/quizzes/:_id',
                                    icon: faPencilAlt,
                                },
                                {
                                    text: 'Delete quiz',
                                    icon: faTrash,
                                    clickCallback: async (
                                        e,
                                        row,
                                        reloadTable
                                    ) => {
                                        if (
                                            window.confirm(
                                                'Are you sure you want to delete this quiz?'
                                            )
                                        ) {
                                            const { success } = await apiCall(
                                                'DELETE',
                                                '/quizzes/' + row._id
                                            );

                                            if (success) {
                                                alert(
                                                    `Quiz ${row.title} was deleted`
                                                );

                                                await reloadTable();
                                            } else {
                                                alert(
                                                    `Quiz ${row.title} couldn't be deleted`
                                                );
                                            }
                                        } else {
                                            alert(
                                                `Quiz ${row.title} not deleted`
                                            );
                                        }
                                    },
                                },
                            ]}
                        />
                    </Route>
                    <Route exact path='/admin/quizzes/new'>
                        <div>New Quiz Form</div>
                    </Route>
                </Switch>
            </div>
        );
    }
}

//     render() {
//         const { buttonClicked } = this.state;
//         return buttonClicked ? (
//             <div>New Quiz Form</div>
//         ) : (
//             <div>
//                 {/* <Button onClick={this.newCourseHandler}>New Quiz</Button> */}
//                 <Link
//                     onClick={this.newCourseHandler}
//                     to='/admin/quizzes/new'
//                     className='btn btn-primary'>
//                     New template
//                 </Link>
//                 <ApiTable
//                     basePath='/admin/quizzes'
//                     apiCall={{
//                         method: 'GET',
//                         path: '/quizzes',
//                     }}
//                     columns={[
//                         {
//                             text: 'Title',
//                             field: 'title',
//                             minWidth: '70%',
//                         },
//                         {
//                             text: 'Status',
//                             field: (row) =>
//                                 `${
//                                     row.status.charAt(0).toUpperCase() +
//                                     row.status.slice(1)
//                                 }`,
//                         },
//                         // {
//                         //     text: 'Address',
//                         //     field: (row) => {
//                         //         let address = row.addressLineOne;

//                         //         if (row.addressLineTwo) {
//                         //             address += ` ${row.addressLineTwo}`;
//                         //         }

//                         //         if (row.townCity) {
//                         //             address += `, ${row.townCity}`;
//                         //         }

//                         //         if (row.zipCode) {
//                         //             address += ` ${row.zipCode}`;
//                         //         }

//                         //         if (row.state) {
//                         //             address += `, ${row.state}`;
//                         //         }

//                         //         return address;
//                         //     },
//                         //     minWidth: '35%',
//                         // },
//                     ]}
//                     rowButtons={[
//                         {
//                             text: 'Edit quiz',
//                             url: '/admin/quizzes/:_id',
//                             icon: faPencilAlt,
//                         },
//                         {
//                             text: 'Delete quiz',
//                             icon: faTrash,
//                             clickCallback: async (e, row, reloadTable) => {
//                                 if (
//                                     confirm(
//                                         'Are you sure you want to delete this quiz?'
//                                     )
//                                 ) {
//                                     const { success } = await apiCall(
//                                         'DELETE',
//                                         '/quizzes/' + row._id
//                                     );

//                                     if (success) {
//                                         alert(`Quiz ${row.title} was deleted`);

//                                         await reloadTable();
//                                     } else {
//                                         alert(
//                                             `Quiz ${row.title} couldn't be deleted`
//                                         );
//                                     }
//                                 } else {
//                                     alert(`Quiz ${row.title} not deleted`);
//                                 }
//                             },
//                         },
//                     ]}
//                 />
//             </div>
//         );
//     }
// }
