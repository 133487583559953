import React, { Component } from 'react';
import {
    div,
    Button,
    Row,
    Col,
    Image,
    Container,
    ResponsiveEmbed,
    Input,
} from 'react-bootstrap';
import './index.scss';

export class AceableStatements extends Component {
    constructor(props) {
        super(props);

        this.state = {
            correctAnswer: false,
            answer: null,
            question: '', // don't know how this quiz will work yet so should leave blank for now.
            correctlyAnswered: null,
            selectedImage: '',
        };
    }
    checkAnswer(answer) {
        if (answer == this.state.correctAnswer) {
            this.setState({ correctlyAnswered: true, answer: answer });
        } else {
            this.setState({ correctlyAnswered: false, answer: answer });
        }
    }
    render() {
        const { correctAnswer, correctlyAnswered } = this.state;
        return (
            <div className='aceable-statements-quiz'>
                <div className='header' style={{ display: 'block' }}>
                    <span>heading</span>
                </div>
                <div className='aceable-statements'>
                    <div
                        className='quiz-statement'
                        style={{ display: 'block' }}>
                        <span>Some statement</span>
                    </div>
                    <Row className='option-boxes-row'>
                        <Col xs='6'>
                            <div className='option-box'>
                                <div className='option-name'>
                                    <span>Choice 1</span>
                                </div>
                                <div>
                                    <span className='dot' />
                                    <span className='dot' />
                                    <span className='dot' />
                                </div>
                            </div>
                        </Col>
                        <Col xs='6'>
                            <div className='option-box'>
                                <div className='option-name'>
                                    <span>Choice 2</span>
                                </div>
                                <div>
                                    <span className='dot' />
                                    <span className='dot' />
                                    <span className='dot' />
                                </div>
                            </div>
                        </Col>
                        <Col xs='6'>
                            <div className='option-box'>
                                <div className='option-name'>
                                    <span>Choice 3</span>
                                </div>
                                <div>
                                    <span className='dot' />
                                    <span className='dot' />
                                    <span className='dot' />
                                </div>
                            </div>
                        </Col>
                        <Col xs='6'>
                            <div className='option-box'>
                                <div className='option-name'>
                                    <span>Choice 4</span>
                                </div>
                                <div>
                                    <span className='dot' />
                                    <span className='dot' />
                                    <span className='dot' />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }
}
