import React, { Component } from 'react';
import { ApiTable } from '../../../components/ApiTable';
import { connect } from 'react-redux';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { faTrash, faUserEdit, faEdit } from '@fortawesome/free-solid-svg-icons';
import apiCall from '../../../helpers/apiCall';
import { ConfirmationModal } from '../../../components/ConfirmationModal';
import { Link } from 'react-router-dom';
import {
    Alert,
    Button,
    Col,
    Modal,
    OverlayTrigger,
    Row,
    Tooltip,
} from 'react-bootstrap';
import { checkEditing } from '../../../helpers/checkEditing.event';

class UsersList extends Component {
    state = {
        toDelete: false,
        modalConfirmAction: null,
        showModalForm: false,
        modalFormMessage: '',
        firstName: '',
        lastName: '',
        email: '',
        emailCheck: '',
        adminLevel: 1,
    };

    constructor(props) {
        super(props);

        props.pushBreadcrumbLink({
            text: 'Users',
            path: '/admin/users',
        });
    }

    toggleShowModalForm = (e) => {
        if (e) {
            e.preventDefault();
        }

        this.setState({
            showModalForm: !this.state.showModalForm,
        });
    };

    handleFormSubmit = async () => {
        const { success, message } = await apiCall('POST', '/users/admin', {
            email: this.state.email,
            emailCheck: this.state.emailCheck,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            adminLevel: this.state.adminLevel,
        });

        this.props.setGlobalAlert({
            type: success ? 'success' : 'error',
            message,
        });

        this.setState({
            showModalForm: !this.state.showModalForm,
        });
    };

    handleInputChange = (e) => {
        const input = e.target;

        this.setState({
            [input.name]: input.value,
        });
    };

    unsetUserToDelete = () => {
        this.setState({
            toDelete: false,
        });
    };

    render() {
        const { modalFormMessage } = this.state;

        return (
            <div>
                <Row className='page-header padding'>
                    <Col>
                        <h3>Users</h3>
                    </Col>
                    <div className='page-controls'>
                        <Button
                            onClick={this.toggleShowModalForm}
                            className='bp'>
                            Create new admin
                        </Button>
                    </div>
                </Row>
                <ApiTable
                    basePath='/admin/users'
                    apiCall={{
                        method: 'GET',
                        path: '/users',
                    }}
                    columns={[
                        {
                            text: '',
                            field: (row) => {
                                const { editingAdmin } = row;

                                return (
                                    <OverlayTrigger
                                        placement='top'
                                        overlay={
                                            <Tooltip
                                                id={`tooltip-${row._id}-edit-course)}`}>
                                                {editingAdmin
                                                    ? `${editingAdmin.firstName} ${editingAdmin.lastName} editing`
                                                    : 'Edit user'}
                                            </Tooltip>
                                        }>
                                        <Link
                                            onClick={(e) => {
                                                checkEditing(e, !!editingAdmin);
                                            }}
                                            className='btn btn--small'
                                            to={'/admin/users/' + row._id}>
                                            <Fa icon={faEdit} />
                                        </Link>
                                    </OverlayTrigger>
                                );
                            },
                            maxWidth: '3.11rem',
                        },
                        {
                            text: 'Full name',
                            sortKey: 'firstName-lastName',
                            field: (row) => `${row.firstName} ${row.lastName}`,
                        },
                        {
                            text: 'Email',
                            field: 'email',
                            sortKey: 'email',
                        },
                        {
                            text: 'Phone number',
                            field: 'phoneNumber',
                            sortKey: 'phoneNumber',
                        },
                        {
                            text: 'Address',
                            field: (row) => {
                                let address = row.addressLineOne;

                                if (row.addressLineTwo) {
                                    address += ` ${row.addressLineTwo}`;
                                }

                                if (row.townCity) {
                                    address += `, ${row.townCity}`;
                                }

                                if (row.zipCode) {
                                    address += ` ${row.zipCode}`;
                                }

                                if (row.state) {
                                    address += `, ${row.state}`;
                                }

                                return address;
                            },
                            minWidth: '35%',
                        },
                    ]}
                    rowButtons={[
                        {
                            text: 'Delete user',
                            icon: faTrash,
                            clickCallback: async (e, row, reloadTable) => {
                                this.setState({
                                    toDelete: row,
                                    modalConfirmAction: async () => {
                                        this.setState({
                                            toDelete: false,
                                        });

                                        const { success, message } =
                                            await apiCall(
                                                'DELETE',
                                                '/users/' + row._id
                                            );

                                        if (success) {
                                            this.props.setGlobalAlert({
                                                type: 'success',
                                                message,
                                            });
                                            await reloadTable();
                                        } else {
                                            this.props.setGlobalAlert({
                                                type: 'error',
                                                message,
                                            });
                                        }
                                    },
                                });
                            },
                        },
                    ]}
                />
                <Modal
                    size='lg'
                    show={this.state.showModalForm}
                    onHide={this.toggleShowModalForm}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create new admin user</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div
                            className={
                                'form' +
                                (modalFormMessage ? ' form--with-error' : '')
                            }>
                            {modalFormMessage && (
                                <Alert variant='warning'>
                                    {modalFormMessage}
                                </Alert>
                            )}
                            <div className='form__content'>
                                <form action='/'>
                                    <Row>
                                        <Col>
                                            <div className='form__field'>
                                                <label htmlFor='firstName'>
                                                    First name
                                                </label>
                                                <input
                                                    type='text'
                                                    name='firstName'
                                                    onChange={
                                                        this.handleInputChange
                                                    }
                                                    defaultValue={
                                                        this.state.firstName
                                                    }
                                                />
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='form__field'>
                                                <label htmlFor='lastName'>
                                                    Last name
                                                </label>
                                                <input
                                                    type='text'
                                                    name='lastName'
                                                    onChange={
                                                        this.handleInputChange
                                                    }
                                                    defaultValue={
                                                        this.state.lastName
                                                    }
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={2}>
                                            <div className='form__field'>
                                                <label htmlFor='adminLevel'>
                                                    Admin level
                                                </label>
                                                <input
                                                    type='number'
                                                    name='adminLevel'
                                                    min='1'
                                                    max='9'
                                                    onChange={
                                                        this.handleInputChange
                                                    }
                                                    defaultValue={
                                                        this.state.adminLevel
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className='form__field'>
                                                <label htmlFor='email'>
                                                    Email address
                                                </label>
                                                <input
                                                    type='text'
                                                    name='email'
                                                    onChange={
                                                        this.handleInputChange
                                                    }
                                                    defaultValue={
                                                        this.state.email
                                                    }
                                                />
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='form__field'>
                                                <label htmlFor='emailCheck'>
                                                    Email address confirmation
                                                </label>
                                                <input
                                                    type='text'
                                                    name='emailCheck'
                                                    onChange={
                                                        this.handleInputChange
                                                    }
                                                    defaultValue={
                                                        this.state.emailCheck
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleFormSubmit}>Create</Button>
                        <button
                            className='btn btn--light'
                            onClick={this.toggleShowModalForm}>
                            Cancel
                        </button>
                    </Modal.Footer>
                </Modal>
                <ConfirmationModal
                    show={this.state.toDelete !== false}
                    hideModal={this.unsetUserToDelete}
                    titleText={`Delete user ${
                        this.state.toDelete !== false
                            ? this.state.toDelete.firstName +
                              ' ' +
                              this.state.toDelete.lastName
                            : ''
                    } `}
                    bodyText="Are you sure you want to delete user's account?"
                    confirmAction={this.state.modalConfirmAction}
                />
            </div>
        );
    }
}

export default connect(null, {
    setGlobalAlert: (payload) => ({
        type: 'SET_GLOBAL_ALERT',
        payload,
    }),
    pushBreadcrumbLink: (payload) => ({
        type: 'PUSH_BREADCRUMB_LINK',
        payload,
    }),
})(UsersList);
