import React, { Component } from 'react';
import './Notifications.scss';
import Notification from './Notification';
import { Col, Row, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import apiCall from '../../helpers/apiCall';

class Notifications extends Component {
    constructor(props) {
        super(props);

        this.state = {
            unreadCount: 0,
            notificationsList: [],
            loading: false,
        };
    }

    loadNotifications = async () => {
        if (!this.state.loading) {
            this.setState({
                loading: true,
            });
        }

        const {
            response: { docs: notificationsList, unreadCount },
            success,
            message,
        } = await apiCall('GET', '/users/notifications/all');

        if (success) {
            this.setState({
                unreadCount,
                notificationsList,
            });
        } else {
            this.props.setGlobalAlert({ type: 'error', message });
        }

        this.setState({
            loading: false,
        });
    };

    markAllRead = async () => {
        const { response, success, message } = await apiCall(
            'POST',
            '/users/notifications/read'
        );

        if (success) {
            this.setState({
                unreadCount: 0,
                notificationsList: this.state.notificationsList.map(
                    (notification) => ({ ...notification, readAt: response })
                ),
            });

            this.props.setGlobalAlert({
                type: 'success',
                message: 'All notifications marked as read',
            });
        } else {
            this.props.setGlobalAlert({ type: 'error', message });
        }
    };

    async componentDidMount() {
        await this.loadNotifications();
    }

    markAsRead = async (key) => {
        const { response, success, message } = await apiCall(
            'POST',
            '/users/notifications/read/' + this.state.notificationsList[key]._id
        );

        if (success) {
            const notificationsList = [...this.state.notificationsList];

            notificationsList[key].readAt = response;

            this.setState({
                unreadCount: this.state.unreadCount - 1,
                notificationsList,
            });

            this.props.setGlobalAlert({
                type: 'success',
                message: 'Notification marked as read',
            });
        } else {
            this.props.setGlobalAlert({ type: 'error', message });
        }
    };

    render() {
        const { notificationsList } = this.state;
        if (this.state.loading) {
            return (
                <div className='center-loading big'>
                    <Spinner animation='border' />
                </div>
            );
        }
        if (notificationsList.length !== 0) {
            return (
                <div id='user-messages'>
                    <Row noGutters className='padding'>
                        <Col>
                            <div>
                                {this.state.unreadCount} unread&nbsp;
                                {this.state.unreadCount === 1
                                    ? 'notification'
                                    : 'notifications'}
                            </div>
                        </Col>
                        {this.state.unreadCount > 0 && (
                            <Col>
                                <span
                                    className='btn btn--light float-right'
                                    onClick={this.markAllRead}>
                                    Mark all as read
                                </span>
                            </Col>
                        )}
                    </Row>

                    {notificationsList.map((notification, key) => (
                        <Notification
                            doc={notification}
                            key={notification._id}
                            index={key}
                            markAsRead={async () => {
                                await this.markAsRead(key);
                            }}
                        />
                    ))}
                </div>
            );
        } else {
            return <div>No Messages</div>;
        }
    }
}

export default connect(null, {
    setGlobalAlert: (payload) => ({
        type: 'SET_GLOBAL_ALERT',
        payload,
    }),
})(Notifications);
