import './index.scss';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import WaveSurfer from 'wavesurfer.js';
import AudioDetails from './AudioDetails';
class AudioLarge extends Component {
    constructor(props) {
        super(props);

        this.state = {
            playing: false,
            volume: 0.5,
            audioDuration: 0,
            currentPosition: 0,
            loaded: false,
        };
    }

    componentDidMount() {
        this.waveform = WaveSurfer.create({
            barWidth: 3,
            cursorWidth: 1,
            container: '#waveform',
            backend: 'WebAudio',
            height: 100,
            progressColor: '#DBDBED',
            responsive: true,
            waveColor: '#637796',
            cursorColor: 'transparent',
            hideScrollbar: true,
            removeMediaElementOnDestroy: true,
        });

        // if (this.props.audio != (undefined || null)) {
        //     this.setState({ audio: this.props.audio });
        // }
        if (this.props.audio != undefined) {
            // this.waveform.load(this.props.audio);
            this.waveform.load(this.props.audio);
            this.waveform.setVolume(this.state.volume);

            this.waveform.on('ready', () => {
                const duration = this.waveform.getDuration();
                console.log(duration);
                this.setState({
                    audioDuration: duration,
                });
            });

            this.waveform.on('audioprocess', () => {
                const current = this.waveform.getCurrentTime();
                this.setState({
                    currentPosition: current,
                });
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.loaded || prevProps.audio != this.props.audio) {
            this.waveform.load(this.props.audio);
            this.waveform.setVolume(this.state.volume);

            this.waveform.on('ready', () => {
                const duration = this.waveform.getDuration();
                this.setState({
                    audioDuration: duration,
                    loaded: true,
                });
            });

            this.waveform.on('audioprocess', () => {
                const current = this.waveform.getCurrentTime();
                this.setState({
                    currentPosition: current,
                });
            });
        }

        if (prevProps.currentLesson != this.props.currentLesson) {
            this.waveform.setVolume(0);
        }
    }

    handleTest = () => {
        const duration = this.waveform.getDuration();
        this.setState({
            audioDuration: duration,
        });
    };

    handlePlay = () => {
        this.waveform.play();
        this.setState({ playing: !this.state.playing });
    };

    handlePause = () => {
        this.waveform.pause();
        this.setState({ playing: !this.state.playing });
    };

    handleVolumeChange = (volume) => {
        this.waveform.setVolume(volume);
        this.setState({
            volume: volume,
        });
    };

    render() {
        if (this.props.audio != undefined) {
            return (
                <div>
                    <div
                        style={{
                            backgroundColor: '#3c495ef2',
                            margin: '0px',
                        }}>
                        <div id='waveform' />
                    </div>
                    <div className='player'>
                        <div className='controls'>
                            <AudioDetails
                                playAudio={() => this.handlePlay()}
                                pauseAudio={() => this.handlePause()}
                                audio={this.props.audio}
                                playing={this.state.playing}
                                duration={this.state.audioDuration}
                                currentPosition={this.state.currentPosition}
                                title={this.props.heading}
                                subTitle={this.props.subHeading}
                            />
                            <div className='volume-slider'>
                                <div>
                                    <input
                                        type='range'
                                        min={0}
                                        max={1}
                                        step={0.02}
                                        value={this.state.volume}
                                        onChange={(e) => {
                                            this.handleVolumeChange(
                                                e.target.value
                                            );
                                        }}
                                    />
                                </div>
                                <div>
                                    {/* <p>final volume: {finalVolume.toFixed(3)}</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div />;
        }
    }
}

const mapStateToProps = (state) => {
    const { currentLesson } = state;
    return {
        currentLesson,
    };
};

export default connect(mapStateToProps)(AudioLarge);
