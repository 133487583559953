import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactPlayer from 'react-player';
import {
    setUserInactive,
    setLastSeen,
} from '../../../store/actions/userActions';
import Player from '@vimeo/player';

class VideoPlayer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userInactive: false,
            playing: false,
        };
        this.vimeoIframe = React.createRef();
    }

    componentDidUpdate() {
        const iframe = this.vimeoIframe.current;
        const player = new Player(iframe);

        player.on('play', function () {
            // console.log('play');
            this.handleUserPlay();
        });
        player.on('pause', function () {
            // console.log('pause');
            this.handleUserPause();
        });
        if (this.props.userInactive.state != this.state.userInactive) {
            if (this.props.userInactive.state) {
                this.setState({ userInactive: this.props.userInactive.state });
                if (this.props.currentCourse.state.timerPause) {
                    this.setState({ playing: false });
                }
            } else {
                this.setState({ userInactive: this.props.userInactive.state });
            }
        }
    }

    handleUserPause = () => {
        // have a request here as well when endpoints ready
        if (this.props.currentCourse.state.timerPause) {
            this.setState({ playing: false });
        }
    };

    handleUserPlay = () => {
        // have a request here as well when endpoints ready
        if (this.props.currentCourse.state.timerPause) {
            this.setState({ playing: true });
        }
    };

    render() {
        const { sourceVideo } = this.props;
        return (
            <iframe
                ref={this.vimeoIframe}
                src={sourceVideo}
                width='100%'
                height='100%'
                frameBorder='0'
                allowFullScreen
            />
        );
    }
}

const mapStateToProps = (state) => {
    const { userInactive, currentCourse } = state;
    return {
        userInactive,
        currentCourse,
    };
};

const mapDispatchToProps = {
    setUserInactive,
    setLastSeen,
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayer);
