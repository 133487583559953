import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PercentageIcon } from '../../../assets/custom-icons/percentage';
import './CourseProgress.scss';

class CourseProgress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    render() {
        return (
            <div
                className={
                    this.state.open
                        ? 'course-progress-modal active'
                        : 'course-progress-modal'
                }>
                <div className='icon-background'>
                    <span
                        onClick={() =>
                            this.setState({ open: !this.state.open })
                        }>
                        <PercentageIcon />
                    </span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    // state argument is the entire redux store
    const { allLessons, currentLesson, allChapters } = state; // inside curly braces we have the name of the state we want
    return { allLessons, currentLesson, allChapters };
};

export default connect(mapStateToProps)(CourseProgress);
