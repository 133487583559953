import React, { Component } from 'react';
import {
    Card,
    Button,
    Row,
    Col,
    Image,
    Container,
    ResponsiveEmbed,
    Input,
} from 'react-bootstrap';
import './index.scss';

export class MultiSelectImages extends Component {
    constructor(props) {
        super(props);

        this.state = {
            correctAnswers: ['answer 4', 'answer 5'],
            answer: null,
            question: '', // don't know how this quiz will work yet so should leave blank for now.
            answers: [],
            correctlyAnswered: null,
            selectedImage: '',
        };
    }

    pushAnswer(answer) {
        if (this.state.answers.includes(answer)) {
            let array = [...this.state.answers]; // make a separate copy of the array
            let index = array.indexOf(answer);
            if (index !== -1) {
                array.splice(index, 1);
                this.setState({ answers: array });
            }
        } else {
            const value = this.state.answers.concat(answer);
            this.setState({ answers: value });
        }
    }

    checkAnswers() {
        this.state.answers.map((a, i) => {
            if (!this.state.correctAnswers.includes(a)) {
                this.setState({ correctlyAnswered: false });
            } else {
                this.setState({ correctlyAnswered: true });
            }
        });
    }
    render() {
        const { correctAnswer, correctlyAnswered } = this.state;
        return (
            <div className='multi-select-images-quiz'>
                <div className='multi-select-images'>
                    <div className='header' style={{ display: 'block' }}>
                        <span>heading</span>
                    </div>
                    <div className='quiz-question' style={{ display: 'block' }}>
                        <span>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Aliquam at porttitor sem.
                        </span>
                    </div>
                    <Row className='quiz-options'>
                        <Col xs={4} sm={4} lg={3} className='quiz-option'>
                            <Card onClick={() => this.pushAnswer('answer 1')}>
                                <Image
                                    className={
                                        this.state.selectedImage == 'answer 1' // if this option in selected options, apply active else no active class
                                            ? 'quiz-image active'
                                            : 'quiz-image'
                                    }
                                    src='https://think.kera.org/wp-content/uploads/2020/05/shutterstock_750703924-800x500.jpg'
                                    thumbnail
                                />
                                <Card.Text>Answer 1</Card.Text>
                            </Card>
                        </Col>
                        <Col xs={4} sm={4} lg={3} className='quiz-option'>
                            <Card onClick={() => this.pushAnswer('answer 2')}>
                                <Image
                                    className='quiz-image'
                                    src='https://cdn.the-scientist.com/assets/articleNo/66864/aImg/35078/foresttb-m.jpg'
                                    thumbnail
                                />
                                <Card.Text>Answer 2</Card.Text>
                            </Card>
                        </Col>{' '}
                        <Col xs={4} sm={4} lg={3} className='quiz-option'>
                            <Card onClick={() => this.pushAnswer('answer 3')}>
                                <Image
                                    className='quiz-image'
                                    src='https://think.kera.org/wp-content/uploads/2020/05/shutterstock_750703924-800x500.jpg'
                                    thumbnail
                                />
                                <Card.Text>Answer 3</Card.Text>
                            </Card>
                        </Col>
                        <Col xs={4} sm={4} lg={3} className='quiz-option'>
                            <Card onClick={() => this.pushAnswer('answer 4')}>
                                <Image
                                    className='quiz-image'
                                    src='https://cdn.the-scientist.com/assets/articleNo/66864/aImg/35078/foresttb-m.jpg'
                                    thumbnail
                                />
                                <Card.Text>Answer 4</Card.Text>
                            </Card>
                        </Col>
                        <Col xs={4} sm={4} lg={3} className='quiz-option'>
                            <Card onClick={() => this.pushAnswer('answer 5')}>
                                <Image
                                    className='quiz-image'
                                    src='https://think.kera.org/wp-content/uploads/2020/05/shutterstock_750703924-800x500.jpg'
                                    thumbnail
                                />
                                <Card.Text>Answer 5</Card.Text>
                            </Card>
                        </Col>{' '}
                        <Col xs={4} sm={4} lg={3} className='quiz-option'>
                            <Card onClick={() => this.pushAnswer('answer 6')}>
                                <Image
                                    className='quiz-image'
                                    src='https://cdn.the-scientist.com/assets/articleNo/66864/aImg/35078/foresttb-m.jpg'
                                    thumbnail
                                />
                                <Card.Text>Answer 6</Card.Text>
                            </Card>
                        </Col>{' '}
                        <Col xs={4} sm={4} lg={3} className='quiz-option'>
                            <Card onClick={() => this.pushAnswer('answer 7')}>
                                <Image
                                    className='quiz-image'
                                    src='https://think.kera.org/wp-content/uploads/2020/05/shutterstock_750703924-800x500.jpg'
                                    thumbnail
                                />
                                <Card.Text>Answer 7</Card.Text>
                            </Card>
                        </Col>{' '}
                        <Col xs={4} sm={4} lg={3} className='quiz-option'>
                            <Card onClick={() => this.pushAnswer('answer 8')}>
                                <Image
                                    className='quiz-image'
                                    src='https://cdn.the-scientist.com/assets/articleNo/66864/aImg/35078/foresttb-m.jpg'
                                    thumbnail
                                />
                                <Card.Text>Answer 8</Card.Text>
                            </Card>
                        </Col>
                        {/* <Col xs={4} sm={4} lg={3} className='quiz-option'>
                         <Card>
                         <Image className='quiz-image'
                         src='https://think.kera.org/wp-content/uploads/2020/05/shutterstock_750703924-800x500.jpg'
                         thumbnail
                         />
                         <Card.Text>Answer 1</Card.Text>
                         </Card>
                        </Col>{' '} */}
                        {/* <Col xs={4} sm={4} lg={3} className='quiz-option'>
                         <Card>
                         <Image className='quiz-image'
                         src='https://think.kera.org/wp-content/uploads/2020/05/shutterstock_750703924-800x500.jpg'
                         thumbnail
                         />
                         <Card.Text>Answer 1</Card.Text>
                         </Card>
                        </Col> */}
                    </Row>
                    <h1>
                        {this.state.correctlyAnswered ? (
                            <div className='correct-answer-alert'>
                                <h1>Correct!!!</h1>
                            </div>
                        ) : this.state.correctlyAnswered == false ? (
                            <div className='wrong-answer-alert'>
                                <h1>Try again!</h1>
                            </div>
                        ) : null}
                    </h1>
                    <Row
                        style={{
                            justifyContent: 'center',
                            margin: '20px',
                        }}>
                        <Button
                            style={{ width: '30%' }}
                            onClick={() => this.checkAnswers()}>
                            Check Answers
                        </Button>
                    </Row>
                </div>
            </div>
        );
    }
}
