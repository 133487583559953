import React, { Component } from 'react';
import { connect } from 'react-redux';

class UserCertificate extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            chapters: [],
        };
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            this.props.setShowCourseMenu(false);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return <div>UserCertificate</div>;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setShowCourseMenu: (payload) => {
            dispatch({
                type: 'SET_SHOW_COURSE_MENU',
                payload,
            });
        },
    };
};

export default connect(null, mapDispatchToProps)(UserCertificate);
