import React, { Component } from 'react';
import {
    Card,
    Button,
    Row,
    Col,
    Image,
    Container,
    ResponsiveEmbed,
    Input,
} from 'react-bootstrap';
import './index.scss';

export class TrueFalseOneQuestion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            correctAnswer: false,
            answer: null,
            question: '', // don't know how this quiz will work yet so should leave blank for now.
            correctlyAnswered: null,
        };
    }
    checkAnswer(answer) {
        if (answer == this.state.correctAnswer) {
            this.setState({ correctlyAnswered: true, answer: answer });
        } else {
            this.setState({ correctlyAnswered: false, answer: answer });
        }
    }
    render() {
        const { correctAnswer, correctlyAnswered } = this.state;
        return (
            <div className='true-false-one-question-quiz'>
                <div className='true-false-one-question'>
                    <div className='header' style={{ display: 'block' }}>
                        <span>heading</span>
                    </div>
                    <div className='quiz-question' style={{ display: 'block' }}>
                        <span>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit. Aliquam at porttitor sem. Aliquam erat
                            volutpat. Donec placerat nisl magna, et faucibus
                            arcu condimentum sed. Lorem ipsum dolor sit amet,
                            consectetur adipiscing elit.
                        </span>
                    </div>
                    <Row className='button-group'>
                        <Col xs='12' sm='6' lg='6'>
                            <Button
                                className={`true-button ${
                                    correctAnswer && correctlyAnswered
                                        ? 'correct'
                                        : ''
                                }`}
                                style={{
                                    height: '60px',
                                    margin: '20px 0 ',
                                }}
                                onClick={() => this.checkAnswer(true)}>
                                TRUE
                            </Button>
                        </Col>
                        <Col xs='12' sm='6' lg='6'>
                            <Button
                                className='false-button'
                                style={{
                                    height: '60px',
                                    margin: '20px 0',
                                }}
                                onClick={() => this.checkAnswer(false)}>
                                FALSE
                                {/* {correctlyAnswered == null
                                    ? 'FALSE'
                                    : this.state.correctlyAnswered &&
                                      !correctAnswer
                                    ? 'Correct'
                                    : 'Incorrect'} */}
                            </Button>
                        </Col>
                    </Row>
                    <h1>
                        {this.state.correctlyAnswered ? (
                            <div className='correct-answer-alert'>
                                <h1>Correct!!!</h1>
                            </div>
                        ) : this.state.correctlyAnswered == false ? (
                            <div className='wrong-answer-alert'>
                                <h1>Try again!</h1>
                            </div>
                        ) : null}
                    </h1>
                </div>
            </div>
        );
    }
}
