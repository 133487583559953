import React, { Component } from 'react';
import { Row, Modal } from 'react-bootstrap';

import { useSelector } from 'react-redux';
import './index.scss';
import { BsCardText, BsInfoCircle } from 'react-icons/bs';

export class Header extends Component {
    render() {
        // { title, transcript, handleShow, backgroundImage }, {
        return (
            <div className='header-card'>
                <Row
                    className='header'
                    style={{
                        backgroundColor: '#F5A623',
                        height: '250px',
                    }}>
                    <div className='header-text'>
                        <h1>Heading</h1>
                    </div>
                </Row>
                <Row
                    className='text'
                    style={{
                        height: '210px',
                    }}>
                    <span>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Aliquam at porttitor sem. Aliquam erat volutpat. Donec
                        placerat nisl magna, et faucibus arcu condimentum sed.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Aliquam at porttitor sem. Aliquam erat volutpat. Donec
                        placerat nisl magna, et faucibus arcu condimentum sed.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Aliquam at porttitor sem.
                    </span>
                </Row>
                <div className='icons'>
                    <span
                        style={{ width: '48px', height: '48px' }}
                        onClick={this.props.handleModalShowAndClose}>
                        <BsInfoCircle size={30} />
                    </span>{' '}
                    <span
                        style={{ width: '48px', height: '48px' }}
                        onClick={this.props.handleModalShowAndClose}>
                        <BsInfoCircle size={30} />
                    </span>{' '}
                    <span
                        style={{ width: '48px', height: '48px' }}
                        onClick={this.props.handleModalShowAndClose}>
                        <BsCardText size={30} />
                    </span>
                </div>
                <Modal
                    backdrop={false}
                    show={this.props.showModal}
                    onHide={this.props.handleModalShowAndClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Modal heading</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Woohoo, youre reading this text in a modal!
                    </Modal.Body>
                    <Modal.Footer></Modal.Footer>
                </Modal>
            </div>
        );
    }
}
