import './App.scss';
import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import MainLayout from './layouts/Main/Main';
import {
    Login,
    ResetPassword,
    ChangePassword,
    Profile,
    VerifyAccount,
} from './pages/Account';
import { Home } from './pages/Home';
import { Admin } from './pages/Admin';
import { Notifications } from './pages/Notifications';
import { User } from './pages/User';
import { Policy } from './pages/Policy';

export default class App extends Component {
    render() {
        return (
            <Router>
                <div id='app'>
                    <MainLayout>
                        <Switch>
                            <Route exact path='/' component={Home} />
                            <Route exact path='/login' component={Login} />
                            <Route exact path='/profile' component={Profile} />
                            <Route
                                exact
                                path='/reset-password'
                                component={ResetPassword}
                            />
                            <Route
                                exact
                                path='/change-password'
                                component={ChangePassword}
                            />
                            <Route
                                exact
                                path='/verify-account'
                                component={VerifyAccount}
                            />
                            <Route path='/admin' component={Admin} />
                            <Route
                                exact
                                path='/notifications'
                                component={Notifications}
                            />
                            <Route path='/user' component={User} />
                            <Route path='/terms_of_service'>
                                <Policy
                                    keyword='terms_of_service'
                                    title='Terms Of Service'
                                />
                            </Route>
                            <Route path='/privacy_policy'>
                                <Policy
                                    keyword='privacy_policy'
                                    title='Privacy Policy'
                                />
                            </Route>
                        </Switch>
                    </MainLayout>
                </div>
            </Router>
        );
    }
}
