import React, { Component } from 'react';
import './CourseMenu.scss';
import { connect } from 'react-redux';
import MenuItems from './MenuItems';
import { VectorIcon } from '../../../assets/Vector';
import { Row, Container } from 'react-bootstrap';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import apiCall from '../../../helpers/apiCall';
class CourseMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            courseMenuOpen: false,
            lessons: [],
        };
    }

    async componentDidUpdate() {
        if (this.props.updateCoursePercentage.state == true) {
            this.props.setUpdateCoursePercentage(false);
            const { success: foundCurrentCourse, response: currentCourse } =
                await apiCall(
                    'GET',
                    `/users/courses/${this.props.currentCourse.state._id}`
                );
            if (foundCurrentCourse) {
                this.props.setCurrentCourse(currentCourse);
            }
        }
    }

    toggleCourseMenu = () => {
        const courseMenuOpen = this.state.courseMenuOpen;
        this.props.setCourseMenuOpen(!courseMenuOpen);
        this.setState({ courseMenuOpen: !courseMenuOpen });
    };

    render() {
        const courseMenuOpen = this.state.courseMenuOpen;
        const currentCourse = this.props.currentCourse;
        const pageLayout = this.props.currentLessonLayout.state == 'PAGE';

        let minutesSpent = 0;
        let hoursSpent = 0;
        let minutesTotal = 0;
        let hoursTotal = 0;

        if (currentCourse && currentCourse.state) {
            let currentProgress = currentCourse.state.courseProgress;
            let totalMinutes = currentCourse.state.totalMinutes;
            hoursSpent = Math.floor(currentProgress / 60);
            minutesSpent = currentProgress - hoursSpent * 60;

            hoursTotal = Math.floor(totalMinutes / 60);
            minutesTotal = totalMinutes - hoursTotal * 60;
        }

        if (!pageLayout && currentCourse.state) {
            return (
                <div className='course-menu'>
                    <nav
                        className={
                            courseMenuOpen
                                ? `student-side-menu active`
                                : 'student-side-menu'
                        }>
                        <div>
                            <div className='navbar'>
                                <div
                                    className={
                                        courseMenuOpen
                                            ? 'toggle active'
                                            : 'toggle'
                                    }>
                                    <div
                                        className='vector-icon-background'
                                        onClick={this.toggleCourseMenu}>
                                        <div
                                            className={
                                                courseMenuOpen
                                                    ? 'vector-icon active'
                                                    : 'vector-icon'
                                            }>
                                            <VectorIcon />
                                        </div>
                                    </div>
                                </div>
                                <span className='course-title'>
                                    {currentCourse.state.title}
                                </span>
                                <div className='sidebar-info'>
                                    <div className='completed'>
                                        <div
                                            style={{
                                                width: '48px',
                                                marginRight: '20px',
                                            }}
                                            className='student-course-progress-circle'>
                                            <CircularProgressbar
                                                value={
                                                    currentCourse.state
                                                        .percentageProgress
                                                }
                                                text={`${currentCourse.state.percentageProgress}%`}
                                            />{' '}
                                        </div>
                                        <div
                                            style={{
                                                fontSize: '14px',
                                            }}>
                                            {hoursSpent
                                                ? `${hoursSpent}h `
                                                : ''}
                                            {minutesSpent || !hoursSpent
                                                ? `${minutesSpent}m `
                                                : ''}
                                            of{' '}
                                            {hoursTotal
                                                ? `${hoursTotal}h `
                                                : ''}
                                            {minutesTotal || !hoursTotal
                                                ? `${minutesTotal}m `
                                                : ''}
                                            Completed
                                            <br />
                                            <strong>
                                                Expires in{' '}
                                                {currentCourse.state.daysLeft}{' '}
                                                days
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='student-side-menu-items'>
                                <MenuItems
                                    closeSideBar={this.toggleCourseMenu}
                                />
                            </div>
                        </div>
                    </nav>
                </div>
            );
        } else if (pageLayout && currentCourse.state) {
            return (
                <div className='course-menu'>
                    <nav
                        className={
                            courseMenuOpen
                                ? `student-side-page-menu active`
                                : `student-side-page-menu `
                        }>
                        <ul className='items-list'>
                            <div className='navbar'>
                                <div
                                    className={
                                        courseMenuOpen
                                            ? 'page-toggle active'
                                            : 'page-toggle'
                                    }>
                                    <div
                                        className='vector-icon-background'
                                        onClick={this.toggleCourseMenu}>
                                        <div
                                            className={
                                                courseMenuOpen
                                                    ? 'vector-icon active'
                                                    : 'vector-icon'
                                            }>
                                            <VectorIcon />
                                        </div>
                                    </div>
                                </div>
                                <span className='course-title'>
                                    {currentCourse.state.title}
                                </span>
                                <div className='sidebar-info'>
                                    <div className='completed'>
                                        <div
                                            style={{
                                                width: '48px',
                                                marginRight: '20px',
                                            }}
                                            className='student-course-progress-circle'>
                                            <CircularProgressbar
                                                value={
                                                    currentCourse.state
                                                        .percentageProgress
                                                }
                                                text={`${currentCourse.state.percentageProgress}%`}
                                            />{' '}
                                        </div>
                                        <div
                                            style={{
                                                fontSize: '14px',
                                            }}>
                                            {hoursSpent
                                                ? `${hoursSpent}h `
                                                : ''}
                                            {minutesSpent || !hoursSpent
                                                ? `${minutesSpent}m `
                                                : ''}
                                            of{' '}
                                            {hoursTotal
                                                ? `${hoursTotal}h `
                                                : ''}
                                            {minutesTotal || !hoursTotal
                                                ? `${minutesTotal}m `
                                                : ''}
                                            Completed
                                            <br />
                                            <strong>
                                                Expires in{' '}
                                                {currentCourse.state.daysLeft}{' '}
                                                days
                                            </strong>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='student-side-menu-items'>
                                <MenuItems
                                    closeSideBar={this.toggleCourseMenu}
                                />
                            </div>
                        </ul>
                    </nav>
                </div>
            );
        } else {
            return <div />;
        }
    }
}
const mapStateToProps = (state) => {
    // state argument is the entire redux store
    const {
        currentLesson,
        currentCourse,
        currentLessonLayout,
        updateCoursePercentage,
    } = state; // inside curly braces we have the name of the state we want
    return {
        currentLesson,
        currentCourse,
        currentLessonLayout,
        updateCoursePercentage,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCourseMenuOpen: (payload) => {
            dispatch({
                type: 'SET_COURSE_MENU_OPEN',
                payload,
            });
        },
        setUpdateCoursePercentage: (payload) => {
            dispatch({
                type: 'SET_UPDATE_COURSE_PERCENTAGE',
                payload,
            });
        },
        setCurrentCourse: (payload) => {
            dispatch({
                type: 'SET_CURRENT_COURSE',
                payload,
            });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseMenu);
