import React, { Component } from 'react';
import { ComponentTabs } from '../../../components/Tabs';
import PackageForm from './PackageForm';
import Vouchers from './Vouchers';
import Messages from './Messages';

export default class Package extends Component {
    constructor(props) {
        super(props);

        this.tabs = [{ tabTitle: 'Package', TabComponent: PackageForm }];

        if (props.match.params.id) {
            this.tabs = this.tabs.concat([
                { tabTitle: 'Vouchers', TabComponent: Vouchers },
                { tabTitle: 'Messages', TabComponent: Messages },
            ]);
        }
    }

    render() {
        return (
            <ComponentTabs
                tabs={this.tabs}
                docId={this.props.match.params.id}
            />
        );
    }
}
