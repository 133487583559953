import React, { Component } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import uuid from 'react-uuid';
import { Rnd } from 'react-rnd';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import './Certificate.scss';
import { FileUpload } from '../../../components/FileUpload';
import apiCall from '../../../helpers/apiCall';
import { Spinner } from '../../../components/Spinner';
import { FontAwesomeIcon as Fa } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';

class Certificate extends Component {
    state = {
        title: '',
        backgroundUrl: null,
        certificateFile: null,
        scale: 1,
        backgroundWidth: 0,
        backgroundHeight: 0,
        backgroundCenter: false,
        originalWidth: 0,
        originalHeight: 0,
        editorContent: '',
        blockCounter: 1,
        blocks: [],
        loading: true,
        preview: false,
        course: {},
    };

    worktableRef = React.createRef();

    handleCKEditorChange = (id, editor) => {
        this.setState({
            [`block_${id}_content`]: editor.getData(),
        });
    };

    async componentDidMount() {
        const { successCourse, response } = await apiCall(
            'GET',
            `/courses/${this.props.match.params.id}`
        );

        this.setState({
            course: response,
        });

        this.props.pushBreadcrumbLink({
            text: 'Courses',
            path: '/admin/courses',
        });
        this.props.pushBreadcrumbLink({
            text: `Course: ${this.state.course.title}`,
            path: `/admin/courses/${this.props.match.params.id}`,
        });

        const {
            response: { title, image, blocks },
            success,
            message,
        } = await apiCall(
            'GET',
            `/courses/${this.props.match.params.id}/certificate`
        );

        if (success) {
            this.calculateWorkTableData(image, title, blocks);
        } else {
            this.props.setGlobalAlert({
                type: 'error',
                message,
            });
        }
    }

    componentWillUnmount = () => {
        this.props.removeBreadcrumbLink({
            text: 'Courses',
            path: '/admin/courses',
        });
        this.props.removeBreadcrumbLink({
            text: `Course: ${this.state.course.title}`,
            path: `/admin/courses/${this.props.match.params.id}`,
        });
    };

    handleFileChange = (type, url, file) => {
        this.calculateWorkTableData(url);
    };

    calculateWorkTableData = (url, title = null, newBlocks = null) => {
        const img = new Image(),
            self = this;

        let backgroundHeight,
            backgroundWidth = parseInt(
                window
                    .getComputedStyle(this.worktableRef.current)
                    .getPropertyValue('width')
                    .slice(0, -2)
            );

        img.onload = function () {
            let vScale,
                hScale,
                backgroundCenter = false;

            if (this.width > backgroundWidth) {
                backgroundHeight = (this.height / this.width) * backgroundWidth;

                vScale = this.height / backgroundHeight;
                hScale = this.width / backgroundWidth;
            } else {
                backgroundWidth = this.width;
                backgroundHeight = this.height;
                backgroundCenter = true;
            }

            const newState = {
                backgroundWidth,
                backgroundHeight,
                backgroundCenter,
                backgroundUrl: url,
                originalWidth: this.width,
                originalHeight: this.height,
                scale: vScale ? Math.min(hScale, vScale) : 1,
                loading: false,
            };

            if (title) {
                newState.title = title;
            }

            if (newBlocks) {
                const blocks = [];

                for (const block of newBlocks) {
                    const id = uuid();

                    blocks.push(id);

                    for (const key of Object.keys(block)) {
                        newState[`block_${id}_${key}`] = block[key];
                    }
                }

                newState.blocks = blocks;
            }

            self.setState(newState);
        };

        img.src = url;
    };

    addCustomBlock = () => {
        const { blocks, blockCounter } = this.state,
            id = uuid();

        blocks.unshift(id);

        this.setState({
            blocks,
            [`block_${id}_key`]: `unique_${blockCounter}`,
            [`block_${id}_content`]: '',
            [`block_${id}_x`]: 0,
            [`block_${id}_y`]: 0,
            blockCounter: blockCounter + 1,
        });
    };

    removeCustomBlock = (id) => {
        const { blocks } = this.state;

        blocks.splice(id, 1);

        this.setState({
            blocks,
            [`block_${id}_key`]: undefined,
            [`block_${id}_content`]: undefined,
            [`block_${id}_x`]: undefined,
            [`block_${id}_y`]: undefined,
            [`block_${id}_width`]: undefined,
            [`block_${id}_height`]: undefined,
        });
    };

    setCustomBlockPosition = (id, { x, y }) => {
        const { scale } = this.state;
        this.setState({
            [`block_${id}_x`]: x * scale,
            [`block_${id}_y`]: y * scale,
        });
    };

    setCustomBlockSize = (id, ref, { x, y }) => {
        const { scale } = this.state,
            computedStyles = window.getComputedStyle(ref);

        this.setState({
            [`block_${id}_x`]: x * scale,
            [`block_${id}_y`]: y * scale,
            [`block_${id}_width`]:
                parseInt(
                    computedStyles.getPropertyValue('width').slice(0, -2)
                ) * scale,
            [`block_${id}_height`]:
                parseInt(
                    computedStyles.getPropertyValue('height').slice(0, -2)
                ) * scale,
        });
    };

    submitCertificateChanges = async () => {
        const blocks = this.state.blocks.map((id) => ({
                key: this.state[`block_${id}_key`],
                content: this.state[`block_${id}_content`],
                x: this.state[`block_${id}_x`],
                y: this.state[`block_${id}_y`],
                width: this.state[`block_${id}_width`],
                height: this.state[`block_${id}_height`],
            })),
            { success, message } = await apiCall(
                'PUT',
                `/courses/${this.props.match.params.id}/certificate`,
                {
                    title: this.state.title,
                    blocks,
                    image: 'https://i.imgur.com/Wwlyc6a.jpg',
                }
            );

        this.props.setGlobalAlert({
            type: success ? 'success' : 'error',
            message,
        });
    };

    handleInputChange = (e) => {
        const input = e.target;

        this.setState({
            [input.name]: input.value,
        });
    };

    toggleCertificatePreview = () => {
        this.setState({
            preview: !this.state.preview,
        });
    };

    render() {
        const { scale } = this.state;
        return this.state.loading ? (
            <div className='cb p-4' ref={this.worktableRef}>
                <Spinner />
            </div>
        ) : (
            <div className='cb p-4'>
                <div className='mb-4 d-flex justify-content-between'>
                    <Button
                        variant='primary'
                        onClick={this.submitCertificateChanges}>
                        Save
                    </Button>
                    <Button
                        variant='secondary'
                        onClick={this.toggleCertificatePreview}>
                        {this.state.preview ? 'Editing mode' : 'Preview mode'}
                    </Button>
                </div>
                <Row>
                    <Col>
                        <div className='cb__input'>
                            <label htmlFor='title'>Certificate title</label>
                            <input
                                type='text'
                                id='title'
                                name='title'
                                value={this.state.title}
                                onChange={this.handleInputChange}
                            />
                        </div>
                    </Col>
                    <Col>
                        <div className='cb__input cb__input--image-upload'>
                            <FileUpload
                                name='background'
                                url={this.state.backgroundUrl}
                                type='image'
                                label='Certificate background'
                                handleFileChange={this.handleFileChange}
                            />
                        </div>
                    </Col>
                </Row>
                <div
                    className='cb__worktable my-4'
                    ref={this.worktableRef}
                    style={{
                        backgroundImage: `url(${this.state.backgroundUrl})`,
                        height:
                            this.state.backgroundHeight === 0
                                ? 'auto'
                                : `${this.state.backgroundHeight}px`,
                        backgroundSize: `${this.state.backgroundWidth}px ${this.state.backgroundHeight}px`,
                        backgroundPosition: this.state.backgroundCenter
                            ? 'center'
                            : 'auto',
                    }}>
                    {!this.state.backgroundUrl ? (
                        <div className='cb__worktable__empty'>
                            Upload a certificate background to get started
                        </div>
                    ) : (
                        this.state.blocks.map((id) =>
                            this.state.preview ? (
                                <div
                                    style={{
                                        position: 'absolute',
                                        left:
                                            this.state[`block_${id}_x`] /
                                                scale +
                                            'px',
                                        top:
                                            this.state[`block_${id}_y`] /
                                                scale +
                                            'px',
                                        width:
                                            this.state[`block_${id}_width`] /
                                                scale +
                                            'px',
                                        height:
                                            this.state[`block_${id}_height`] /
                                                scale +
                                            'px',
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: this.state[
                                            `block_${id}_content`
                                        ],
                                    }}
                                />
                            ) : (
                                <Rnd
                                    key={id}
                                    default={{
                                        x: this.state[`block_${id}_x`] / scale,
                                        y: this.state[`block_${id}_y`] / scale,
                                        width:
                                            this.state[`block_${id}_width`] /
                                            scale,
                                        height:
                                            this.state[`block_${id}_height`] /
                                            scale,
                                    }}
                                    bounds='parent'
                                    className='cb__worktable__block'
                                    onDragStop={(e, d) => {
                                        this.setCustomBlockPosition(id, d);
                                    }}
                                    onResizeStop={(
                                        e,
                                        direction,
                                        ref,
                                        delta,
                                        position
                                    ) => {
                                        this.setCustomBlockSize(
                                            id,
                                            ref,
                                            position
                                        );
                                    }}>
                                    <span>{this.state[`block_${id}_key`]}</span>
                                </Rnd>
                            )
                        )
                    )}
                </div>
                <div className='cb__input'>
                    <div className='d-flex justify-content-between align-items-center mb-4'>
                        <label htmlFor='cb__blocks' className='m-0'>
                            Custom blocks
                        </label>
                        <Button
                            variant='outline-dark'
                            onClick={this.addCustomBlock}>
                            Add block
                        </Button>
                    </div>
                    <div id='cb__blocks'>
                        {this.state.blocks.map((id) => {
                            return (
                                <div className='cb__custom-block' key={id}>
                                    <div className='d-flex justify-content-between'>
                                        <input
                                            type='text'
                                            name={`block_${id}_key`}
                                            value={
                                                this.state[`block_${id}_key`]
                                            }
                                            onChange={this.handleInputChange}
                                        />
                                        <Button
                                            variant='link'
                                            size='sm'
                                            className='ml-2 mb-2'
                                            onClick={this.removeCustomBlock}>
                                            <Fa icon={faTrash} />
                                        </Button>
                                    </div>
                                    <CKEditor
                                        name='message'
                                        editor={Editor}
                                        onBlur={(e, editor) => {
                                            console.log(
                                                Array.from(
                                                    editor.ui.componentFactory.names()
                                                )
                                            );
                                        }}
                                        data={this.state[`block_${id}_content`]}
                                        onChange={(e, editor) => {
                                            this.handleCKEditorChange(
                                                id,
                                                editor
                                            );
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(null, {
    pushBreadcrumbLink: (payload) => ({
        type: 'PUSH_BREADCRUMB_LINK',
        payload,
    }),
    removeBreadcrumbLink: (payload) => ({
        type: 'REMOVE_BREADCRUMB_LINK',
        payload,
    }),
    setGlobalAlert: (payload) => ({
        type: 'SET_GLOBAL_ALERT',
        payload,
    }),
})(withRouter(Certificate));
