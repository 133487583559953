import { connect } from 'react-redux';

import Card from './Card';
import apiCall from '../../../helpers/apiCall';
import React, { Component } from 'react';
import Paypal from './Paypal';

class PurchasePackage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // name: props.name,
            // price: props.price,
            // productBy: 'Real Estate U',
            product: {
                name: 'itemname',
                price: 123,
                productBy: 'Real Estate U',
            },
        };
    }

    render() {
        return (
            <div>
                <Card />
                <Paypal />
            </div>
        );
    }
}

export default PurchasePackage;
