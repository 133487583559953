import React, { Component } from 'react';
import {
    BsCaretDownFill,
    BsCaretDown,
    BsCaretUpFill,
    BsCaretUp,
} from 'react-icons/bs';
import { Spinner } from 'react-bootstrap';
import './Table.scss';
import TableButton from './TableButton';
import uuid from 'react-uuid';

export default class Table extends Component {
    render() {
        const {
            rows,
            columns,
            emptyMessage,
            onSort,
            onSortReset,
            rowButtons,
            sortData,
            loading,
        } = this.props;

        if (!rows.length) {
            return (
                <div className='table color--light padding--double text--center font--medium'>
                    {emptyMessage ?? 'No records'}
                </div>
            );
        }
        return (
            <div className='table'>
                <div className='header'>
                    {columns.map((column) => {
                        return (
                            <div
                                key={column.field}
                                style={{
                                    minWidth: column.minWidth || 'auto',
                                    maxWidth: column.maxWidth || 'auto',
                                }}
                                onClick={
                                    !!column.sortKey && onSort
                                        ? async (e) => {
                                              await onSort(e, column.sortKey);
                                          }
                                        : undefined
                                }
                                onContextMenu={
                                    !!column.sortKey && onSortReset
                                        ? async (e) => {
                                              return await onSortReset(
                                                  e,
                                                  column.sortKey
                                              );
                                          }
                                        : undefined
                                }
                                className={`col${
                                    column.headClassName
                                        ? ' ' + column.headClassName
                                        : ''
                                }`}>
                                {column.text}

                                {!!column.sortKey && (
                                    <div className='col--sort'>
                                        {sortData[column.sortKey] ===
                                            undefined ||
                                        sortData[column.sortKey] === -1 ? (
                                            <BsCaretUp />
                                        ) : (
                                            <BsCaretUpFill />
                                        )}

                                        {sortData[column.sortKey] ===
                                            undefined ||
                                        sortData[column.sortKey] === 1 ? (
                                            <BsCaretDown />
                                        ) : (
                                            <BsCaretDownFill />
                                        )}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                    {rowButtons && rowButtons.length && (
                        <div
                            className='col col--controls'
                            style={{
                                minWidth:
                                    rowButtons.reduce((width, button) => {
                                        if (!button.icon) {
                                            width += button.text.length * 0.9;
                                        } else {
                                            width += 2.06;
                                        }

                                        return width;
                                    }, 0) +
                                    (rowButtons.length - 1) * 0.75 +
                                    1.3 +
                                    'rem',
                            }}
                        />
                    )}
                </div>
                <div className='main'>
                    <div className={`table-loading ${!loading ? ' hide' : ''}`}>
                        <Spinner animation='border' />
                    </div>
                    {rows.map((doc) => (
                        <div key={doc._id} className='row'>
                            {columns.map((column, i) => (
                                <div
                                    key={`${doc._id}-${i}`}
                                    style={{
                                        minWidth: column.minWidth || 'auto',
                                        maxWidth: column.maxWidth || 'auto',
                                    }}
                                    className={`col${
                                        column.className
                                            ? ' ' + column.className
                                            : ''
                                    }`}>
                                    {typeof column.field === 'function'
                                        ? column.field(doc)
                                        : doc[column.field]}
                                </div>
                            ))}
                            {rowButtons?.length && (
                                <div
                                    className='col col--controls'
                                    style={{
                                        minWidth:
                                            rowButtons.reduce(
                                                (width, button) => {
                                                    if (!button.icon) {
                                                        width +=
                                                            button.text.length *
                                                            0.9;
                                                    } else {
                                                        width += 2.06;
                                                    }

                                                    return width;
                                                },
                                                0
                                            ) +
                                            (rowButtons.length - 1) * 0.75 +
                                            1.3 +
                                            'rem',
                                    }}>
                                    {rowButtons.map((button) => (
                                        <TableButton
                                            {...button}
                                            doc={doc}
                                            key={`${doc._id}-${uuid()}`}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
