import React, { useEffect } from 'react';
import { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import apiCall from '../../../helpers/apiCall';

const Paypal = (props) => {
    const loggedIn = useSelector((state) => state.loggedIn);
    console.log(loggedIn);
    const [invoiceId, setInvoiceId] = useState();
    const [isSending, setIsSending] = useState(false);
    const history = useHistory();

    const [product, setProduct] = useState({
        name: 'React from FB',
        price: 10,
        productBy: 'facebook',
    });

    const makePayment = useCallback(async () => {
        console.log('Creating invoice');

        if (isSending) return;
        // update state
        setIsSending(true);
        console.log(isSending);

        const authToken = localStorage.getItem('authToken');

        const { response, success } = await apiCall(
            'POST',
            '/users/invoices',
            {
                userId: loggedIn.user._id,
                packageIds: ['606193575ceac9002e6a9c82'], // need to check if package is bought already on BE
            },
            authToken
        );
        console.log(response);

        if (success) {
            setInvoiceId(response._id);
            setIsSending(false);
        }
    });

    const createPayment = useCallback(async () => {
        console.log('Creating Payment');
        if (invoiceId) {
            console.log(invoiceId);
            // this prolly needs to have some auth middlewares implemented in the backend
            const { response, success, message } = await apiCall(
                'POST',
                '/paypal',
                {
                    userId: loggedIn.user._id,
                    packageId: '5feb0cc60043f7de3fdcbf02',
                    invoiceId: invoiceId,
                }
            );

            if (success) {
                window.location = response.forwardLink;
            } else {
                console.log(message);
            }
        }
    });

    useEffect(() => {
        createPayment();
    }, [invoiceId]);

    return (
        <div
            style={{
                width: '126px',
                marginTop: '30px',
                marginBottom: '10px',
            }}>
            <button
                style={{ height: '32px' }}
                className='cardButton'
                onClick={() => {
                    makePayment();
                }}>
                Pay with paypal
            </button>
        </div>
    );
};

export default Paypal;
