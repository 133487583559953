import React from 'react';
import { Modal } from 'react-bootstrap';

export const RequiredTimeShield = ({
    getNextLesson,
    showHideRequireTimeShield,
    requiredTimeShield,
}) => {
    return (
        <Modal
            size='lg'
            show={requiredTimeShield}
            onHide={() => showHideRequireTimeShield()}>
            <Modal.Header closeButton>
                <Modal.Title>Current lesson not completed</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    You will need to stay for the required time to mark this
                    lesson as completed. Would you like to finish the required
                    time for this lesson?
                </p>
            </Modal.Body>
            <Modal.Footer>
                <button className='bd' onClick={() => getNextLesson()}>
                    No, I will come back later.
                </button>
                <button
                    className='bp'
                    onClick={() => showHideRequireTimeShield()}>
                    Yes, I will complete the lesson now.
                </button>
            </Modal.Footer>
        </Modal>
    );
};
