import React, { Component } from 'react';
import { PackageCard } from '../../components/User/PackageCards/PackageCard';
import { CourseCard } from '../../components/User/CourseCards/CourseCard';

import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import apiCall from '../../helpers/apiCall';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class Home extends Component {
    _isMounted = false; //check if component is alive/exists
    constructor(props) {
        super(props);
        this.state = {
            packages: [],
            courses: [],
            showCourses: false,
            bundles: [],
            singles: [],
        };
    }
    async componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            const userId = this.props.loggedIn.user._id;
            const { success, response } = await apiCall(
                'GET',
                `/users/${userId}/packages`
            );
            if (success) {
                this.setState({ packages: response.docs });
            }
            console.log(response);

            response.docs.map(async (p) => {
                const { success, response } = await apiCall(
                    'GET',
                    `/users/packages/${p._id}/courses`
                );
                if (success) {
                    if (response.courses.length == 1) {
                        this.setState((prevState) => ({
                            singles: [
                                ...prevState.singles,
                                response.courses[0],
                            ],
                        }));
                    } else if (response.courses.length > 1) {
                        console.log(response);
                        this.setState((prevState) => ({
                            bundles: [...prevState.bundles, p],
                        }));
                        console.log(this.state.bundles);
                    }
                }
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async getCourses(packageId) {
        const { success, response } = await apiCall(
            'GET',
            `/users/packages/${packageId}/courses`
        );
        if (success) {
            this.setState({ courses: response.courses });
        }
        this.setState({ showCourses: true });
    }

    handleClose() {
        this.setState({ showCourses: false });
    }

    handleShow() {
        this.setState({ showCourses: true });
    }

    render() {
        const courses = this.state.courses;
        if (this.state.packages && this.state.packages[0]) {
            return (
                <div className='rw'>
                    <div className='rr'>
                        {this.state.singles.map((course) => (
                            <div
                                key={course._id}
                                className='rcx12 rcs6 rcm4 rcl3'>
                                <CourseCard
                                    image={course.image}
                                    title={course.title}
                                    description={course.description}
                                    course={course}
                                />
                            </div>
                        ))}
                        {this.state.bundles.map((bundle) => (
                            <div
                                key={bundle._id}
                                className='rcx12 rcs6 rcm4 rcl3'>
                                <PackageCard
                                    image={bundle.package.image}
                                    title={bundle.package.title}
                                    description={bundle.package.description}
                                    getCourses={() =>
                                        this.getCourses(bundle._id)
                                    }
                                />
                            </div>
                        ))}

                        <Modal
                            size='lg'
                            show={this.state.showCourses}
                            onHide={() => this.handleClose()}>
                            <Modal.Header closeButton>
                                <Modal.Title>Courses</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {courses.length > 0 ? (
                                    <div>
                                        {courses.map((course) => (
                                            <div
                                                key={course._id}
                                                className='rcx12 rcs6 rcm4'>
                                                <CourseCard
                                                    image={course.image}
                                                    title={course.title}
                                                    description={
                                                        course.description
                                                    }
                                                    course={course}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ) : null}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant='secondary'
                                    onClick={() => this.handleClose()}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>
                </div>
            );
        } else {
            return <div />;
        }
    }
}

const mapStateToProps = (state) => {
    // state argument is the entire redux store
    const { loggedIn } = state; // inside curly braces we have the name of the state we want
    return {
        loggedIn,
    };
};
export default withRouter(connect(mapStateToProps)(Home));
