import React from 'react';
import {
    BsFillSkipEndFill,
    BsFillSkipStartFill,
    BsFillPauseFill,
    BsPlayFill,
} from 'react-icons/bs';
import { connect } from 'react-redux';
import {
    setUserInactive,
    setLastSeen,
} from '../../../store/actions/userActions';

class Player extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            playing: false,
            userInactive: false,
        };
        this.url = this.props.audio;
        this.audio = new Audio(this.url);
        this.audio.currentTime = 20;
    }

    componentDidUpdate(prevProps) {
        if (this.props.userInactive.state != this.state.userInactive) {
            this.setState({ userInactive: this.props.userInactive.state });
            if (
                this.props.userInactive.state &&
                this.props.currentCourse.state.timerPause
            ) {
                this.pause();
            }
        }
    }

    pause = () => {
        this.setState({ playing: false });
        this.props.pauseAudio();
    };

    play = () => {
        this.setState({ playing: true });
        this.props.playAudio();
    };

    render() {
        if (this.state.playing) {
            return (
                <BsFillPauseFill
                    className='audio-toggle'
                    onClick={() => this.pause()}
                    size={30}
                />
            );
        } else {
            return (
                <BsPlayFill
                    className='audio-toggle'
                    onClick={() => this.play()}
                    size={30}
                />
            );
        }
    }
}

const mapStateToProps = (state) => {
    const { userInactive, currentCourse } = state;
    return {
        userInactive,
        currentCourse,
    };
};

const mapDispatchToProps = {
    setUserInactive,
    setLastSeen,
};

export default connect(mapStateToProps, mapDispatchToProps)(Player);
