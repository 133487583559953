import React, { Component } from 'react';

import ChaptersDropwdowns from '../../../components/User/ChapterDropdowns/ChaptersDropdowns';
// import { getLessons } from '../../../helpers/getLessons';
import apiCall from '../../../helpers/apiCall';
import { connect } from 'react-redux';
import CourseInformation from '../../../components/User/CourseInformation/CourseInformation';
import CourseMenu from '../../../components/User/CourseMenu/CourseMenu';
import CourseProgress from '../../../components/User/CourseProgress/CourseProgress';
import { Spinner } from '../../../components/Spinner';
// WILL ADD INDEX.JS FILES TOO ALL
class UserChapters extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            chapters: [],
        };
    }

    async componentDidMount() {
        this.props.setShowCourseMenu(true);

        this._isMounted = true;
        if (this._isMounted) {
            this.props.setAllChapters({}); // we want to reset all chapters and lessons from store
            this.props.setCurrentLessonLayout('');
            this.props.setCourseMenuOpen(false);
            const { courseId } = this.props.match.params;
            const { success, response, message } = await apiCall(
                'GET',
                `/users/courses/${courseId}/chapters`
            );
            const { success: foundCurrentCourse, response: currentCourse } =
                await apiCall('GET', `/users/courses/${courseId}`);
            if (foundCurrentCourse) {
                this.props.setCurrentCourse(currentCourse);
                console.log(this.props.currentCourse);
            }
            if (success) {
                this.props.setAllChapters(response.chapters);
            }
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        if (this.props.allChapters.state && this.props.allChapters.state[0]) {
            return (
                <div className='user-chapters'>
                    {/* <CourseProgress /> */}
                    <CourseInformation />
                    <ChaptersDropwdowns />
                </div>
            );
        } else {
            return (
                <div>
                    <Spinner />
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => {
    // state argument is the entire redux store
    const {
        allLessons,
        currentLesson,
        allChapters,
        currentLessonLayout,
        currentCourse,
    } = state; // inside curly braces we have the name of the state we want
    return {
        allLessons,
        currentLesson,
        allChapters,
        currentLessonLayout,
        currentCourse,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCourseMenuOpen: (payload) => {
            dispatch({
                type: 'SET_COURSE_MENU_OPEN',
                payload,
            });
        },
        setShowCourseMenu: (payload) => {
            dispatch({
                type: 'SET_SHOW_COURSE_MENU',
                payload,
            });
        },
        setAllChapters: (payload) => {
            dispatch({
                type: 'SET_ALL_CHAPTERS',
                payload,
            });
        },
        setCurrentLessonLayout: (payload) => {
            dispatch({
                type: 'SET_CURRENT_LESSON_LAYOUT',
                payload,
            });
        },
        setCurrentCourse: (payload) => {
            dispatch({
                type: 'SET_CURRENT_COURSE',
                payload,
            });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserChapters);
