import React, { Component } from 'react';
import {
    Card,
    Button,
    Row,
    Col,
    Image,
    Container,
    ResponsiveEmbed,
    Input,
} from 'react-bootstrap';
import './index.scss';

export class TrueFalseFourQuestions extends Component {
    constructor(props) {
        super(props);

        this.state = {
            correctAnswer: false,
            answer: null,
            question: '', // don't know how this quiz will work yet so should leave blank for now.
            correctlyAnswered: null,
        };
    }
    checkAnswer(answer) {
        if (answer == this.state.correctAnswer) {
            this.setState({ correctlyAnswered: true, answer: answer });
        } else {
            this.setState({ correctlyAnswered: false, answer: answer });
        }
    }
    render() {
        const { correctAnswer, correctlyAnswered } = this.state;
        return (
            <div className='true-false-four-questions-quiz'>
                <div className='true-false-four-questions'>
                    <div className='header' style={{ display: 'block' }}>
                        <span>heading</span>
                    </div>
                    <div className='true-false-one-question'>
                        <Row style={{ marginBottom: '20px' }}>
                            <Col lg='5'>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Aliquam at porttitor sem
                            </Col>
                            <Col>
                                <Button
                                    className='true-false-buttons'
                                    onClick={() => this.checkAnswer(true)}>
                                    True
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    className='true-false-buttons'
                                    onClick={() => this.checkAnswer(false)}>
                                    False
                                </Button>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '20px' }}>
                            <Col lg='5'>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Aliquam at porttitor sem
                            </Col>
                            <Col>
                                <Button
                                    className='true-false-buttons'
                                    onClick={() => this.checkAnswer(true)}>
                                    True
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    className='true-false-buttons'
                                    onClick={() => this.checkAnswer(false)}>
                                    False
                                </Button>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '20px' }}>
                            <Col lg='5'>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Aliquam at porttitor sem
                            </Col>
                            <Col>
                                <Button
                                    className='true-false-buttons'
                                    onClick={() => this.checkAnswer(true)}>
                                    True
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    className='true-false-buttons'
                                    onClick={() => this.checkAnswer(false)}>
                                    False
                                </Button>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: '20px' }}>
                            <Col lg='5'>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit. Aliquam at porttitor sem
                            </Col>
                            <Col>
                                <Button
                                    className='true-false-buttons'
                                    onClick={() => this.checkAnswer(true)}>
                                    True
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    className='true-false-buttons'
                                    onClick={() => this.checkAnswer(false)}>
                                    False
                                </Button>
                            </Col>
                        </Row>
                        <h1>
                            {this.state.correctlyAnswered ? (
                                <div className='correct-answer-alert'>
                                    <h1>Correct!!!</h1>
                                </div>
                            ) : this.state.correctlyAnswered == false ? (
                                <div className='wrong-answer-alert'>
                                    <h1>Try again!</h1>
                                </div>
                            ) : null}
                        </h1>
                    </div>
                </div>
            </div>
        );
    }
}
