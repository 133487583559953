import React, { Component } from 'react';
import './Accordion.scss';
import { connect } from 'react-redux';
import AccordionItems from './AccordionItems';

class ChaptersAccordion extends Component {
    constructor(props) {
        super(props);
        this.ref = React.createRef();
    }
    componentDidMount() {
        if (this.props.currentChapter.state._id == this.props.chapter._id) {
            this.ref = React.createRef();
        }
    }

    handleClick = (ref) => {
        if (this.props.chapter._id == this.props.currentChapter.state._id) {
            this.ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    };

    componentDidUpdate(prevProps) {
        if (prevProps.currentChapter != this.props.currentChapter) {
            if (this.props.chapter._id == this.props.currentChapter.state._id) {
                this.handleClick(this.ref);
            }
        }
    }

    render() {
        return (
            <div ref={this.ref}>
                <AccordionItems
                    withBorder={this.props.withBorder}
                    title={this.props.title}
                    description={this.props.description}
                    complete={this.props.complete}
                    current={this.props.current}
                    inComplete={this.props.inComplete}
                    chapter={this.props.chapter}
                    courseId={this.props.courseId}>
                    {this.props.children}
                </AccordionItems>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    const { currentChapter } = state;
    return {
        currentChapter,
    };
};
export default connect(mapStateToProps)(ChaptersAccordion);
