import React, { Component } from 'react';
import './MobileNavigation.scss';
import { BiCog, BiBell, BiDoorOpen } from 'react-icons/bi';
import { RiBookletLine, RiLogoutBoxRLine } from 'react-icons/ri';
import { FaUserCircle } from 'react-icons/fa';

class MobileNavigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    componentDidMount() {}

    handleToggle() {
        this.setState({ open: !this.state.open });
    }

    render() {
        return (
            <div className='mobile-navigation'>
                <div
                    className='hamburger-icon'
                    onClick={() => this.handleToggle()}>
                    <div className='bar1'></div>
                    <div className='bar2'></div>
                    <div className='bar3'></div>
                </div>
                <div
                    className={`mobile-navbar ${
                        this.state.open ? 'active' : ''
                    }`}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

// MobileNavigation.propTypes = {};

export default MobileNavigation;
