import React, { Component } from 'react';
import Enrolment from './Enrolment';
import PreExam from './PreExam';
import ExamTable from './ExamTable';
import PostExam from './PostExam';
import Messages from './Messages';
import Certificate from './Certificate';
import { Nav } from 'react-bootstrap';
import { Link, Route, Switch, withRouter } from 'react-router-dom';
import CourseForm from './CourseForm';
import ExamForm from './ExamForm';
import { connect } from 'react-redux';

class Course extends Component {
    render() {
        return (
            <div id='course'>
                {this.props.location.pathname.indexOf('/admin/courses/ext/') <
                0 ? (
                    <Nav
                        variant='tabs'
                        activeKey={this.props.location.pathname}>
                        <Nav.Item key='course'>
                            <Nav.Link
                                as={Link}
                                eventKey={`/admin/courses/${this.props.match.params.id}`}
                                to={`/admin/courses/${this.props.match.params.id}`}>
                                Course
                            </Nav.Link>
                        </Nav.Item>
                        {this.props.match.params.id !== 'new' && [
                            <Nav.Item key='enrollment'>
                                <Nav.Link
                                    as={Link}
                                    eventKey={`/admin/courses/${this.props.match.params.id}/enrollment`}
                                    to={`/admin/courses/${this.props.match.params.id}/enrollment`}>
                                    Enrollment
                                </Nav.Link>
                            </Nav.Item>,
                            <Nav.Item key='pre-exam'>
                                <Nav.Link
                                    as={Link}
                                    eventKey={`/admin/courses/${this.props.match.params.id}/pre-exam`}
                                    to={`/admin/courses/${this.props.match.params.id}/pre-exam`}>
                                    Pre Exam
                                </Nav.Link>
                            </Nav.Item>,
                            <Nav.Item key='exam'>
                                <Nav.Link
                                    as={Link}
                                    eventKey={`/admin/courses/${this.props.match.params.id}/exam`}
                                    to={`/admin/courses/${this.props.match.params.id}/exam`}>
                                    Exam
                                </Nav.Link>
                            </Nav.Item>,
                            <Nav.Item key='post-exam'>
                                <Nav.Link
                                    as={Link}
                                    eventKey={`/admin/courses/${this.props.match.params.id}/post-exam`}
                                    to={`/admin/courses/${this.props.match.params.id}/post-exam`}>
                                    Post Exam
                                </Nav.Link>
                            </Nav.Item>,
                            <Nav.Item key='messages'>
                                <Nav.Link
                                    as={Link}
                                    eventKey={`/admin/courses/${this.props.match.params.id}/messages`}
                                    to={`/admin/courses/${this.props.match.params.id}/messages`}>
                                    Messages
                                </Nav.Link>
                            </Nav.Item>,
                            <Nav.Item key='certificate'>
                                <Nav.Link
                                    as={Link}
                                    eventKey={`/admin/courses/${this.props.match.params.id}/certificate`}
                                    to={`/admin/courses/${this.props.match.params.id}/certificate`}>
                                    Certificate
                                </Nav.Link>
                            </Nav.Item>,
                        ]}
                    </Nav>
                ) : (
                    <></>
                )}
                <div>
                    <Switch>
                        <Route exact path='/admin/courses/:id'>
                            <CourseForm />
                        </Route>
                        <Route exact path='/admin/courses/:id/enrollment'>
                            <Enrolment />
                        </Route>
                        <Route exact path='/admin/courses/:id/pre-exam'>
                            <PreExam />
                        </Route>
                        <Route exact path='/admin/courses/:id/exam'>
                            <ExamTable />
                        </Route>
                        <Route exact path='/admin/courses/:id/post-exam'>
                            <PostExam />
                        </Route>
                        <Route exact path='/admin/courses/:id/messages'>
                            <Messages />
                        </Route>
                        <Route exact path='/admin/courses/:id/certificate'>
                            <Certificate />
                        </Route>
                        <Route exact path='/admin/courses/ext/exams/edit/:id'>
                            <ExamForm key='exam-edit' />
                        </Route>
                        <Route
                            exact
                            path='/admin/courses/ext/:parentId/exams/create'>
                            <ExamForm key='exam-create' />
                        </Route>
                        <Route exact path='/admin/courses/ext/quizzes/edit/:id'>
                            <ExamForm key='quiz-edit' />
                        </Route>
                        <Route
                            exact
                            path='/admin/courses/ext/chapters/:parentId/quiz/create'>
                            <ExamForm key='quiz-create' />
                        </Route>
                    </Switch>
                </div>
            </div>
        );
    }
}

export default connect(null, {
    setCoursePreview: (payload) => ({
        type: 'SET_PREVIEW_COURSE',
        payload,
    }),
})(withRouter(Course));
