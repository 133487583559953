import React, { Component, createRef } from 'react';
import { Card, Image } from 'react-bootstrap';
import ReactPlayer from 'react-player';
import './index.scss';
import DocumentViewer from '../../DocumentViewer';
import { EndOfChapterQuiz } from '../EndOfChapterQuiz';
import AudioLarge from '../../AudioLarge';
import VideoPlayer from '../../VideoPlayer';
import { SingleSelect } from '../MicroQuizzes/SingleSelect';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import CardSelector from '../../LessonSlider/CardSelector';

export class PageLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cards: [],
            showQuiz: false,
        };
    }

    async componentDidMount() {
        if (this.props.cards.length > 0) {
            this.setState({ cards: this.props.cards });
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.cards != this.props.cards) {
            this.setState({ cards: this.props.cards });
        }
    }

    showEndOfChapterQuiz() {
        this.setState({ showQuiz: true });
    }

    hideEndOfChapterQuiz() {
        this.setState({ showQuiz: false });
    }

    render() {
        const { cards } = this.state;

        if (cards.length > 0) {
            {
                return (
                    <div>
                        {cards.map((card) => (
                            <div key={card._id} className='page-layout'>
                                {card.cardType.includes('HEADING') ? (
                                    <div className='card-heading'>
                                        <Card.Body
                                            style={{
                                                backgroundColor: card.bgColor,
                                                color: card.fgColor,
                                                textAlign: 'center',
                                            }}>
                                            <h2 style={{ margin: 'auto' }}>
                                                {card.heading}
                                            </h2>
                                        </Card.Body>
                                        {card.content.length > 0 ? (
                                            <Card.Body>
                                                <div>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: card.content,
                                                        }}
                                                    />
                                                </div>
                                            </Card.Body>
                                        ) : null}
                                        {card.info.length > 0 ? (
                                            <Card.Body>
                                                <div
                                                    style={{
                                                        overflow: 'auto',
                                                        maxHeight: '300px',
                                                    }}>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: card.info,
                                                        }}
                                                    />
                                                </div>
                                            </Card.Body>
                                        ) : null}
                                    </div>
                                ) : card.cardType == 'TEXT' ? (
                                    <div className='card-text'>
                                        <Card.Body>
                                            <h2 style={{ margin: 'auto' }}>
                                                {card.heading}
                                            </h2>
                                        </Card.Body>
                                        {card.content.length > 0 ? (
                                            <Card.Body>
                                                <div>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: card.content,
                                                        }}
                                                    />
                                                </div>
                                            </Card.Body>
                                        ) : null}
                                        {card.info.length > 0 ? (
                                            <Card.Body>
                                                <div
                                                    style={{
                                                        overflow: 'auto',
                                                        maxHeight: '300px',
                                                    }}>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: card.info,
                                                        }}
                                                    />
                                                </div>
                                            </Card.Body>
                                        ) : null}
                                    </div>
                                ) : card.cardType == 'VIDEO' ? (
                                    <div className='card-video'>
                                        <Card.Body>
                                            <h2 style={{ margin: 'auto' }}>
                                                {card.heading}
                                            </h2>
                                        </Card.Body>
                                        {card.subHeading.length > 0 ? (
                                            <Card.Body>
                                                <h3>{card.subHeading}</h3>
                                            </Card.Body>
                                        ) : null}
                                        {card.sourceVideo.length > 0 ? (
                                            <Card.Body>
                                                <div className='videoWrapper'>
                                                    <VideoPlayer
                                                        sourceVideo={
                                                            card.sourceVideo
                                                        }
                                                    />
                                                </div>
                                            </Card.Body>
                                        ) : null}
                                        {card.transcript.length > 0 ? (
                                            <Card.Body>
                                                <div className='transcript'>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: card.transcript,
                                                        }}
                                                    />
                                                </div>
                                            </Card.Body>
                                        ) : null}
                                        {card.info.length > 0 ? (
                                            <Card.Body>
                                                <div
                                                    style={{
                                                        overflow: 'auto',
                                                        maxHeight: '300px',
                                                    }}>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: card.info,
                                                        }}
                                                    />
                                                </div>
                                            </Card.Body>
                                        ) : null}
                                    </div>
                                ) : card.cardType.includes('IMAGE') ? (
                                    <div className='card-image'>
                                        <Card.Body>
                                            <h2 style={{ margin: 'auto' }}>
                                                {card.heading}
                                            </h2>
                                        </Card.Body>
                                        {card.content.length > 0 ? (
                                            <Card.Body>
                                                <div>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: card.content,
                                                        }}
                                                    />
                                                </div>
                                            </Card.Body>
                                        ) : null}
                                        <Card.Body>
                                            <Image
                                                src={card.sourceImage}
                                                fluid
                                            />
                                        </Card.Body>
                                        {card.info.length > 0 ? (
                                            <Card.Body>
                                                <div
                                                    style={{
                                                        overflow: 'auto',
                                                        maxHeight: '300px',
                                                    }}>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: card.info,
                                                        }}
                                                    />
                                                </div>
                                            </Card.Body>
                                        ) : null}
                                    </div>
                                ) : card.cardType == 'DOCUMENT' &&
                                  card.sourceDocument.includes(
                                      'https://reulms-backend.azurewebsites.net'
                                  ) ? (
                                    <div className='card-document'>
                                        <Card.Body>
                                            <h2 style={{ margin: 'auto' }}>
                                                {card.heading}
                                            </h2>
                                        </Card.Body>
                                        {card.content.length > 0 ? (
                                            <Card.Body>
                                                <div>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: card.content,
                                                        }}
                                                    />
                                                </div>
                                            </Card.Body>
                                        ) : null}
                                        <Card.Body>
                                            <DocumentViewer
                                                document={card.sourceDocument}
                                            />
                                        </Card.Body>
                                        {card.info.length > 0 ? (
                                            <Card.Body>
                                                <div
                                                    style={{
                                                        overflow: 'auto',
                                                        maxHeight: '300px',
                                                    }}>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: card.info,
                                                        }}
                                                    />
                                                </div>
                                            </Card.Body>
                                        ) : null}
                                    </div>
                                ) : card.cardType == 'AUDIO_BIG' ? (
                                    <div className='card-audio-big'>
                                        <Card.Body>
                                            <h2 style={{ margin: 'auto' }}>
                                                {card.heading}
                                            </h2>
                                        </Card.Body>
                                        {card.subHeading.length > 0 ? (
                                            <Card.Body>
                                                <h3>{card.subHeading}</h3>
                                            </Card.Body>
                                        ) : null}
                                        {card.sourceAudio.length > 0 ? (
                                            <Card.Body>
                                                <AudioLarge
                                                    large={true}
                                                    audio={card.sourceAudio}
                                                    heading={card.heading}
                                                    subHeading={card.subHeading}
                                                />
                                            </Card.Body>
                                        ) : null}
                                        {card.transcript.length > 0 ? (
                                            <Card.Body>
                                                <div className='transcript'>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: card.transcript,
                                                        }}
                                                    />
                                                </div>
                                            </Card.Body>
                                        ) : null}
                                        {card.info.length > 0 ? (
                                            <Card.Body>
                                                <div
                                                    style={{
                                                        overflow: 'auto',
                                                        maxHeight: '300px',
                                                    }}>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: card.info,
                                                        }}
                                                    />
                                                </div>
                                            </Card.Body>
                                        ) : null}
                                    </div>
                                ) : card.cardType == 'AUDIO' ? (
                                    <div className='card-audio'>
                                        <Card.Body>
                                            <h2 style={{ margin: 'auto' }}>
                                                {card.heading}
                                            </h2>
                                        </Card.Body>
                                        {card.subHeading.length > 0 ? (
                                            <Card.Body>
                                                <h3>{card.subHeading}</h3>
                                            </Card.Body>
                                        ) : null}
                                        {card.sourceAudio.length > 0 ? (
                                            <Card.Body>
                                                <AudioPlayer
                                                    src={card.sourceAudio}
                                                />
                                            </Card.Body>
                                        ) : null}
                                        {card.transcript.length > 0 ? (
                                            <Card.Body>
                                                <div className='transcript'>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: card.transcript,
                                                        }}
                                                    />
                                                </div>
                                            </Card.Body>
                                        ) : null}
                                        {card.info.length > 0 ? (
                                            <Card.Body>
                                                <div
                                                    style={{
                                                        overflow: 'auto',
                                                        maxHeight: '300px',
                                                    }}>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: card.info,
                                                        }}
                                                    />
                                                </div>
                                            </Card.Body>
                                        ) : null}
                                    </div>
                                ) : card.cardType.includes(
                                      'SINGLE_QUESTION'
                                  ) ? (
                                    <Card.Body>
                                        <SingleSelect />
                                    </Card.Body>
                                ) : card.cardType.includes('QUIZ') ? (
                                    <div>
                                        <EndOfChapterQuiz />
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        ))}
                    </div>
                );
            }
        } else {
            return <div>no cards</div>;
        }
    }
}
