import './Admin.scss';
import React, { Component } from 'react';
import { Menu, MenuItem } from '../components/Menu';
import {
    faCogs,
    faBolt,
    faChartBar,
    faEnvelope,
    faFileAlt,
    faGraduationCap,
    faShoppingCart,
    faFileInvoiceDollar,
    faTachometerAlt,
    faTasks,
    faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { Alert } from 'react-bootstrap';
import apiCall from '../helpers/apiCall';

class Admin extends Component {
    resendEmail = async () => {
        const { success, message } = await apiCall(
            'GET',
            '/users/verify/resend'
        );

        this.props.setGlobalAlert({
            type: success ? 'success' : 'error',
            message,
        });
    };

    render() {
        return (
            <div className={'admin-layout'}>
                <Menu basePath='/admin'>
                    <MenuItem to='/dashboard' icon={faTachometerAlt}>
                        Dashboard
                    </MenuItem>
                    <MenuItem to='/settings' icon={faCogs}>
                        Settings
                    </MenuItem>
                    <MenuItem to='/sessions' icon={faBolt}>
                        Sessions
                    </MenuItem>
                    <MenuItem to='/messages' icon={faEnvelope}>
                        Messages
                    </MenuItem>
                    <MenuItem to='/users' icon={faUsers}>
                        Users
                    </MenuItem>
                    <MenuItem to='/invoices' icon={faFileInvoiceDollar}>
                        Invoices
                    </MenuItem>
                    <MenuItem to='/packages' icon={faShoppingCart}>
                        Packages
                    </MenuItem>
                    <MenuItem to='/courses' icon={faGraduationCap}>
                        Courses
                    </MenuItem>
                    <MenuItem to='/core-libraries' icon={faFileAlt}>
                        Core libraries
                    </MenuItem>
                    <MenuItem to='/quizzes' icon={faTasks}>
                        Quizzes
                    </MenuItem>
                    <MenuItem to='/reports' icon={faChartBar}>
                        Reports
                    </MenuItem>
                </Menu>
                <main>
                    {!this.props.loggedIn?.user?.verified && (
                        <div className='verify-email'>
                            <Alert variant='danger'>
                                <Alert.Heading>
                                    Verify your e-mail address
                                </Alert.Heading>
                                <p>
                                    We&apos;ve an email to the address provided
                                    by you in the registration process to
                                    confirm your e-mail address.
                                    <br />
                                    Did get any e-mail? Be sure to check spam or
                                    junk folders or{' '}
                                    <b onClick={this.resendEmail}>
                                        click here to resend e-mail
                                        verification.
                                    </b>
                                </p>
                            </Alert>
                        </div>
                    )}
                    <div className='admin-wrapper'>{this.props.children}</div>
                </main>
            </div>
        );
    }
}

export default connect(
    (state) => {
        return {
            breadcrumbList: state.globalBreadcrumb,
            loggedIn: state.loggedIn,
        };
    },
    {
        setGlobalAlert: (payload) => ({
            type: 'SET_GLOBAL_ALERT',
            payload,
        }),
    }
)(Admin);
