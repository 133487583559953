import React, { Component, useContext } from 'react';
import { Card, Col, Row, Accordion, Form } from 'react-bootstrap';
import {
    faCaretDown,
    faCaretUp,
    faPencilAlt,
    faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AccordionContext from 'react-bootstrap/AccordionContext';
import apiCall from '../../../helpers/apiCall';
import Table from '../../../components/Table/Table';

function CaretToggle() {
    const currentEventKey = useContext(AccordionContext);

    return (
        <span className='pull-right' style={{ cursor: 'pointer' }}>
            <FontAwesomeIcon
                className='float-right'
                style={{
                    fontSize: '20px',
                }}
                icon={currentEventKey ? faCaretUp : faCaretDown}
            />
        </span>
    );
}

export default class CourseForms extends Component {
    state = {
        userCourse: null,
    };

    inputTypeMap = {
        text: 'text',
        number: 'number',
        date: 'date',
        file: 'url',
        typeDNA: 'text',
        textarea: 'textarea',
        options: 'select',
        checkbox: 'checkbox',
        radio: 'radio',
    };

    accordionKeyMap = {
        enrollment: {
            title: 'Enrollment',
        },
        preExam: {
            title: 'Pre Exam',
        },
        postExam: {
            title: 'Post Exam',
        },
    };

    componentDidMount = async () => {
        const { success, response } = await apiCall(
            'GET',
            `/users/courses/${this.props.doc._id}/admin`
        );
        if (success && response) {
            let userCourse = { ...response };
            Object.keys(this.accordionKeyMap).map((key) => {
                userCourse[key + 'UpdatedAt'] = response[key + 'UpdatedAt']
                    ? new Date(
                          response[key + 'UpdatedAt']
                      ).toLocaleDateString() +
                      ' ' +
                      new Date(response[key + 'UpdatedAt']).toLocaleTimeString()
                    : '-';
            });

            this.setState({
                userCourse,
            });
        }
    };

    toggleFieldEditable = (fieldId, key, editable) => {
        this.setState({
            userCourse: {
                ...this.state.userCourse,
                [key + 'Form']: {
                    ...this.state.userCourse[key + 'Form'],
                    fields: this.state.userCourse[key + 'Form'].fields.map(
                        (field, j) => {
                            if (fieldId === field._id) {
                                field.editable = editable;
                            }
                            return field;
                        }
                    ),
                },
            },
        });
    };

    handleAnswerSave = async (formKey, field, value) => {
        const { success, response } = await apiCall(
            'PUT',
            `/users/enrollment/${this.state.userCourse._id}/${field.key}`,
            {
                value: value,
            }
        );

        if (success) {
            this.toggleFieldEditable(field._id, formKey, false);
        }
    };

    handleAnswerChange = (formKey, courseForm, fieldKey, value) => {
        this.setState({
            userCourse: {
                ...this.state.userCourse,
                [formKey + 'Form']: {
                    ...courseForm,
                    answers: {
                        ...courseForm.answers,
                        [fieldKey]: value,
                    },
                },
            },
        });
    };

    handleMulticheckboxChange = (
        formKey,
        courseForm,
        fieldKey,
        option,
        checked
    ) => {
        let currentAnswer = courseForm.answers[fieldKey];

        if (!currentAnswer) {
            currentAnswer = [];
        }

        currentAnswer = [...currentAnswer];

        if (checked) {
            currentAnswer.push(option);
        } else {
            currentAnswer = currentAnswer.filter((res) => {
                return res !== option;
            });
        }

        this.handleAnswerChange(formKey, courseForm, fieldKey, currentAnswer);
    };

    render() {
        return (
            <div className='pt-3'>
                {Object.keys(this.accordionKeyMap).map((key) => {
                    return (
                        <Accordion
                            defaultActiveKey={null}
                            className='py-2'
                            key={key}>
                            <Card>
                                <Accordion.Toggle
                                    as={Card.Header}
                                    eventKey='0'
                                    style={{
                                        backgroundColor: '#dcf5ee',
                                    }}>
                                    <Row>
                                        <Col
                                            xs={8}
                                            style={{
                                                fontWeight: 'bold',
                                            }}>
                                            {this.accordionKeyMap[key].title}
                                        </Col>
                                        <Col xs={3}>
                                            {this.state.userCourse &&
                                            this.state.userCourse[
                                                key + 'UpdatedAt'
                                            ] !== '-' ? (
                                                <div className='float-right'>
                                                    <span>
                                                        <strong>
                                                            Completed:{' '}
                                                        </strong>
                                                        {this.state.userCourse
                                                            ? this.state
                                                                  .userCourse[
                                                                  key +
                                                                      'UpdatedAt'
                                                              ]
                                                            : '-'}
                                                    </span>
                                                </div>
                                            ) : (
                                                <></>
                                            )}
                                        </Col>
                                        <Col xs={1}>
                                            <Accordion.Toggle
                                                as={CaretToggle}
                                                className='mx-1'
                                                eventKey='1'></Accordion.Toggle>
                                        </Col>
                                    </Row>
                                </Accordion.Toggle>
                                <Accordion.Collapse eventKey='0'>
                                    <Card.Body>
                                        {this.state.userCourse &&
                                        this.state.userCourse[key + 'Form'] ? (
                                            <Table
                                                columns={[
                                                    {
                                                        text: 'Field',
                                                        field: 'label',
                                                    },
                                                    {
                                                        text: 'Value',
                                                        field: (field) => {
                                                            const inputType =
                                                                this
                                                                    .inputTypeMap[
                                                                    field
                                                                        .inputType
                                                                ];
                                                            const courseForm =
                                                                this.state
                                                                    .userCourse[
                                                                    key + 'Form'
                                                                ];
                                                            const answer =
                                                                courseForm
                                                                    .answers[
                                                                    field.key
                                                                ];

                                                            return field.editable &&
                                                                inputType &&
                                                                inputType !==
                                                                    'url' ? (
                                                                inputType ===
                                                                'textarea' ? (
                                                                    <textarea
                                                                        value={
                                                                            answer
                                                                        }
                                                                        onChange={(
                                                                            event
                                                                        ) => {
                                                                            this.handleAnswerChange(
                                                                                key,
                                                                                courseForm,
                                                                                field.key,
                                                                                event
                                                                                    .target
                                                                                    .value
                                                                            );
                                                                        }}></textarea>
                                                                ) : inputType ===
                                                                  'select' ? (
                                                                    <select
                                                                        className='form-control'
                                                                        value={
                                                                            answer
                                                                        }
                                                                        onChange={(
                                                                            event
                                                                        ) => {
                                                                            this.handleAnswerChange(
                                                                                key,
                                                                                courseForm,
                                                                                field.key,
                                                                                event
                                                                                    .target
                                                                                    .value
                                                                            );
                                                                        }}>
                                                                        {field.extra.options.map(
                                                                            (
                                                                                option,
                                                                                j
                                                                            ) => {
                                                                                return (
                                                                                    <option
                                                                                        value={
                                                                                            option
                                                                                        }
                                                                                        key={`${field.key}-${i}-${j}`}>
                                                                                        {
                                                                                            option
                                                                                        }
                                                                                    </option>
                                                                                );
                                                                            }
                                                                        )}
                                                                    </select>
                                                                ) : inputType ===
                                                                  'checkbox' ? (
                                                                    <div>
                                                                        {field.extra.options.map(
                                                                            (
                                                                                option,
                                                                                j
                                                                            ) => {
                                                                                return (
                                                                                    <Form.Check
                                                                                        key={`${field.key}-${i}-${j}`}
                                                                                        type='checkbox'
                                                                                        label={
                                                                                            option
                                                                                        }
                                                                                        checked={
                                                                                            answer.indexOf(
                                                                                                option
                                                                                            ) >=
                                                                                            0
                                                                                        }
                                                                                        onChange={(
                                                                                            event
                                                                                        ) => {
                                                                                            this.handleMulticheckboxChange(
                                                                                                key,
                                                                                                courseForm,
                                                                                                field.key,
                                                                                                option,
                                                                                                event
                                                                                                    .target
                                                                                                    .checked
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                );
                                                                            }
                                                                        )}
                                                                    </div>
                                                                ) : inputType ===
                                                                  'radio' ? (
                                                                    <div>
                                                                        {field.extra.options.map(
                                                                            (
                                                                                option,
                                                                                j
                                                                            ) => {
                                                                                return (
                                                                                    <Form.Check
                                                                                        key={`${field.key}-${i}-${j}`}
                                                                                        type='radio'
                                                                                        name={
                                                                                            field.label
                                                                                        }
                                                                                        label={
                                                                                            option
                                                                                        }
                                                                                        checked={
                                                                                            answer ===
                                                                                            option
                                                                                        }
                                                                                        onChange={(
                                                                                            event
                                                                                        ) => {
                                                                                            this.handleAnswerChange(
                                                                                                key,
                                                                                                courseForm,
                                                                                                field.key,
                                                                                                option
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                );
                                                                            }
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    <Form.Control
                                                                        type={
                                                                            inputType
                                                                        }
                                                                        value={
                                                                            answer
                                                                        }
                                                                        onChange={(
                                                                            event
                                                                        ) => {
                                                                            this.handleAnswerChange(
                                                                                key,
                                                                                courseForm,
                                                                                field.key,
                                                                                event
                                                                                    .target
                                                                                    .value
                                                                            );
                                                                        }}
                                                                    />
                                                                )
                                                            ) : inputType ===
                                                              'url' ? (
                                                                <a
                                                                    href={
                                                                        answer
                                                                    }
                                                                    target='_blank'
                                                                    rel='noreferrer'
                                                                    style={{
                                                                        color: 'blue',
                                                                    }}>
                                                                    Click to
                                                                    download
                                                                </a>
                                                            ) : (
                                                                <>
                                                                    {String(
                                                                        answer
                                                                    )}
                                                                </>
                                                            );
                                                        },
                                                    },
                                                ]}
                                                rows={
                                                    this.state.userCourse[
                                                        key + 'Form'
                                                    ].fields
                                                }
                                                rowButtons={[
                                                    {
                                                        type: 'button',
                                                        text: (field) => {
                                                            return field.editable
                                                                ? 'Save'
                                                                : 'Edit';
                                                        },
                                                        icon: (field) => {
                                                            return field.editable
                                                                ? faCheck
                                                                : faPencilAlt;
                                                        },
                                                        condition: (field) => {
                                                            const inputType =
                                                                this
                                                                    .inputTypeMap[
                                                                    field
                                                                        .inputType
                                                                ];
                                                            return (
                                                                inputType &&
                                                                inputType !==
                                                                    'url'
                                                            );
                                                        },
                                                        clickCallback: (
                                                            e,
                                                            field
                                                        ) => {
                                                            if (
                                                                !field.editable
                                                            ) {
                                                                this.toggleFieldEditable(
                                                                    field._id,
                                                                    key,
                                                                    true
                                                                );
                                                            } else {
                                                                const answer =
                                                                    this.state
                                                                        .userCourse[
                                                                        key +
                                                                            'Form'
                                                                    ].answers[
                                                                        field
                                                                            .key
                                                                    ];
                                                                this.handleAnswerSave(
                                                                    key,
                                                                    field,
                                                                    answer
                                                                );
                                                            }
                                                        },
                                                    },
                                                ]}
                                            />
                                        ) : (
                                            <div>
                                                {
                                                    this.accordionKeyMap[key]
                                                        .title
                                                }{' '}
                                                has not been completed yet.
                                            </div>
                                        )}
                                    </Card.Body>
                                </Accordion.Collapse>
                            </Card>
                        </Accordion>
                    );
                })}
            </div>
        );
    }
}
