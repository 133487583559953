import React, { Component } from 'react';
import { connect } from 'react-redux';
import MenuItems from '../CourseMenu/MenuItems';

class ChaptersDropwdowns extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebar: true,
            lessons: [],
            sidebarEventKey: 0,
        };
    }

    render() {
        return (
            <div style={{ margin: '20px' }}>
                <MenuItems withBorder={true} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    // state argument is the entire redux store
    const { allChapters } = state; // inside curly braces we have the name of the state we want
    return {
        allChapters,
    };
};

export default connect(mapStateToProps)(ChaptersDropwdowns);
