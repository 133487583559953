import React, { Component } from 'react';
import { CourseCard } from '../../../components/User/CourseCards/CourseCard';

import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import apiCall from '../../../helpers/apiCall';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class UserCourse extends Component {
    _isMounted = false; //check if component is alive/exists
    constructor(props) {
        super(props);
        this.state = {
            packages: [],
            courses: [],
            showCourses: false,
            bundles: [],
            singles: [],
        };
    }
    async componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            const userId = this.props.loggedIn.user._id;
            const { success, response } = await apiCall(
                'GET',
                `/users/${userId}/packages`
            );
            if (success) {
                this.setState({ packages: response.docs });
            }
            response.docs.map(async (p) => {
                const { success, response } = await apiCall(
                    'GET',
                    `/users/packages/${p._id}/courses`
                );
                if (success) {
                    if (response.courses.length == 1) {
                        this.setState((prevState) => ({
                            singles: [
                                ...prevState.singles,
                                response.courses[0],
                            ],
                        }));
                    } else if (response.courses.length > 1) {
                        this.setState((prevState) => ({
                            bundles: [...prevState.bundles, p],
                        }));
                    }
                }
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleClose() {
        this.setState({ showCourses: false });
    }

    handleShow() {
        this.setState({ showCourses: true });
    }

    render() {
        const courses = this.state.courses;
        if (this.state.packages && this.state.packages[0]) {
            return (
                <Container style={{ marginTop: '48px' }}>
                    <Row>
                        {this.state.singles.map((course) => (
                            <Col key={course._id} xs={12} sm={6} lg={4}>
                                <CourseCard
                                    image={course.data.image}
                                    title={course.data.title}
                                    description={course.data.description}
                                    courseId={course._id}
                                />
                            </Col>
                        ))}
                    </Row>
                </Container>
            );
        } else {
            return <div />;
        }
    }
}

const mapStateToProps = (state) => {
    // state argument is the entire redux store
    const { loggedIn } = state; // inside curly braces we have the name of the state we want
    return {
        loggedIn,
    };
};
export default withRouter(connect(mapStateToProps)(UserCourse));
