import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    ImageLeft,
    ImageRight,
    ImageTop,
    ImageFadeBackground,
    Video,
    EndOfChapterQuiz,
    BasicText,
    Header,
    BasicTextTwoColumns,
    AudioTranscriptLarge,
    AudioTranscript,
    FillTheMissingWord,
    MultiChoiceMicroQuiz,
    TrueFalseOneQuestion,
    TrueFalseFourQuestions,
    MultiSelectImages,
    AceableStatements,
    PageLayout,
} from '../LessonLayouts/';
import apiCall from '../../../helpers/apiCall';
import { Container } from 'react-bootstrap';
import './CardSelector.scss';

class CardSelector extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            style: '',
            defaultPadding: true,
            showDemo: false,
            showModal: false,
            cards: null,
        };
    }

    async componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            await this.selectCard();
        }
    }

    async componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.currentLessonCardIds.state !=
                this.props.currentLessonCardIds.state ||
            prevProps.currentLessonLayout.state !=
                this.props.currentLessonLayout.state
        ) {
            await this.selectCard();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    async selectCard() {
        if (
            this.props.currentLessonCardIds.state &&
            this.props.currentLessonCardIds.state[0]
        ) {
            if (this.props.currentLessonLayout.state == 'PAGE') {
                const urls = [];
                const cards = [];
                this.props.currentLessonCardIds.state.map((id) => {
                    urls.push(`/courses/lessons/cards/${id}`);
                });
                urls.map(async (url) => {
                    const { response } = await apiCall('GET', url);
                    cards.push(response);
                });
                this.setState({ cards: cards });
            } else if (this.props.currentLessonLayout.state == 'CARD') {
                const { success, response } = await apiCall(
                    // when we implement cards we need to store current card ID in redux store from cardSlider.jsx
                    'GET',
                    `/courses/lessons/cards/${this.props.currentLessonCardIds.state[0]}`
                );
            }
        }
    }

    handleModalShowAndClose() {
        this.setState({ showModal: !this.state.showModal });
    }

    render() {
        const cardType = this.state.style; //response.style;

        let component = null;

        if (
            this.props.currentLessonCardIds.state &&
            this.props.currentLessonCardIds.state[0] &&
            this.props.currentLessonLayout.state != ''
        ) {
            return (
                <div>
                    {this.props.currentLessonLayout.state == 'PAGE' ? (
                        <PageLayout
                            cards={this.props.currentLessonCardIds.state}
                        />
                    ) : this.props.currentLessonLayout.state == 'CARD' ? (
                        <Container
                            className={
                                this.props.currentLessonLayout.state != 'PAGE'
                                    ? 'card-selector'
                                    : ''
                            }>
                            <div className='lesson-card'>{component}</div>
                        </Container>
                    ) : (
                        <div></div>
                    )}
                </div>
            );
        } else {
            return <div> No cards found</div>;
        }
    }
}

const mapStateToProps = (state) => {
    const { currentLessonCardIds, currentLesson, currentLessonLayout } = state;
    return { currentLessonCardIds, currentLesson, currentLessonLayout };
};

export default connect(mapStateToProps)(CardSelector);
