import React from 'react';
import './Accordion.scss';
import {
    RiArrowDownSLine,
    RiArrowUpSLine,
    RiCheckFill,
    RiEyeFill,
    RiEyeLine,
} from 'react-icons/ri';
import { BsUnlockFill, BsLockFill } from 'react-icons/bs';
import { IconContext } from 'react-icons';
import apiCall from '../../../helpers/apiCall';
import { Card } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Spinner } from '../../Spinner';

class AccordionItems extends React.Component {
    state = {
        opened: false,
        chapterLessons: [],
        loading: false,
    };

    componentDidMount() {
        if (Object.keys(this.props.currentChapter.state).length > 0) {
            if (this.props.chapter._id == this.props.currentChapter.state._id) {
                this.getLessons();
                this.props.setUpdateCourseMenuLessons('');
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.chapter._id == this.props.updateCourseMenuLessons.state
        ) {
            this.getLessons();
            this.props.setUpdateCourseMenuLessons('');
        }
    }

    getLessons = async () => {
        this.setState({ opened: !this.state.opened });
        if (
            !this.state.chapterLessons.length > 0 ||
            this.props.updateCourseMenuLessons.state == this.props.chapter._id
        ) {
            this.setState({ loading: true, opened: true });
            const url = `/users/chapters/${this.props.chapter._id}/lessons`;
            const { response, success, message } = await apiCall('GET', url);
            if (success) {
                this.setState({
                    chapterLessons: response.docs,
                    loading: false,
                });
            }
        }
    };

    setLessonClassName = (lesson) => {
        const className =
            this.props.currentLesson.state._id == lesson._id
                ? '__current'
                : 'completed' in lesson ||
                  this.props.completedLessons.lessons.includes(lesson._id)
                ? '__complete'
                : 'unlocked' in lesson ||
                  lesson.conditionStatement == 'ANY_TIME' ||
                  this.props.unlockedLessons.lessons.includes(lesson._id)
                ? '__unlocked'
                : lesson.conditionStatement == 'PREVIOUS_TIME_MET' ||
                  lesson.conditionStatement == 'PREVIOUS_STARTED'
                ? '__locked'
                : '';
        return className;
    };

    setLessonIcon = (lesson) => {
        const icon =
            this.props.currentLesson.state._id == lesson._id ? (
                <RiEyeFill size={24} />
            ) : 'completed' in lesson ||
              this.props.completedLessons.lessons.includes(lesson._id) ? (
                <RiCheckFill size={24} />
            ) : 'unlocked' in lesson ||
              this.props.unlockedLessons.lessons.includes(lesson._id) ||
              lesson.conditionStatement == 'ANY_TIME' ? (
                <BsUnlockFill size={18} />
            ) : (
                <BsLockFill size={18} />
            );
        return icon;
    };

    goToLesson = (lesson) => {
        this.props.history.push(
            `/user/course/${this.props.courseId}/chapters/${this.props.chapter._id}/lesson/${lesson._id}`
        );
        console.log(lesson);
        this.props.setCurrentLesson(lesson);
        this.props.setCurrentChapter(this.props.chapter);
    };

    render() {
        const {
            state: { opened },
        } = this;
        if (Object.keys(this.props.currentLesson).length > 0) {
            return (
                <div
                    className={`accordion-item ${
                        opened ? 'accordion-item--opened' : ''
                    }`}>
                    <div
                        className={
                            this.props.withBorder
                                ? 'accordion-item__with-border'
                                : ''
                        }>
                        <div
                            className='accordion-item__line'
                            onClick={() => this.getLessons()}>
                            <span className='accordion-item__title'>
                                {this.props.title.split(' - ').map((string) => (
                                    <span key={string}>{string}</span>
                                ))}
                            </span>
                            {this.state.opened ? (
                                <IconContext.Provider
                                    value={{ color: '#DADADA', size: '30px' }}>
                                    <div>
                                        <RiArrowUpSLine />
                                    </div>
                                </IconContext.Provider>
                            ) : (
                                <IconContext.Provider
                                    value={{ color: '#DADADA', size: '30px' }}>
                                    <div>
                                        <RiArrowDownSLine />
                                    </div>
                                </IconContext.Provider>
                            )}
                        </div>
                        <div className='accordion-item__line'>
                            {this.props.description}
                        </div>
                    </div>
                    <div className='accordion-item__inner'>
                        <div className='accordion-item__content'>
                            <div
                                className={
                                    this.state.loading
                                        ? 'accordion-item__collapsed-loading'
                                        : 'accordion-item__collapsed'
                                }>
                                {!this.state.loading ? (
                                    <Card className='course-menu-items__card'>
                                        <div>
                                            <ul>
                                                {this.state.chapterLessons.map(
                                                    (lesson, index) => (
                                                        <div key={lesson._id}>
                                                            <li
                                                                className={`course-menu-items__lesson${this.setLessonClassName(
                                                                    lesson
                                                                )}`}
                                                                key={index}
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    position:
                                                                        'relative',
                                                                    marginLeft: 0,
                                                                }}
                                                                onClick={
                                                                    'unlocked' in
                                                                        lesson ||
                                                                    lesson.conditionStatement ==
                                                                        'ANY_TIME' ||
                                                                    this.props.unlockedLessons.lessons.includes(
                                                                        lesson._id
                                                                    )
                                                                        ? () =>
                                                                              this.goToLesson(
                                                                                  lesson
                                                                              )
                                                                        : null
                                                                }>
                                                                <span
                                                                    className={
                                                                        index !=
                                                                        0
                                                                            ? 'course-menu-items__circle'
                                                                            : 'course-menu-items__circle course-menu-items__no-line'
                                                                    }>
                                                                    {this.setLessonIcon(
                                                                        lesson
                                                                    )}
                                                                </span>
                                                                <div className='course-menu-items__lesson-cont'>
                                                                    {'lessonType' in
                                                                    lesson ? (
                                                                        lesson.lessonType ==
                                                                        'STATE' ? (
                                                                            <span className='course-menu-items__state-marker'>
                                                                                S
                                                                            </span>
                                                                        ) : lesson.lessonType ==
                                                                          'NATIONAL' ? (
                                                                            <span className='course-menu-items__national-marker'>
                                                                                N
                                                                            </span>
                                                                        ) : (
                                                                            <span />
                                                                        )
                                                                    ) : (
                                                                        <span />
                                                                    )}

                                                                    <span
                                                                        className={`course-menu-items__lesson-title${
                                                                            this
                                                                                .props
                                                                                .currentLesson
                                                                                .state
                                                                                ._id ==
                                                                            lesson._id
                                                                                ? '__active'
                                                                                : ''
                                                                        }`}>
                                                                        <span>
                                                                            {
                                                                                lesson.title
                                                                            }
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </li>
                                                        </div>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    </Card>
                                ) : (
                                    <div className='course-menu-items__loading-spinner-area'>
                                        <Spinner />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return <div />;
        }
    }
}

const mapStateToProps = (state) => {
    const {
        currentLesson,
        currentChapter,
        allChapters,
        updateCourseMenuLessons,
        unlockedLessons,
        completedLessons,
    } = state;
    return {
        currentLesson,
        currentChapter,
        allChapters,
        updateCourseMenuLessons,
        unlockedLessons,
        completedLessons,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setCurrentLesson: (payload) => {
            dispatch({
                type: 'SET_CURRENT_LESSON',
                payload,
            });
        },
        setCurrentChapter: (payload) => {
            dispatch({
                type: 'SET_CURRENT_CHAPTER',
                payload,
            });
        },
        setUpdateCourseMenuLessons: (payload) => {
            dispatch({
                type: 'SET_UPDATE_COURSE_MENU_LESSONS',
                payload,
            });
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(AccordionItems));
